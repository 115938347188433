import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { CartesianGrid, Legend, LineChart, XAxis, YAxis, Line, ResponsiveContainer, Tooltip } from "recharts";
import { 
  CircularProgress
} from "@mui/material";
import RootState from "../../../redux/states/root.state";
import { AppDispatch } from "../../../redux/store";
import { fetchLiveHistory } from "../../../redux/actions/spaHistory.actions";

interface ReportsGraphProps {
  spa_guid: string;
  selected: any[];
  startDate: string;
  endDate: string;
}

const colors = {
  'STemp': '#f6c141',
  'SPTemp': '#f1932d',
  'HTemp': '#dc050c',
  'P1': '#1965b0',
  'H1': '#7bafde',
  'Filter': '#95211b',
  'SBConnected': '#521a13',
  'Current': '#000000',
  'sbVout': '#999999',
  'sbI1': '#6f4c9b',
  'sbI2': '#b58fc2',
  'sbpH': '#4eb265',
  'sbORP': '#a6be54'
};

const CustomTooltip: React.FC<{ active:any, payload:any, label:any }> = ({ active, payload, label }) => {
  if (active && payload?.length) {
    return (
      <div className="rechart-tooltip">
        <span>{label}</span>
        <br />
        {payload.map((el:any, i:number) => (
          <div key={i}>
            <small style={{color:colors[el.name as keyof typeof colors]}}>
              {el.name} : {el.value}
            </small>
            <br/>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

const ReportsGraph: React.FC<ReportsGraphProps> = ({spa_guid, selected, startDate, endDate}) => {
  const dispatch: AppDispatch = useDispatch();
  const {data: live, loading} = useSelector((state: RootState) => state.liveHistory);
  
  useEffect(() => {
    let keys = [...selected];
    if (keys.includes('Current')){
      let i = keys.indexOf('Current');
      keys[i] = '#cr';
    }
    if (keys.includes('Filter')){
      let i = keys.indexOf('Filter');
      keys[i] = '#fl';
    }

    if (spa_guid){
      dispatch(fetchLiveHistory({guid:spa_guid, date_from:new Date(startDate).getTime(), date_to:new Date(endDate).getTime(), keys:keys}));
    } 
  }, [dispatch, spa_guid, selected, startDate, endDate]);

  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    let newArray = [...live];
    newArray = newArray.map((data:any)=>{
      let keys = Object.keys(data);
      let newObj = {...data};
      if (keys.includes('SBConnected')) {
        newObj={...newObj, SBConnected: + data.SBConnected.BOOL}
      }

      if (keys.includes('sbpH')) {
        newObj={...newObj, sbpH: data.sbpH/100}
      }

      if (keys.includes('Current')) {
        newObj={...newObj, Current: data.Current/100}
      }

      return {...newObj, timestamp:new Date(data.timestamp).toUTCString()}
    })

    newArray.sort((a:any,b:any)=>{
      return new Date(b.timestamp) < new Date(a.timestamp) ? 1 : -1
    })

    setData(newArray);
  }, [live,setData]);

  return (
    <>
      <div className="table">
        {loading ?
        <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
        :
        live.length>0?
        <ResponsiveContainer width="95%" height="95%">
          <LineChart 
            data={data}
            margin={{ top: 50, right: 30, left: 20, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="timestamp"/>
            <YAxis/>
            <Tooltip
              content={<CustomTooltip active={false} payload={[]} label={""} />}
            />
            <Legend />
            {selected.map((select:any, i)=>
              <Line key={i} type="monotone" dataKey={select} stroke={colors[select as keyof typeof colors]} strokeWidth={2} label={'hi'} dot={false}/>
            )}
          </LineChart>
        </ResponsiveContainer>
        :
        <div className="empty-table">No Data Found.</div>
        }
      </div>
    </>
  );
};

export default ReportsGraph;
