import styles from "../../../assets/scss/Modal.module.scss";
import React from "react";
import {Modal} from '@mui/material';


interface AddModalProps {
    isOpen: boolean, 
    setOpenAdd: React.Dispatch<React.SetStateAction<boolean>>,
}

const AddModal: React.FC<AddModalProps> = ({isOpen, setOpenAdd}) => {
    const handleClose = () => setOpenAdd(false);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <div className={styles.modal}>
            <div className={styles.header}>
                <p> Add Spa? </p>
            </div>
            <div className={styles.body}>
                <p> Adding will link the app with your tub. </p>
            </div>
            <div className="modal-btn-group">
                <button className="as-text" onClick={handleClose}> Cancel </button>                        
                <button className="as-button" onClick={handleClose}>Connect</button>
            </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddModal;
