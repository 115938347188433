import React from "react";
import Header from "../../../components/common/Header/Header";

const ContactUs = () => {
    return (
        <>
            <Header withLogo={false} withNavBtn={false} withUserAvatar={false} header="Contact Us"/>

            <div className="page-wrapper">
                <p>Click <a href="https://www.arcticspas.ca/contact/" target="_blank" rel="noreferrer">HERE</a> to be redirected to contact form.</p>
            </div>
        </>

    );
};

export default ContactUs;
