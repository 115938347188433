export enum ArcticAbbr {
    ERR0="FLO",
    ERR1="FLC",
    ERR2="HL",
    ERR3="HL(F)",
    ERR4="Prr",
    ERR5="HL",
    ERR6="EE",
    ERR7="FP",
    ERR8="Ph",
    ERR9="Hd",
    ERR10="",
    ERR11="SB-CE",
    ERR12="",
    ERR13="H-wo-s",
    ERR14="orp_nr",
    ERR15="Ph_L",
    ERR16="",
    ERR17="",
    ERR18="",
    ERR19="",
    ERR20="",
    ERR21="",
    ERR22="",
    ERR23="",
    ERR24="",
    ERR25="",
    ERR26="",
    ERR27="",
    ERR28="",
    ERR29="",
    ERR30="",
    ERR31="",
    ERR32="",
    ERR33="",
    ERR34="",
    ERR35="",
    ERR36="",
    ERR37="",
    ERR38="",
    ERR39="",
    ERR40="",
    ERR41="",
    ERR42="",
    ERR43="",
    ERR44="",
    ERR45="",
    ERR46="",
    ERR47="",
    ERR48="",
    ERR49="",
    ERR50="",
    ERR51="",
    ERR52="",
    ERR53="",
    ERR54="",
    ERR55="",
    ERR56="",
    ERR57="",
    ERR58="",
    ERR59="",
    ERR60="",
    ERR61="",
    ERR62="",
    ERR63="",

    STAT0="",
    STAT1="",
    STAT2="",
    STAT3="",
    STAT4="",
    STAT5="",
    STAT6="",
    STAT7="",
    STAT8="",
    STAT9="",
    STAT10="HPt",
    STAT11="",
    STAT12="H/S cal",
    STAT13="",
    STAT14="",
    STAT15="",
    STAT16="",
    STAT17="",
    STAT18="",
    STAT19="",
    STAT20="P1Lnc",
    STAT21="P1Hnc",
    STAT22="P2nc",
    STAT23="P3nc",
    STAT24="P4nc",
    STAT25="YESSnc",
    STAT26="Onnc",
    STAT27="H1nc",
    STAT28="H2nc",
    STAT29="",
    STAT30="",
    STAT31="",
    STAT32="",
    STAT33="",
    STAT34="",
    STAT35="",
    STAT36="",
    STAT37="",
    STAT38="",
    STAT39="",
    STAT40="",
    STAT41="",
    STAT42="",
    STAT43="",
    STAT44="",
    STAT45="",
    STAT46="",
    STAT47="",
    STAT48="",
    STAT49="",
    STAT50="",
    STAT51="",
    STAT52="",
    STAT53="",
    STAT54="",
    STAT55="",
    STAT56="",
    STAT57="",
    STAT58="",
    STAT59="",
    STAT60="",
    STAT61="",
    STAT62="",
    STAT63="",
}