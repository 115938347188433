import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);

window.matchMedia('(prefers-color-scheme: dark)').addEventListener("change", function (e) {
  e.matches ? document.body.setAttribute('data-theme', 'dark') : document.body.setAttribute('data-theme', 'light');
});

// at this point, there is nothing paint on screen yet.
(function() { try {
    var mode = localStorage.getItem('data-theme');
    if (mode) {
      document.body.setAttribute('data-theme', mode);

    } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.body.setAttribute('data-theme', 'dark');
    }

  } catch (e) {} })();

root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();