import mqtt, { MqttClient, IClientSubscribeOptions, IClientOptions } from 'mqtt';
import mqttConfig from './mqttconfig';
import store from '../../redux/store';


let client: MqttClient;
const tokenKeyName = 'mqtt_token';
const customAuthorizerName = 'mqtt';
const { v4: uuidv4 } = require('uuid');

export const connectMQTT = (user: { email: any; }) => {
  const accessToken = store.getState().user.accessToken || '';
  const uniqueId = uuidv4(); // Generate a unique identifier

  const options: IClientOptions = {
    clientId: `${user?.email ?? 'web-user'}-${uniqueId}`,
    port: mqttConfig.port,
    username: `?x-amz-customauthorizer-name=${customAuthorizerName}&${tokenKeyName}=${accessToken}`,
    password: '',
    protocol: 'mqtts',
    protocolVersion: 4,
    rejectUnauthorized: true,
    reconnectPeriod: 5000,
  };

  client = mqtt.connect(`mqtts://${mqttConfig.broker}`, options);
};

export const disconnectMQTT = () => {
  if (client) {
    client.end();
  }
};

export const subscribeToTopic = (
  topic: string,
  opts: IClientSubscribeOptions = { qos: 0 },
  callback: mqtt.ClientSubscribeCallback = () => {}
) => {
  if(!client || client.disconnecting) return;

  client.subscribe(topic, opts, (err, granted) => {
    if (err) {
      console.error('Error subscribing to topic:', topic, err);
    } else {
      console.log('Successfully subscribed to topic:', topic, granted);
      callback(err, granted);
    }
  });
};

export const unsubscribeFromTopic = (topic: string) => {
  client.unsubscribe(topic, (err) => {
    if (err) {
      console.error('Error unsubscribing from topic:', topic, err);
    } else {
      console.log('Successfully unsubscribed from topic:', topic);
    }
  });
};

export const publishMessage = (topic: string, message: string, retain: boolean = true) => {
  if(!client || client.disconnecting) return;

  client.publish(topic, message, { qos: 1, retain }, (err) => {
    if (err) {
      console.error('Error publishing message to topic:', topic, err);
    } else {
      console.log('Successfully published message to topic:', topic, message);
    }
  });
};

export const onConnect = (callback: () => void) => {
  client.on('connect', () => {
    console.log('MQTT connection established');
    callback();
  });
};

export const onDisconnect = (callback: () => void) => {
  client.on('disconnect', () => {
    console.log('Disconnected from MQTT broker');
    callback();
  });
};

export const onError = (callback: (error: Error) => void) => {
  client.on('error', (error) => {
    console.error('MQTT error:', error);
    callback(error);
  });
};

export const onMessage = (callback: (topic: string, message: Buffer) => void) => {
  client.on('message', (topic, message) => {
    console.log("Message received on topic:", topic)

    callback(topic, message);
  });
};

export const onSubscribe = (callback: (topic: string) => void) => {
  (client as any).on('subscribe', (topic: string) => {
    console.log('Subscribed to topic:', topic);
    callback(topic);
  });
};

export const onUnsubscribe = (callback: (topic: string) => void) => {
  (client as any).on('unsubscribe', (topic: string) => {
    console.log('Unsubscribed from topic:', topic);
    callback(topic);
  });
};