// src/redux/reducers/errorLog.reducer.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchLiveHistory } from '../actions/spaHistory.actions';
import LiveHistoryState from '../states/liveHistory.state';

const initialState: LiveHistoryState = {
  loading: false,
  data: [],
  error: null,
};

const LiveHistorySlice = createSlice({
  name: 'live-history',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLiveHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLiveHistory.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchLiveHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      });
  },
});

export default LiveHistorySlice.reducer;