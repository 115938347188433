import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { registerUser } from "../../../redux/actions/user.actions";
import RootState from "../../../redux/states/root.state";
import { AppDispatch } from "../../../redux/store";
import {
  TextField,
  Button,
  Box,
  Typography,
  SxProps,
  Theme,
} from "@mui/material";

const formStyles: SxProps<Theme> = (theme) => ({
  width: "100%",
  marginTop: theme.spacing(1),
});

const buttonStyles: SxProps<Theme> = (theme) => ({
  margin: theme.spacing(3, 0, 2),
  backgroundColor: "var(--as-red)",
  "&:hover": {
    backgroundColor: "var(--as-red)",
  },

  marginTop: "2rem",
  textTransform: "none",
  fontWeight: "600",
});

const RegistrationForm: React.FC = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const registerError = useSelector((state: RootState) => state.user.error);
  const accessToken = useSelector((state: RootState) => state.user.accessToken);

  useEffect(() => {
    if (accessToken) {
      navigate("/spas");
    }
  }, [accessToken, navigate]);

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();

    const result = await dispatch(
      registerUser({
        first_name: firstName,
        last_name: lastName,
        username: username,
        email: email,
        password: password,
        password_confirmation: passwordConfirmation,
      })
    );

    if (registerUser.fulfilled.match(result)) {
      navigate("/spas");
    }
  };

  return (
    <div className="login-card">
      <div className="login-card-content">
        <div className="login-header">Sign Up</div>
        <Box
          component="form"
          onSubmit={handleRegister}
          noValidate
          sx={formStyles}
        >
          <div style={{ display: "flex", gap: "1rem" }}>
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="first_name"
              label="First Name"
              name="first_name"
              autoComplete="first_name"
              autoFocus
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "var(--as-gray)",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "var(--as-gray)",
                },
              }}
            />
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="last_name"
              label="Last Name"
              name="last_name"
              autoComplete="last_name"
              autoFocus
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "var(--as-gray)",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "var(--as-gray)",
                },
              }}
            />
          </div>

          <div style={{ marginBottom: "-1rem" }}>
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "var(--as-gray)",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "var(--as-gray)",
                },
              }}
            />
          </div>
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoFocus
            autoComplete="off"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{
              "& .MuiInputLabel-root": {
                color: "var(--as-gray)",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "var(--as-gray)",
              },
            }}
          />
          <div style={{ marginBottom: "-1rem" }}>
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="off"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "var(--as-gray)",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "var(--as-gray)",
                },
              }}
            />
          </div>
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            name="password_confirmation"
            label="Re-type password"
            type="password"
            id="password_confirmation"
            autoComplete="off"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            sx={{
              "& .MuiInputLabel-root": {
                color: "var(--as-gray)",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "var(--as-gray)",
              },
            }}
          />
          {registerError && (
            <Typography color="error" variant="body2" paragraph>
              {registerError}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={buttonStyles}
          >
            Register
          </Button>
          <Box
            mt={".5rem"}
            display="flex"
            justifyContent="center"
            gap="0.75rem"
          >
            Already have an account?
            <Link to={"/login"} className="login-help-link">
              {"Login"}
            </Link>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default RegistrationForm;
