import styles from "../../../assets/scss/Modal.module.scss";
import React from "react";
import {Modal} from '@mui/material';
import {useNavigate} from 'react-router-dom';

interface ModalProps {
    isOpen: boolean, 
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    content: string,
}

const AlertModal: React.FC<ModalProps> = ({isOpen, setOpen, content}) => {
    const navigate = useNavigate();

    /* Alert user and return to settings. */
    const handleClose = () => {
        setOpen(false);
        navigate(-1);
    };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <div className={styles.modal}>
            <div className={styles.header}>
                <p> </p>
            </div>
            <div className={styles.body}>
                <p> {content} </p>
            </div>
            <div className="modal-btn-group">
                <p></p>
                <button className="as-text" onClick={handleClose}> OK </button>
            </div>
        </div>
      </Modal>
    </div>
  );
};

export default AlertModal;
