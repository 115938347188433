import "../SpaDetailsPage.css"
import React from "react";

const NetworkCard: React.FC = () => {

  return (
    <>
      <div className="card-container spa">
        <div className="grid-item set">
            <div className="grid-item id">
                <div>Eth/Wifi</div>
            </div>

            <div className="grid-item values">
                <div>{`-`}</div>
            </div>  
        </div> 

        <div className="grid-item set">
            <div className="grid-item id">
                <div>Private IP</div>
                <div>Public IP</div>
                <div>MAC</div>
            </div>

            <div className="grid-item values">
                <div>{`-`}</div>
                <div>{`-`}</div>
                <div>{`-`}</div>
            </div>  
        </div>   

        <div className="grid-item set">
            <div className="grid-item id">
                <div>SSID</div>
                <div>Encyption</div>
                <div>Signal Strength</div>
                <div>Connection Type</div>
            </div>

            <div className="grid-item values">
                <div>{`-`}</div>
                <div>{`-`}</div>
                <div>{`-`}</div>
                <div>{`-`}</div>
            </div>  
        </div>   

        <div className="grid-item set">
            <div className="grid-item id">
                <div>Rx Packets</div>
                <div>Tx Packets</div>
            </div>

            <div className="grid-item values">
                <div>{`-`}</div>
                <div>{`-`}</div>
            </div>  
        </div>   

        <div className="grid-item set">
            <div className="grid-item id">
                <div>Rx Errors</div>
                <div>Tx Errors</div>
            </div>

            <div className="grid-item values">
                <div>{`-`}</div>
                <div>{`-`}</div>
            </div>  
        </div>   

        
        <div className="grid-item set">
            <div className="grid-item id">
                <div>Rx Dropped</div>
                <div>Tx Dropped</div>
            </div>

            <div className="grid-item values">
                <div>{`-`}</div>
                <div>{`-`}</div>
            </div>  
        </div>   

        <div className="grid-item set">
            <div className="grid-item id">
                <div>Rx Overruns</div>
                <div>Tx Overruns</div>
            </div>

            <div className="grid-item values">
                <div>{`-`}</div>
                <div>{`-`}</div>
            </div>  
        </div>   

        <div className="grid-item set">
            <div className="grid-item id">
                <div>Rx Frames</div>
                <div>Tx Carrier</div>
            </div>

            <div className="grid-item values">
                <div>{`-`}</div>
                <div>{`-`}</div>
            </div>  
        </div>   

      </div>
    </>
  );
};

export default NetworkCard;
