import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  SxProps,
  Theme,
} from "@mui/material";
import { styled } from "@mui/system";
import { requestResetLink } from "../../../redux/actions/user.actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import RootState from "../../../redux/states/root.state";
import CircularProgress from "@mui/material/CircularProgress";

const StyledContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "32px",
  backgroundColor: "var(--as-white)",
  borderRadius: "4px",
});

const formStyles: SxProps<Theme> = (theme) => ({
  width: "100%",
  marginTop: theme.spacing(1),
});

const buttonStyles: SxProps<Theme> = (theme) => ({
  marginTop: "2rem",
  textTransform: "none",
  fontWeight: "600",
  backgroundColor: "var(--as-red)",
  "&:hover": {
    backgroundColor: "var(--as-red)",
  },
});

const ForgotPasswordForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const forgotPasswordError = useSelector(
    (state: RootState) => state.user.error
  );
  const isLoading = useSelector((state: RootState) => state.user.loading);
  const dispatch = useDispatch<AppDispatch>();

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    const result = await dispatch(requestResetLink({ email: email }));

    if (requestResetLink.fulfilled.match(result)) {
      setMessage("Password reset link has been sent.");
      setEmail("");
    }
  };

  return (
    <div className="login-card">
      <div className="login-card-content">
        <div className="login-header">Request Reset Link</div>
        <Box
          component="form"
          onSubmit={handleResetPassword}
          noValidate
          sx={formStyles}
        >
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              "& .MuiInputLabel-root": {
                color: "var(--as-gray)",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "var(--as-gray)",
              },
            }}
          />
          {forgotPasswordError && (
            <Typography style={{ color: "var(--color-danger)" }} variant="subtitle1" paragraph>
              {forgotPasswordError}
            </Typography>
          )}

          {message && (
            <Typography
              style={{ color: "var(--color-sucess)" }}
              variant="subtitle1"
              paragraph
            >
              {message}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={buttonStyles}
          >
            {isLoading ? (
              <CircularProgress size={"24px"} style={{ color: "var(--font-white)" }} />
            ) : (
              "Send"
            )}
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
