import styles from "../../../assets/scss/Modal.module.scss";
import React from "react";
import {Modal} from '@mui/material';


interface DeleteModalProps {
    isOpen: boolean, 
    setOpenDelete: React.Dispatch<React.SetStateAction<boolean>>,
    spa: any;
}

const DeleteModal: React.FC<DeleteModalProps> = ({isOpen, setOpenDelete, spa}) => {
    const handleClose = () => setOpenDelete(false);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <div className={styles.modal}>
            <div className={styles.header}>
                <p> Delete {spa?.nick_name} Spa? </p>
            </div>
            <div className={styles.body}>
                <p> Deleting will unlink the app with your tub. </p>
            </div>

              <div className="modal-btn-group">
                <button className={styles.delete} onClick={handleClose}> Delete </button>                        
                <button className="as-text" onClick={handleClose}>Cancel</button>

            </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteModal;
