import React, { useEffect, useState } from "react";
import SpaBoyLogo from "../../../assets/logos/SpaBoyLogo.png";
import MySwitch from "../../../components/common/Switches/Switch";
import spaBoyStyles from "../../../assets/scss/SpaBoy.module.scss";
import Header from "../../../components/common/Header/Header";
import { LevelsStatus } from "./components/LevelsStatus";
import useMQTT from "../../../hooks/useMQTT";
import Command from "../../../classes/command.class";
import { useParams } from "react-router-dom";
import { fetchSpa } from "../../../redux/actions/spa.actions";
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import { Slider } from "@mui/material";
import { TimerWithProgressBar } from "./components/TimerWithProgressBar";
import "./SpaBoy.css";
import TabbedSwitch from "./components/TabbedSwitch";

const SpaBoy = () => {
  const { id } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const [orpLevelBase, setOrpLevelBase] = useState(0);
  const settingsData = useSelector((state: RootState) => state.settings.data);
  const liveData = useSelector((state: RootState) => state.live.data);

  const [clManualControl, setClManualControl] = useState(0);

  let orpLevelMapper: { [p: number]: [number, number] };

  orpLevelMapper = {
    0: [545, 555],
    50: [645, 655],
    100: [745, 755],
  };

  // Fetch SPA From the DB
  useEffect(() => {
    if (id != null) {
      dispatch(fetchSpa(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (!settingsData) return;

    if (settingsData.SBORPlo) setOrpLevelBase((settingsData.SBORPlo - 545) / 2);

    if (settingsData.SBHr) {
      setClManualControl(settingsData.SBHr);
      setSpaboyHoursPerDay(settingsData.SBHr);
    }
  }, [settingsData.SBORPlo, settingsData.SBHr]);

  // MQTT Functionality:
  const [commandTopic] = useMQTT(id ?? "");
  const command = new Command(commandTopic);

  const handleClLevelChange = (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => {
    console.log({ value });
    console.log({ activeThumb });

    const selectedValue = value;

    setOrpLevelBase(selectedValue as number);

    command.updateSettings({
      SBORPlo: orpLevelMapper[selectedValue as number][0],
      SBORPhi: orpLevelMapper[selectedValue as number][1],
    });
  };

  /* CL manual control */
  const clManualControlChanged = (e: any) => {
    const newControlValue = e.target.checked ? 1 : 0;

    /* Automatic: 0 | Manual: slider value */
    setClManualControl(newControlValue);

    command.updateSettings({
      setSBHrs: newControlValue,
    });
  };

  /* Spa boy manual control */

  const [spaboyHoursPerDay, setSpaboyHoursPerDay] = useState(1);
  const handleSpaboyHoursChange = (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => {
    setSpaboyHoursPerDay(value as number);

    command.updateSettings({
      setSBHrs: value as number,
    });
  };

  return (
    <>
      <Header withLogo={false} withNavBtn={false} withUserAvatar={false}/>

      <div className="controls-page-container">
        <div className="primary-window">
          <div className="menu-page-header">
            <img
              src={SpaBoyLogo}
              alt="Logo"
              style={{ height: "2.8rem", margin: "0 "}}
            />
          </div>

          <LevelsStatus
            sbWear={liveData.sbWear}
            orpLevel={settingsData.sbORPind}
            phLevel={settingsData.sbpHind}
          />

          <div className="sub-menu-title">
            Chlorine Controls
            <div
              style={{
                textTransform: "capitalize",
                fontWeight: "400",
                color: clManualControl ? "var(--font-black)" : "var(--disabled-font)",
              }}
            >
              Adjust manually
              <MySwitch checked={clManualControl > 0} onChange={clManualControlChanged} />
            </div>
          </div>



              <div className="sub-menu-box">
                {clManualControl === 0 ?
                    <div className={spaBoyStyles.rangeInputWrapper}>
                      <div className={spaBoyStyles.inputTitle}>Set Chlorine Level</div>

                      <div className={spaBoyStyles.rangeInput}>
                        <div className={spaBoyStyles.inputLabels}>
                          <div className={orpLevelBase === 0 ? spaBoyStyles.active : ""}>
                            Low
                          </div>
                          <div className={orpLevelBase === 50 ? spaBoyStyles.active : ""}>
                            Medium
                          </div>
                          <div
                              className={orpLevelBase === 100 ? spaBoyStyles.active : ""}
                          >
                            High
                          </div>
                        </div>

                        <div className="filter-frequency-info">
                          <div className="filtration-slider">
                            <Slider
                                min={0}
                                max={100}
                                value={orpLevelBase}
                                onChange={handleClLevelChange}
                                step={50}
                                marks
                            />
                          </div>
                        </div>
                      </div>
                    </div> :

                    <div className={spaBoyStyles.rangeInputWrapper}>
                  <div className={spaBoyStyles.inputTitle}>Total Hours Per Day</div>

                  <div className={spaBoyStyles.rangeInput}>
                    <div className={spaBoyStyles.inputLabels}>
                      <div
                          className={
                            spaboyHoursPerDay === 1 ? spaBoyStyles.active : ""
                          }
                      >
                        1
                      </div>
                      <div
                          className={
                            spaboyHoursPerDay === 2 ? spaBoyStyles.active : ""
                          }
                      >
                        2
                      </div>
                      <div
                          className={
                            spaboyHoursPerDay === 3 ? spaBoyStyles.active : ""
                          }
                      >
                        3
                      </div>
                      <div
                          className={
                            spaboyHoursPerDay === 4 ? spaBoyStyles.active : ""
                          }
                      >
                        4
                      </div>
                      <div
                          className={
                            spaboyHoursPerDay === 5 ? spaBoyStyles.active : ""
                          }
                      >
                        5
                      </div>
                      <div
                          className={
                            spaboyHoursPerDay === 6 ? spaBoyStyles.active : ""
                          }
                      >
                        6
                      </div>
                      <div
                          className={
                            spaboyHoursPerDay === 7 ? spaBoyStyles.active : ""
                          }
                      >
                        7
                      </div>
                      <div
                          className={
                            spaboyHoursPerDay === 8 ? spaBoyStyles.active : ""
                          }
                      >
                        8
                      </div>
                    </div>

                    <div className="filter-frequency-info">
                      <div className="filtration-slider">
                        <Slider
                            min={1}
                            max={8}
                            value={spaboyHoursPerDay}
                            onChange={handleSpaboyHoursChange}
                            step={1}
                            marks
                        />
                      </div>
                    </div>

                    <div style={{color: "var(--font-black)"}}>
                      SpaBoy will operate for{" "}
                      {((spaboyHoursPerDay ?? 1) / 4).toFixed(2)} hour
                      {spaboyHoursPerDay / 4 > 1 ? "s" : ""} cycles, 4 times per
                      day.
                    </div>
                  </div>
                </div>
                }
              </div>
        </div>
      </div>
    </>
  );
};

export default SpaBoy;
