import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Radio,
  RadioGroup ,
  FormControlLabel,
  FormLabel 
} from "@mui/material";
import UsersTable from "../Tables/UsersTable";
import RootState from "../../../../redux/states/root.state";

interface ContainerProps {
  searchParams: any;
  setSearchParams: any;
  checked: any[];
  setChecked: any;
  setEditOpen: any;
  setDeleteOpen: any;
  setUser: any;
}

const UsersContainer: React.FC<ContainerProps> = ({
  searchParams,
  setSearchParams,
  checked,
  setChecked,
  setEditOpen,
  setDeleteOpen,
  setUser
}) => {
  // Data
  const users = useSelector((state: RootState) => state.users.data);

  // Search and Filter
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [radio, setRadio] = useState("dealer");

  // Checked items
  const [isCheckAll, setCheckAll] = useState(false);

  useEffect(() => {
    setChecked(users?.map((u)=>{return {id:u?.id, is_checked:false}}))
  }, [users,setChecked]);

  const isDisabled = () => {
    return checked.find(obj=>obj?.is_checked===true)===undefined
  }

  const handleFilter = () => {
    searchParams.delete('page'); //reset page
    username==="" ? searchParams.delete('username') : searchParams.set('username', username);
    email==="" ? searchParams.delete('email') : searchParams.set('email', email);
    name==="" ? searchParams.delete('name') : searchParams.set('name', name);
    userRole==="" ? searchParams.delete('role') : searchParams.set('role', userRole);
    setSearchParams(searchParams);
  }

  const handleClear = () => {
    searchParams.delete('username');
    searchParams.delete('email');
    searchParams.delete('name');
    searchParams.delete('role');
    searchParams.delete('page');
    setSearchParams(searchParams);
  }

  return (
    <>
    <div className="container">
        <div className="list">
          <div className="filters">
            <div className="search">
              <TextField
                id="username-search"
                label="Username"
                type="search"
                fullWidth={true}
                onChange={(e)=>setUsername(e.target.value)}
                onKeyDown={(e)=>(e.code==="Enter" ? handleFilter() : null)}
              />

              <TextField
                id="email-search"
                label="Email"
                type="search"
                fullWidth={true}
                onChange={(e)=>setEmail(e.target.value)}
                onKeyDown={(e)=>(e.code==="Enter" ? handleFilter() : null)}
              />

              <TextField
                id="name-search"
                label="Name"
                type="search"
                fullWidth={true}
                onChange={(e)=>setName(e.target.value)}
                onKeyDown={(e)=>(e.code==="Enter" ? handleFilter() : null)}
              />

              <FormControl fullWidth>
                <InputLabel>Role</InputLabel>
                <Select
                  label="User Role"
                  value={userRole}
                  onChange={e => setUserRole(e.target.value)}
                >
                  <MenuItem value={""}>All</MenuItem>
                  <MenuItem value={"admin"}>Admin</MenuItem>
                  <MenuItem value={"developer"}>Developer</MenuItem>
                  <MenuItem value={"corporate"}>Corporate</MenuItem>
                  <MenuItem value={"dealer"}>Dealer</MenuItem>
                  <MenuItem value={"owner"}>Owner</MenuItem>
                  <MenuItem value={"technician"}>Technician</MenuItem>
                  <MenuItem value={"sales"}>Sales</MenuItem>
                  <MenuItem value={"customer"}>Customer</MenuItem>
                </Select>
              </FormControl>

              <div className="filter-btn-group">
                <button onClick={handleFilter}>Apply Filters</button>
                <button onClick={handleClear}>Clear</button>
              </div>
            </div>

            <div className="firmware-updates">
              <div className="role-checkboxes">
              <FormControl>
                <FormLabel id="role-radio-buttons">Roles</FormLabel>
                <RadioGroup
                  aria-labelledby="radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={radio}
                >
                  <FormControlLabel value="admin" control={<Radio />} label="Admin" disabled={isDisabled()} onChange={()=>setRadio("admin")}/>
                  <FormControlLabel value="dealer" control={<Radio />} label="Dealer" disabled={isDisabled()} onChange={()=>setRadio("dealer")}/>
                  <FormControlLabel value="technician" control={<Radio />} label="Techncian" disabled={isDisabled()} onChange={()=>setRadio("technician")}/>
                  <FormControlLabel value="staff" control={<Radio />} label="Staff" disabled={isDisabled()} onChange={()=>setRadio("staff")}/>
                  <FormControlLabel value="customer" control={<Radio />} label="Customer" disabled={isDisabled()} onChange={()=>setRadio("customer")}/>
                </RadioGroup>
              </FormControl>
              </div>

              <div className="filter-btn-group">
                <button disabled={isDisabled()}>Apply Roles</button>
              </div>
            </div>

          </div>
          
          <UsersTable 
            users={users} 
            setEditOpen={setEditOpen} 
            setDeleteOpen={setDeleteOpen} 
            setUser={setUser} 
            isChecked={checked} 
            setChecked={setChecked} 
            isCheckAll={isCheckAll} 
            setCheckAll={setCheckAll} 
            searchParams={searchParams} 
            setSearchParams={setSearchParams}
          />
              
        </div>
      </div>     
    </>
  );
};

export default UsersContainer;
