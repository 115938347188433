export enum StonlyLinks {
    ERR0="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-remedies-2020-custom-series-Vq8PamDRFs/Steps/2050837,2051192",
    ERR1="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-IUensMOKIo/Steps/3763557,3768160",
    ERR2="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-IUensMOKIo/Steps/3763557,3763568",
    ERR3="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-IUensMOKIo/Steps/3763557,3763567",
    ERR4="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-IUensMOKIo/Steps/3763557,3768220",
    ERR5="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-remedies-2020-custom-series-Vq8PamDRFs/Steps/2050837,2064130",
    ERR6="http://localhost:3000/spa/eb233229-6fe5-488d-a8a7-f10d0296cd3c/errors",
    ERR7="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-remedies-2020-custom-series-Vq8PamDRFs/Steps/2050837,2064303",
    ERR8="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-IUensMOKIo/Steps/3763557,3768161",
    ERR9="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-remedies-2020-custom-series-Vq8PamDRFs/Steps/2050837,2064413",
    ERR10="",
    ERR11="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-remedies-2020-custom-series-Vq8PamDRFs/Steps/2050837,2064453",
    ERR12="",
    ERR13="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-IUensMOKIo/Steps/3763557,3768221",
    ERR14="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-IUensMOKIo/Steps/3763557,3768396",
    ERR15="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-IUensMOKIo/Steps/3763557,3763576",
    ERR16="",
    ERR17="",
    ERR18="",
    ERR19="",
    ERR20="",
    ERR21="",
    ERR22="",
    ERR23="",
    ERR24="",
    ERR25="",
    ERR26="",
    ERR27="",
    ERR28="",
    ERR29="",
    ERR30="",
    ERR31="",
    ERR32="",
    ERR33="",
    ERR34="",
    ERR35="",
    ERR36="",
    ERR37="",
    ERR38="",
    ERR39="",
    ERR40="",
    ERR41="",
    ERR42="",
    ERR43="",
    ERR44="",
    ERR45="",
    ERR46="",
    ERR47="",
    ERR48="",
    ERR49="",
    ERR50="",
    ERR51="",
    ERR52="",
    ERR53="",
    ERR54="",
    ERR55="",
    ERR56="",
    ERR57="",
    ERR58="",
    ERR59="",
    ERR60="",
    ERR61="",
    ERR62="",
    ERR63="",

    STAT0="",
    STAT1="",
    STAT2="",
    STAT3="",
    STAT4="",
    STAT5="",
    STAT6="",
    STAT7="",
    STAT8="",
    STAT9="",
    STAT10="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-IUensMOKIo/Steps/3763557,3768395",
    STAT11="",
    STAT12="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-remedies-2020-custom-series-Vq8PamDRFs/Steps/2050837,2064509",
    STAT13="",
    STAT14="",
    STAT15="",
    STAT16="",
    STAT17="",
    STAT18="",
    STAT19="",
    STAT20="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-remedies-2020-custom-series-Vq8PamDRFs/Steps/2050837,2064601",
    STAT21="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-remedies-2020-custom-series-Vq8PamDRFs/Steps/2050837,2064603",
    STAT22="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-remedies-2020-custom-series-Vq8PamDRFs/Steps/2050837,2064632",
    STAT23="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-remedies-2020-custom-series-Vq8PamDRFs/Steps/2050837,2064638",
    STAT24="",
    STAT25="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-remedies-2020-custom-series-Vq8PamDRFs/Steps/2050837,2064640",
    STAT26="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-remedies-2020-custom-series-Vq8PamDRFs/Steps/2050837,2064641",
    STAT27="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-remedies-2020-custom-series-Vq8PamDRFs/Steps/2050837,2064643",
    STAT28="https://arcticspas-yfxhw.stonly.com/kb/guide/en/error-codes-remedies-2020-custom-series-Vq8PamDRFs/Steps/2050837,2064657",
    STAT29="",
    STAT30="",
    STAT31="",
    STAT32="",
    STAT33="",
    STAT34="",
    STAT35="",
    STAT36="",
    STAT37="",
    STAT38="",
    STAT39="",
    STAT40="",
    STAT41="",
    STAT42="",
    STAT43="",
    STAT44="",
    STAT45="",
    STAT46="",
    STAT47="",
    STAT48="",
    STAT49="",
    STAT50="",
    STAT51="",
    STAT52="",
    STAT53="",
    STAT54="",
    STAT55="",
    STAT56="",
    STAT57="",
    STAT58="",
    STAT59="",
    STAT60="",
    STAT61="",
    STAT62="",
    STAT63="",
}