import spaBoyStyles from "../../../../assets/scss/SpaBoy.module.scss";
import { ElectrodeIconWithIndicator } from "../../../../assets/svg/svg";
import React from "react";
import TimerWithProgressBar from "./TimerWithProgressBar";

interface Props {
  sbWear: number;
  phLevel: number;
  orpLevel: number;
}

export const LevelsStatus = ({ sbWear, phLevel, orpLevel }: Props) => {
  //pH gradient levels
  const getPhGradient = () => {
    return "linear-gradient(to right, #ff4d4d, #e6ce46, #49cb49, #99ccff, #3a6fc4)";
  };
  //pH discrete gradient  levels
  const chlorineLevels = [
    "#ff4d4d",
    "#f2d579",
    "#49cb49",
    "#f2d579",
    "#ff4d4d",
  ];

  // pH Marker position (14? 7? what is the pH range for being low high?)
  const getPhPosition = (level: number) => {
    return `${(level / 7) * 100}%`; // Convert pH to percentage for the scale
  };

  // Determine the index of the Chlorine level (from 0 to 4)
  const getClLevelIndex = (level: number) => {
    return Math.min(Math.max(0, level - 1), 4);
  };

  const getChlorineLabel = (level: number) => {
    switch (level) {
      case 1:
        return "Very Low";
      case 2:
        return "Low";
      case 3:
        return "Good";
      case 4:
        return "High";
      case 5:
        return "Very High";
      default:
        return "";
    }
  };

  return (
    <div className={spaBoyStyles.statusContainer}>
      <div className="status-bar-line" style={{ width: "100%" }}>
        <div className={spaBoyStyles.phLabel}>
          <span>pH</span>
        </div>

        {/* pH Level */}
        <div className={spaBoyStyles.meterWrapper}>
          <div className={spaBoyStyles.barWrapper}>
            <div
              className={spaBoyStyles.bar}
              style={{ background: getPhGradient() }}
            >
              <div
                className={spaBoyStyles.indicatorTriangle}
                style={{ left: getPhPosition(phLevel) }}
              ></div>
            </div>
            <div className={spaBoyStyles.valueWrapper}>
              <span style={{ left: getPhPosition(phLevel) }}>{phLevel?.toFixed(2)}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="status-bar-line" style={{ width: "100%" }}>
        <div className={spaBoyStyles.chlorineLabel}> Chlorine</div>

        <div className={spaBoyStyles.meterWrapper}>
          <div className={spaBoyStyles.barWrapper}>
            <div className={spaBoyStyles.discreteBarWrapper}>
              {chlorineLevels.map((color, index) => (
                <div
                  key={index}
                  className={spaBoyStyles.discreteBar}
                  style={{
                    backgroundColor: color,
                    opacity: index === getClLevelIndex(orpLevel) ? 1 : 0.3,
                  }}
                ></div>
              ))}
              <div
                className={spaBoyStyles.indicatorTriangle}
                style={{ left: `${(getClLevelIndex(orpLevel) / 4) * 100}%` }}
              ></div>
            </div>
            <div className={spaBoyStyles.valueWrapper}>
              <span style={{ left: `${(getClLevelIndex(orpLevel) / 4) * 100}%` }}>{orpLevel?.toFixed(2)}</span> {/* Display Chlorine value */}
              <span style={{ left: `${(getClLevelIndex(orpLevel) / 4) * 100}%` }} className={spaBoyStyles.chlorineDynamicLabel}>
                {getChlorineLabel(orpLevel)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
