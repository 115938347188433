import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SettingsState from "../states/settings.state";

interface SettingsData {
    [key: string]: any;
}

const initialState: SettingsState = {
    data: {},
    loading: false,
    error: null,
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setSettingsData: (state, action: PayloadAction<SettingsData>) => {
            return {
                ...state,
                data: action.payload
            };
        },
    },
});

export const { setSettingsData } = settingsSlice.actions;
export default settingsSlice.reducer;
