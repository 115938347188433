import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

const Spinner = () => {
    return (
        <div style={{minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <CircularProgress/>
        </div>
    )
}

export default Spinner;