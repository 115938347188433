import { createAsyncThunk } from '@reduxjs/toolkit';
import RootState from '../states/root.state';
import { getConstantsHistory, getErrorsHistory, getLiveHistory, getSettingsHistory, getStatusesHistory } from '../../services/api/spaHistory.api';

export const fetchLiveHistory = createAsyncThunk<
    any,
    { guid: string; date_from: number;date_to: number,keys: any[]},
    { rejectValue: string; state: RootState }
>(
    'live-history',
    async (spaInfo, { getState, rejectWithValue }) => {
        try {
            const { user } = getState();
            const token = user.accessToken;

            const response = await getLiveHistory(token, spaInfo);

            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while fetching live history.');
            }
        }
    }
);

export const fetchSettingsHistory = createAsyncThunk<
    any,
    { guid: string; date_from: number;date_to: number,keys: any[]},
    { rejectValue: string; state: RootState }
>(
    'settings-history',
    async (spaInfo, { getState, rejectWithValue }) => {
        try {
            const { user } = getState();
            const token = user.accessToken;

            const response = await getSettingsHistory(token, spaInfo);

            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while fetching settings history.');
            }
        }
    }
);

export const fetchConstantsHistory = createAsyncThunk<
    any,
    { guid: string; date_from: number;date_to: number,keys: any[]},
    { rejectValue: string; state: RootState }
>(
    'constants-history',
    async (spaInfo, { getState, rejectWithValue }) => {
        try {
            const { user } = getState();
            const token = user.accessToken;

            const response = await getConstantsHistory(token, spaInfo);

            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while fetching constants history.');
            }
        }
    }
);

export const fetchErrorsHistory = createAsyncThunk<
    any,
    { guid: string; date_from: number;date_to: number,keys: any[]},
    { rejectValue: string; state: RootState }
>(
    'errors-history',
    async (spaInfo, { getState, rejectWithValue }) => {
        try {
            const { user } = getState();
            const token = user.accessToken;

            const response = await getErrorsHistory(token, spaInfo);

            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while fetching errors history.');
            }
        }
    }
);

export const fetchStatusesHistory = createAsyncThunk<
    any,
    { guid: string; date_from: number;date_to: number,keys: any[]},
    { rejectValue: string; state: RootState }
>(
    'statuses-history',
    async (spaInfo, { getState, rejectWithValue }) => {
        try {
            const { user } = getState();
            const token = user.accessToken;

            const response = await getStatusesHistory(token, spaInfo);

            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while fetching statuses history.');
            }
        }
    }
);
