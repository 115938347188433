import "./DealershipsPage.css"
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AppDispatch } from "../../redux/store";
import Header from "../../components/common/Header/Header";
import { fetchDealerships } from "../../redux/actions/dealership.actions";
import DealershipsContainer from "../../components/common/Tables/TableContainers/DealershipsContainer";

const DealershipsPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(fetchDealerships(searchParams.toString()));
  }, [dispatch,searchParams]);

  return (
    <>
    <Header/>
    <DealershipsContainer searchParams={searchParams} setSearchParams={setSearchParams}/>    
    </>
  );
};

export default DealershipsPage;
