// src/redux/reducers/errorLog.reducer.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { upgradeFirmware, uploadFirmware } from '../actions/firmware.actions';
import FirmwareUpgradeState from '../states/firmwareUpgrade.state';

const initialState: FirmwareUpgradeState = {
  data: null,
  loading: false,
  error: null,
};

const FirmwareUpgradeSlice = createSlice({
  name: 'upgradeFirmware',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(upgradeFirmware.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(upgradeFirmware.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(upgradeFirmware.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      })
      
      .addCase(uploadFirmware.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadFirmware.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(uploadFirmware.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      });;
  },
});

export default FirmwareUpgradeSlice.reducer;