import "../SpaDetailsPage.css"
import React from "react";
import { useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import { SpaSettingsLabels } from "../../../mqttData/settings/spaSettingsLabels";

const SpaCard: React.FC = () => {
    const spa = useSelector((state: RootState) => state.spa.data);
    // Spa data and settings:
    const settingsData = useSelector((state: RootState) => state.settings.data);
    console.log(spa)
  
  return (
    <>
      <div className="card-container spa">
        <div className="grid-item set">
            <div className="grid-item id">
                <div>GUID</div>
                <div>SN</div>
                <div>Nickname</div>
            </div>

            <div className="grid-item values">
                <div>{spa?.id??`-`}</div>
                <div>{spa.arctic_serial_number??"N/A"}</div>
                <div>{spa?.nick_name??`n/a`}</div>
            </div>  
        </div>


        <div className="grid-item set">
            <div className="grid-item id">
                <div>Yocto</div>
                <div>LPC</div>
                <div>SpaBoy</div>
            </div>

            <div className="grid-item values">
                <div>{spa?.yocto_version??`--`}</div>
                <div>{spa?.lpc_version??`--`}</div>
                <div>{spa?.spaboy_firmware_version??`--`}</div>
            </div>
        </div>

        <div className="grid-item set">
            <div className="grid-item id">
                <div>RFIDa</div>
                <div>RFIDb</div>
                <div>RFIDComs</div>
            </div>

            <div className="grid-item values">
                <div>{settingsData[SpaSettingsLabels.TAG1]??'--'}</div>
                <div>{settingsData[SpaSettingsLabels.TAG2]??'--'}</div>
                <div>{settingsData.RFIDConnected ? `CONNECTED`: `DISCONNECTED`}</div>
            </div>
        </div>

        <div className="grid-item set">
            <div className="grid-item id">
                <div>Temp. Offset</div>
                <div>ORP Offset</div>
                <div>pH Offset</div>
            </div>

            <div className="grid-item values">
                <div>{settingsData.Toff ?? 0}°F</div>
                <div>{settingsData[SpaSettingsLabels.cfgORPoff] ?? 0}</div>
                <div>{settingsData[SpaSettingsLabels.cfgpHoff] ?? 0}</div>
            </div>
        </div>
      </div>
    </>
  );
};

export default SpaCard;
