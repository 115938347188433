import "../Tables.css";
import React, {useState} from "react";
import { useSelector } from "react-redux";
import HistoryTable from "../Tables/HistoryTable";
import RootState from "../../../../redux/states/root.state";

interface ContainerProps {
  searchParams: any;
  setSearchParams: any;
}

const UpdateHistoryContainer: React.FC<ContainerProps> = ({
  searchParams,
  setSearchParams
}) => {
  // Date Ranges
  var last_week = new Date();
  last_week.setDate(last_week.getDate() - 7)

  const [startSent, setSentStart] = useState(last_week.toISOString().split("T")[0]);
  const [endSent, setSentEnd] = useState(new Date().toISOString().split("T")[0]);

  const [startUpdate, setUpdateStart] = useState(last_week.toISOString().split("T")[0]);
  const [endUpdate, setUpdateEnd] = useState(new Date().toISOString().split("T")[0]);

  // Data
  const history = useSelector((state: RootState) => state.upgradeRequests.data);

  const handleDateChange = (e:any) => {
    let val = e.target.value;
    let id = e.target.id;
    var date = new Date();
    switch(val){
      case "":
        date.setDate(date.getDate() - 7);
        id==='sent-select'?setSentStart(date.toISOString().split("T")[0]):setUpdateStart(date.toISOString().split("T")[0]);
        break;

      case "today":
        id==='sent-select'?setSentStart(date.toISOString().split("T")[0]):setUpdateStart(date.toISOString().split("T")[0]);
        break;

      case "last_month":
        date.setMonth(date.getMonth()-1);
        id==='sent-select'?setSentStart(date.toISOString().split("T")[0]):setUpdateStart(date.toISOString().split("T")[0]);
        break;

      case "six_months":
        date.setMonth(date.getMonth()-6);
        id==='sent-select'?setSentStart(date.toISOString().split("T")[0]):setUpdateStart(date.toISOString().split("T")[0]);
        break;

      case "last_year":
        date.setFullYear(date.getFullYear() - 1);
        id==='sent-select'?setSentStart(date.toISOString().split("T")[0]):setUpdateStart(date.toISOString().split("T")[0]);
        break;
    }
  }

  return (
    <div className="container">
        <div className="list">
          <div className="filters">
            <div className="search">

              <div className="date-input">
                <span>Status</span>
                <select className="select-style" name="log-status" id="status-select">
                  <option value="">--Please select an option--</option>
                  <option value="fufilled">Fufilled</option>
                  <option value="err">Error</option>
                  <option value="sent">Sent</option>
                  <option value="pending">Pending</option>
                </select>
              </div>
              
              
              <div className="date-input">
                    <div className="since-date">
                      <span>Sent </span>
                        <select className="date-range" name="date-range" id="sent-select" onChange={(e)=>handleDateChange(e)}>
                          <option value="">Last Week</option>
                          <option value="today">Today</option>
                          <option value="last_month">Last Month</option>
                          <option value="six_months">Six Months</option>
                          <option value="last_year">Last Year</option>
                        </select>
                    </div>
                    <input type="date" value={startSent??""} onChange={(e)=>setSentStart(e.target.value)} max={endSent}></input>
                    <br></br>
                    <input 
                      type="date" 
                      value={endSent??""} 
                      onChange={(e)=>setSentEnd(e.target.value===""? new Date().toISOString().split("T")[0] : e.target.value)} 
                      min={startSent} 
                      max={new Date().toISOString().split("T")[0]}>
                    </input>
                  </div>
                  
                  <div className="date-input">
                    <div className="since-date">
                      <span>Updated </span>
                        <select className="date-range" name="date-range" id="update-select" onChange={(e)=>handleDateChange(e)}>
                          <option value="">Last Week</option>
                          <option value="today">Today</option>
                          <option value="last_month">Last Month</option>
                          <option value="six_months">Six Months</option>
                          <option value="last_year">Last Year</option>
                        </select>
                    </div>
                    <input type="date" value={startUpdate??""} onChange={(e)=>setUpdateStart(e.target.value)} max={endUpdate}></input>
                    <br></br>
                    <input 
                      type="date" 
                      value={endUpdate??""} 
                      onChange={(e)=>setUpdateEnd(e.target.value===""? new Date().toISOString().split("T")[0] : e.target.value)} 
                      min={startUpdate} 
                      max={new Date().toISOString().split("T")[0]}>
                    </input>
                  </div>
                
              <div className="filter-btn-group">
                <button>Apply Filters</button>
                <button>Clear</button>
              </div>
            </div>
          </div>
          
          <HistoryTable history={history} searchParams={searchParams} setSearchParams={setSearchParams}/>
              
        </div>
      </div>
  );
};

export default UpdateHistoryContainer;
