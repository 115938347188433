import styles from "../../../assets/scss/Modal.module.scss";
import React from "react";
import {Modal} from '@mui/material';


interface DeleteUserModalProps {
    isOpen: boolean, 
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    user: any;
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({isOpen, setOpen, user}) => {
    const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <div className={styles.modal}>
            <div className={styles.header}>
                <p> Delete {user?.name}? </p>
            </div>
            
            <div className="modal-btn-group">
                <button className={styles.delete} onClick={handleClose}> Delete </button>
                <button className="as-text" onClick={handleClose}> Cancel </button>
            </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteUserModal;
