import React, { useEffect, useState } from "react";
import { Slider } from "@mui/material";

interface SliderProps {
  onTempChange: (newTemp: number | number[]) => void;
  initialValue: number;
  phValue: number;
  clIndicator: number;
  currentTemp: number;
  isConnected: boolean;
}

export const TempSlider: React.FC<SliderProps> = ({
  initialValue,
  onTempChange,
  phValue ,
  clIndicator,
  currentTemp,
  isConnected,
}) => {
  const [value, setValue] = useState<number>(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    onTempChange(newValue);
    setValue(newValue as number);
  };

    let clMapper: { [p: number]: string };

     clMapper = {
      0 : 'very low',
      1 : 'low',
      2 : 'ok',
      3 : 'high',
      4 : 'very high',
  }

  return (
    <div className="filter-frequency-info">
      <div className="control-page-ph-cl">
        <div className="water-status-info">
          <div>
            <div>pH</div>
            <div>Cl</div>
          </div>

          <div>
            <div>{phValue}</div>
            <div>{clMapper[clIndicator]}</div>
          </div>

        </div>
      </div>
      <div className="temperature-setting">
        Set Point
        <div className="temperature-value"> {currentTemp ?? `--`}&deg;F </div>
      </div>

      <div className="filtration-slider">
        <Slider
          value={value ?? 0}
          marks={[{
            value: value ?? 0,
            label: `${value ?? 0}°F`
          }]}
          onChange={handleChange}
          min={59}
          max={104}
          step={1}
          disabled={!isConnected}
          sx={{
            backgroundImage:
              "linear-gradient(to right, rgb(16,117,236), rgb(247,23,23))",
            "& .MuiSlider-thumb": {
              backgroundColor: "rgba(255,255,255, 0.8)",
            },
            "& .MuiSlider-track": {
              color: "transparent",
            },
            '& .MuiSlider-markLabel': {
              color: 'var(--font-black)',
            }
          }}
          defaultValue={30}
          aria-label="Temperature Control Slider"
        />
      </div>
    </div>
  );
};
