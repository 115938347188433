import styles from "../../../assets/scss/Modal.module.scss";
import React, { useState, useEffect } from "react";
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import {
  Modal, 
  TextField, 
  CircularProgress,  
  Radio,
  RadioGroup,
  FormControlLabel,
 } from '@mui/material';
import { fetchUserById } from "../../../redux/actions/users.actions";

interface EditUserModalProps {
    isOpen: boolean, 
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    id: any;
}

const EditUserModal: React.FC<EditUserModalProps> = ({isOpen, setOpen, id}) => {
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector((state: RootState) => state.usersById.data);
  const isLoading = useSelector((state: RootState) => state.usersById.loading);

  const [usernameInput, setUsername] = useState("");
  const [emailInput, setEmail] = useState("");
  const [nameInput, setName] = useState("");
  const [role, setRole] = useState("");

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (id) {
      dispatch(fetchUserById(id))
    }
  }, [dispatch,id]);

  useEffect(() => {
    if (user){
      setUsername(user.username??"");
      setEmail(user.email??"");
      setName(user.name??"");
      if(user.roles!==undefined && user.roles.length>0){
        let newArr = [...user.roles];
        let role = newArr.pop()
        setRole(role?.name??"");
      }
      
    }
}, [user]);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        {isLoading ?
        <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
        :
        <div className={styles.modal}>
            <div className={styles.header}>
                <p><strong>Edit {user?.name}</strong></p>
            </div>
            <div className="edit-modal-input">
                <TextField id="username" label="Username" variant="outlined" fullWidth value={usernameInput} onChange={(e)=>setUsername(e.target.value)}/>
                <TextField id="email" label="Email" variant="outlined" fullWidth value={emailInput} onChange={(e)=>setEmail(e.target.value)}/>
                <TextField id="name" label="Name" variant="outlined" fullWidth value={nameInput} onChange={(e)=>setName(e.target.value)}/>

                <div className="edit-header">Roles</div>
                <div className="roles">
                  <RadioGroup
                    name="radio-buttons-group"
                    value={role}
                  >
                    <FormControlLabel value="admin" control={<Radio />} label="Admin" onChange={()=>setRole("admin")}/>
                    <FormControlLabel value="developer" control={<Radio />} label="Developer" onChange={()=>setRole("developer")}/>
                    <FormControlLabel value="corporate" control={<Radio />} label="Corporate" onChange={()=>setRole("corporate")}/>
                    <FormControlLabel value="owner" control={<Radio />} label="Owner" onChange={()=>setRole("owner")}/>
                    <FormControlLabel value="dealer" control={<Radio />} label="Dealer" onChange={()=>setRole("dealer")}/>
                    <FormControlLabel value="technician" control={<Radio />} label="Techncian" onChange={()=>setRole("technician")}/>
                    <FormControlLabel value="sales" control={<Radio />} label="Sales" onChange={()=>setRole("sales")}/>
                    <FormControlLabel value="customer" control={<Radio />} label="Customer" onChange={()=>setRole("customer")}/>
                  </RadioGroup>
                </div>

            </div>
            <div className="modal-btn-group">
                <button className="as-text" style={{color:'var(--as-red)'}} onClick={handleClose}> Cancel </button>
                <button className="as-button" onClick={handleClose} disabled={isLoading}>                         
                  {isLoading ? (
                    <CircularProgress size={"24px"} style={{ color: "as-white" }} />
                    ) : (
                    "Save"
                    )}
                </button>
                
            </div>
        </div>}
      </Modal>
    </div>
  );
};

export default EditUserModal;
