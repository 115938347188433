import { Switch, chipClasses } from "@mui/material";
import React from "react";

interface MySwitchProps {
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const MySwitch: React.FC<MySwitchProps> = ({ checked, onChange, disabled=false }) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      sx={{
        "& .MuiSwitch-switchBase.Mui-checked": {
          color: "var(--as-red)",
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
          backgroundColor: "var(--as-red)",
        },
        "& .MuiSwitch-track": {
          backgroundColor: "var(--as-gray)",
        },
      }}
    />
  );
};

export default MySwitch;
