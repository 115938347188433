import "../Tables.css"
import React, { useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import SpasTable from "../Tables/SpasTable";

interface ContainerProps {
    searchParams: any;
    setSearchParams: any;
    checked: any[];
    setChecked: any;
    setConfirmUpdate: any;
    setConfirmLogs: any;
    setDeleteOpen: any;
    setEditOpen:any;
    setSpaToModify:any;
}


const SpasContainer: React.FC<ContainerProps> = ({
    searchParams,
    setSearchParams,
    checked,
    setChecked,
    setConfirmUpdate,
    setConfirmLogs,
    setDeleteOpen,
    setEditOpen,
    setSpaToModify,
}) => {
  // Search and Filter
  const [name, setName] = useState("");
  const [serial, setSerial] = useState("");
  const [ip, setIp] = useState("");
  const [email, setEmail] = useState("");
  const [online, setOnline] = useState(searchParams.get('online')!==null);
  const [registered, setRegistered] = useState(searchParams.get('registered')!==null);

  const handleFilter = () => {
    searchParams.delete('page'); //reset page
    name==="" ? searchParams.delete('nick_name') : searchParams.set('nick_name', name);
    serial==="" ? searchParams.delete('arctic_serial_number') : searchParams.set('arctic_serial_number', serial);
    ip==="" ? searchParams.delete('ip_address') : searchParams.set('ip_address', ip);
    email==="" ? searchParams.delete('user_email') : searchParams.set('user_email', email);
    !online ? searchParams.delete('online') : searchParams.set('online', 'true');
    !registered ? searchParams.delete('registered') : searchParams.set('registered', 'true');
    setSearchParams(searchParams);
  }

  const handleClear = () => {
    setName("");
    searchParams.delete('nick_name');
    setSerial("");
    searchParams.delete('arctic_serial_number');
    setIp("");
    searchParams.delete('ip_address');
    setEmail("");
    searchParams.delete('user_email');
    setOnline(false);
    searchParams.delete('online');
    setRegistered(false);
    searchParams.delete('registered');
    setSearchParams(searchParams);
  }

  return (
        <div className="list">
          <div className="filters">
            <div className="search">
                <TextField
                  id="name-search"
                  label="Nickname"
                  type="search"
                  fullWidth={true}
                  value={name??""}
                  onChange={(e)=>setName(e.target.value)}
                  onKeyDown={(e)=>(e.code==="Enter" ? handleFilter() : null)}
                   
                />

                <TextField
                  id="serial-search"
                  label="Serial Number"
                  type="search"
                  fullWidth={true}
                  value={serial??""}
                  onChange={(e)=>setSerial(e.target.value)}
                  onKeyDown={(e)=>(e.code==="Enter" ? handleFilter() : null)}
                   
                />

                <TextField
                  id="ip-search"
                  label="IP Address"
                  type="search"
                  fullWidth={true}
                  value={ip??""}
                  onChange={(e)=>setIp(e.target.value)}
                  onKeyDown={(e)=>(e.code==="Enter" ? handleFilter() : null)}
                   
                />

                <TextField
                  id="email-search"
                  label="Email Address"
                  type="search"
                  fullWidth={true}
                  value={email??""}
                  onChange={(e)=>setEmail(e.target.value)}
                  onKeyDown={(e)=>(e.code==="Enter" ? handleFilter() : null)}
                />
              <span>Yocto: </span>
              <div className="firmware-versions">
                <FormControl fullWidth>
                  <InputLabel>Min</InputLabel>
                  <Select
                    label="yocto-selection-min"
                    defaultValue="" 
                     
                  >
                    <MenuItem value={1}>1.1</MenuItem>
                    <MenuItem value={2}>1.2</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel>Max</InputLabel>
                  <Select
                    label="yocto-selection-max"
                    defaultValue="" 
                     
                  >
                    <MenuItem value={1}>1.1</MenuItem>
                    <MenuItem value={2}>1.2</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <span>LPC: </span>
              <div className="firmware-versions">
                <FormControl fullWidth>
                  <InputLabel>Min</InputLabel>
                  <Select
                    label="lpc-selection-min"
                    defaultValue="" 
                     
                  >
                    <MenuItem value={1}>1.1</MenuItem>
                    <MenuItem value={2}>1.2</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel>Max</InputLabel>
                  <Select
                    label="lpc-selection-max"
                    defaultValue="" 
                     
                  >
                    <MenuItem value={1}>1.1</MenuItem>
                    <MenuItem value={2}>1.2</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <span>SpaBoy: </span>
              <div className="firmware-versions">
                <FormControl fullWidth>
                  <InputLabel>Min</InputLabel>
                  <Select
                    label="spaboy-selection-min"
                    defaultValue="" 
                     
                  >
                    <MenuItem value={1}>1.1</MenuItem>
                    <MenuItem value={2}>1.2</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel>Max</InputLabel>
                  <Select
                    label="spaboy-selection-max"
                    defaultValue="" 
                     
                  >
                    <MenuItem value={1}>1.1</MenuItem>
                    <MenuItem value={2}>1.2</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="checkbox-filters">
                <label><input type="checkbox" checked={online} onChange={(e)=>setOnline(e.target.checked)}></input>Online</label>
                <label><input type="checkbox" checked={registered} onChange={(e)=>setRegistered(e.target.checked)}></input>Registered</label>
              </div>

              <div className="filter-btn-group">
                <button onClick={handleFilter}>Apply Filters</button>
                <button onClick={handleClear}>Clear</button>
              </div>
            </div>
          </div>

          <div className="table-section">
            <SpasTable 
              searchParams={searchParams} 
              setSearchParams={setSearchParams} 
              setChecked={setChecked} 
              checked={checked}
              setDeleteOpen={setDeleteOpen}
              setEditOpen={setEditOpen}
              setSpaToModify={setSpaToModify}
            />
            <div className="filter-btn-group spa-actions">
              <button disabled={checked.length===0} onClick={()=>setConfirmUpdate(true)}>Update Firmware {checked.length>0&&`(${checked.length})`}</button>
              <button disabled={checked.length===0} onClick={()=>setConfirmLogs(true)}>Request Logs {checked.length>0&&`(${checked.length})`}</button>
            </div>
          </div>
        </div>
)
};

export default SpasContainer;