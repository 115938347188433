import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import AccessDenied from '../../pages/General/AccessDenied';
import RootState from '../../redux/states/root.state';

interface ProtectedRouteProps {
    children: React.ReactNode;
}

const DealerRoute: React.FC<ProtectedRouteProps> = ({children}) => {
    const {data, accessToken} = useSelector((state: RootState) => state.user);
    const [isCustomer, setCustomer] = useState(false);
    const [canAccessAdminPanel, setCanAccessAdminPanel] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!accessToken) {
            navigate('/login');
        }

        setCanAccessAdminPanel(
            data?.roles?.some((role: any) =>
                     ["dealer", "admin", "owner", "corporate", "developer"].includes(role.name)
                ) || false
        )


    }, [accessToken, data, navigate]);


    return (accessToken && canAccessAdminPanel) ? <>{children}</> : <AccessDenied />

};

export default DealerRoute;