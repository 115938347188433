import React from "react";
import Header from "../../../components/common/Header/Header";

const Troubleshoot = () => {
  return (
      <>
          <Header withLogo={false} withNavBtn={false} withUserAvatar={false} header="Troubleshoot"/>

          <div className="page-wrapper">
                <p>Click <a href="https://arcticspas-yfxhw.stonly.com/kb/en" target="_blank" rel="noreferrer">HERE</a> to be redirected to troubleshoot guide.</p>
          </div>
      </>
  );
};

export default Troubleshoot;
