import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    asRed: Palette['primary'];
  }
  interface PaletteOptions {
    asRed?: PaletteOptions['primary'];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(163, 32, 53)', // AS Red
    },
    asRed: {
      main: 'rgb(163, 32, 53)',
    },
    // You can keep other color definitions here
  },
});

export default theme;