import React from "react";
import Header from "../../../components/common/Header/Header";
import sharedStyles from "../../../assets/scss/Shared.module.scss";
import styles from "../../../assets/scss/Settings.module.scss";
import {LinkOut} from "../../../assets/svg/svg";

const Manuals = () => {
    return (
        <>
            <Header withLogo={false} withNavBtn={false} withUserAvatar={false}/>
            <div className="primary-window">
                <div className="menu-page-header">Manuals & Warranty</div>
                <br></br>
                <div className={sharedStyles.fullHeight} style={{ padding: "32px" }}>
                    <div className={styles.settingsContainer}>
                        <div className={styles.buttonGroup}>

                                <button className={styles.button}>
                                    <a className={styles.link} href="https://www.arcticspas.com/arctic-spas-manual/" target="_blank" rel="noopener noreferrer">
                                        <div className={styles.buttonContent}>
                                            <p>{`Arctic Spas Owner Manuals`}</p>
                                            <LinkOut/> 
                                        </div>   
                                    </a>      
                                </button>

                            <button className={styles.button}>
                                <a  className={styles.link} href="https://www.arcticspas.ca/warranty/" target="_blank" rel="noopener noreferrer">
                                    <div className={styles.buttonContent}>
                                        <p>{`Warranty Information`}</p>
                                        <LinkOut/> 
                                    </div>
                                </a>           
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Manuals;
