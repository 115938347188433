import styles from "../../../assets/scss/Modal.module.scss";
import React from "react";
import {Modal} from '@mui/material';


interface EditModalProps {
    isOpen: boolean, 
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    spa:any,
    spasn:any,
}

const EditSerialModal: React.FC<EditModalProps> = ({isOpen, setOpen, spa,spasn}) => {
    const [input, setInput] = React.useState(``);

    const handleClose = () => setOpen(false);

    /* Handle text input */
    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        let input = e.target.value;
        setInput(input);
    };

    const handleEdit = () => {
        if(input!==""){
            console.log(`New Serial: ${input}`) /* Placeholder till backend implemented */
        }
        setInput(``);
        setOpen(false);
    }

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <div className={styles.modal}>
            <div className={styles.header}>
                <p><strong>Set Spa Serial Number</strong></p>
            </div>
            <div className={styles.body}>
                <form className={styles.forms}>
                    <label className={styles.formLabel}>Spa Serial Number:</label>
                    <input type="number" id="input" className={styles.textInput} placeholder={spasn} onChange={handleInput}></input>
                </form>
                
            </div>
            <div className={styles.buttons}>
                <button className={styles.confirm} onClick={handleEdit}> Confirm </button>
                <button className={styles.cancel} onClick={handleClose}> Cancel </button>
            </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditSerialModal;
