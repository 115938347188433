import React from 'react';
import {NavigateOptions, To, useNavigate} from 'react-router-dom';

interface NavigatableDivProps {
  children: React.ReactNode;
  destination: string | number;
  className?: string;

}

const NavigatableDiv: React.FC<NavigatableDivProps> = ({ children, destination, className  }) => {
  const navigate = useNavigate() as (to: number | To, options?: NavigateOptions) => void;

  const handleClick = () => {
    // Navigate to the specified destination when the div is clicked
    navigate(destination);
  };

  return (
    <div onClick={handleClick} className={className} style={{ cursor: 'pointer' }}>
      {children}
    </div>
  );
};

export default NavigatableDiv;