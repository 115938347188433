export const formatErrorCode= (code:string) => {
    if (code.length===5) {
        return code.slice(0,2)+code.slice(3); //ie. ERR10->ER 10
      }
    return code.slice(0,2)+'0'+code.slice(3); // ie. ERR0 -> ER 00
};

export const formatStatusCode= (code:string) => {
    if (code.length===6) {
        return code.slice(0,4)+code.slice(4); //ie. STAT10->STAT 10
      }
    return code.slice(0,4)+'0'+code.slice(4); // ie. STAT0 -> STAT 00
};