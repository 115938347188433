import "../SpaDetailsPage.css"
import React, {useEffect, useState} from "react";
import { DeregisterIcon, RegisterIcon } from "../../../assets/svg/svg";
import RootState from "../../../redux/states/root.state";
import { useSelector } from "react-redux";

const UserCard: React.FC<{user:any, setOpen:any}> = ({user, setOpen}) => {
  const {data:currentUser} = useSelector((state: RootState) => state.user);
  const [canEditUser, setCanEditUser] = useState(false);

  useEffect(() => {
    if (currentUser){
        setCanEditUser(currentUser?.roles?.some((role: any) =>
            ["admin"].includes(role.name)
        ) || false);
    }
}, [currentUser,setCanEditUser]);

  if (user) {
    return (
      <>
        <div className="card-container user">
          {canEditUser&&<div className="absolute-icon clickable-icon" onClick={()=>setOpen(true)}><DeregisterIcon/></div>}
          <div><strong>{user?.name??'n/a'}</strong></div>
          <div>{user?.username??'n/a'}</div>
          <div>{user?.email??'n/a'}</div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="card-container user">
        {canEditUser&&<div className="absolute-icon clickable-icon" onClick={()=>setOpen(true)}><RegisterIcon/></div>}
        <div><strong>{`No Registered User`}</strong></div>
      </div>
    </>
  );

};

export default UserCard;
