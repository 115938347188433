import styles from "../../../assets/scss/Modal.module.scss";
import React, {useEffect} from "react";
import {Modal} from '@mui/material';
import {requestLogs} from "../../../redux/actions/logsRequest.actions";
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
    CircularProgress,
    Box,
  } from "@mui/material";
import RootState from "../../../redux/states/root.state";
import { useNavigate } from "react-router-dom";

interface ModalProps {
    isOpen: boolean, 
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    spas: any[],
}

const RequestLogsModal: React.FC<ModalProps> = ({isOpen, setOpen, spas}) => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const {data:logs, loading:isLoading, error} = useSelector((state: RootState) => state.logsRequest);
    
    const handleClose = () => {
        setOpen(false);
    };

      useEffect(() => {
        if (isOpen) {
          const fetchData = async (spa:any) => {
            await dispatch(
              requestLogs({guid:spa?.id})
            );
          }
          if(spas!==undefined && spas.length>0){
            spas.forEach(async (spa:any) => {
              if(spa.status === 'connected'){
                fetchData(spa);
              }
            });
          }
        
        }
      }, [dispatch, spas, isOpen]);


  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <div className={styles.modal}>
        { isLoading &&
            <>
            <div className={styles.header}>
                <p>{`Requesting Logs...`}</p>
            </div>
    
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
              </Box>
            </>
          }

            {logs &&  !isLoading ?   
              <>
                  <div className={styles.header}>
                      <p>{`Request Sent`}</p>
                  </div>
                  <div className={styles.body}>
                      <p>{logs.message ?? logs.status}</p>
                  </div>

                  <div className="modal-btn-group">
                      <button className="as-text" onClick={handleClose}> Close </button>
                      <button className="as-button" onClick={()=>navigate(`/logs`)}> Logs</button>
                  </div>
    
              </>
            :
            error &&  !isLoading ?     
              <>
                  <div className={styles.header}>
                      <p>{`An Error Occurred`}</p>
                  </div>
                  <div className={styles.body}>
                      <p>{error}</p>
                  </div>

                  <div className="modal-btn-group">
                      <p></p>
                      <button className="as-text" onClick={handleClose}> OK </button>
                  </div>
    
              </>
              :
              <></>
            }               

        </div>
      </Modal>
    </div>
  );
};

export default RequestLogsModal;
