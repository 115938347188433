import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { refreshAccessToken, clearUserState } from '../actions/user.actions';
import RootState from '../states/root.state';

let refreshTimeout: NodeJS.Timeout | null = null;

export const setupTokenRefresh = (
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  expirationTime: number
) => {
  if (refreshTimeout) {
    clearTimeout(refreshTimeout);
  }

  const currentTime = Date.now();
  const timeUntilExpiry = expirationTime - currentTime;
  const refreshTime = timeUntilExpiry > 0 ? timeUntilExpiry - 60000 : 0; // Refresh 1 minute before expiry

  if (refreshTime > 0) {
    refreshTimeout = setTimeout(() => {
      dispatch(refreshAccessToken());
    }, refreshTime);
  } else {
    dispatch(clearUserState());
  }
};

export const clearTokenRefresh = () => {
  if (refreshTimeout) {
    clearTimeout(refreshTimeout);
  }
};