export enum SpaErrorLabels {
    ERR0="ERR0",
    ERR1="ERR1",
    ERR2="ERR2",
    ERR3="ERR3",
    ERR4="ERR4",
    ERR5="ERR5",
    ERR6="ERR6",
    ERR7="ERR7",
    ERR8="ERR8",
    ERR9="ERR9",
    ERR10="ERR10",
    ERR11="ERR11",
    ERR12="ERR12",
    ERR13="ERR13",
    ERR14="ERR14",
    ERR15="ERR15",
    ERR16="ERR16",
    ERR17="ERR17",
    ERR18="ERR18",
    ERR19="ERR19",
    ERR20="ERR20",
    ERR21="ERR21",
    ERR22="ERR22",
    ERR23="ERR23",
    ERR24="ERR24",
    ERR25="ERR25",
    ERR26="ERR26",
    ERR27="ERR27",
    ERR28="ERR28",
    ERR29="ERR29",
    ERR30="ERR30",
    ERR31="ERR31",
    ERR32="ERR32",
    ERR33="ERR33",
    ERR34="ERR34",
    ERR35="ERR35",
    ERR36="ERR36",
    ERR37="ERR37",
    ERR38="ERR38",
    ERR39="ERR39",
    ERR40="ERR40",
    ERR41="ERR41",
    ERR42="ERR42",
    ERR43="ERR43",
    ERR44="ERR44",
    ERR45="ERR45",
    ERR46="ERR46",
    ERR47="ERR47",
    ERR48="ERR48",
    ERR49="ERR49",
    ERR50="ERR50",
    ERR51="ERR51",
    ERR52="ERR52",
    ERR53="ERR53",
    ERR54="ERR54",
    ERR55="ERR55",
    ERR56="ERR56",
    ERR57="ERR57",
    ERR58="ERR58",
    ERR59="ERR59",
    ERR60="ERR60",
    ERR61="ERR61",
    ERR62="ERR62",
    ERR63="ERR63",
}