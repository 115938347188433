import {createAsyncThunk} from '@reduxjs/toolkit';
import {getLogs, downloadLogFile} from '../../services/api/logs.api';
import RootState from "../states/root.state";

export const fetchLogs = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
  'logs/list',
  async (params, {rejectWithValue, getState}) => {

      const {user} = getState();
      const token = user.accessToken;

    return await getLogs(token, params);
  }
);

export const getLogsFile = createAsyncThunk<any, any, { rejectValue: string; state: RootState}>(
    'logs/get-file',
    async (params, {rejectWithValue, getState}) => {

        const {user} = getState();
        const token = user.accessToken;

        return await downloadLogFile(token, params.log_request_id);
    }
);