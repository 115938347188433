import React from "react";
import logo from "../../assets/logos/logo.png";
import loginStyles from "../../assets/scss/Login.module.scss"
import ResetPasswordForm from "./components/ResetPasswordForm";

const ResetPasswordPage: React.FC = () => {
    return (
        <>
            <div className={loginStyles.homeScreen}>
                <img
                    src={logo}
                    alt="Logo"
                    style={{ height: "7rem", margin: "0 0 4rem 0" }}
                />

                <ResetPasswordForm />
            </div>
        </>
    );
};

export default ResetPasswordPage;
