// src/redux/reducers/index.ts

import { combineReducers } from '@reduxjs/toolkit';
import spasReducer from './spas.reducer';
import spaReducer from './spa.reducer';
import userReducer from './user.reducer';
import errorLogReducer from './errorLog.reducer';
import firmwareReducer from './firmware.reducer';
// Import other reducers as needed
import liveReducer from './live.reducer';
import settingsReducer from './settings.reducer';
import logsReducer from './logs.reducer';
import usersReducer from './users.reducer';
import logsRequestReducer from './logsRequest.reducer';
import usersByIdReducer from './usersById.reducer';
import dealershipReducer from './dealership.reducer';
import dealershipsReducer from './dealerships.reducer';
import firmwareUpgradeReducer from './firmwareUpgrade.reducer';
import firmwareUploadReducer from './firmwareUpload.reducer';
import constReducer from './const.reducer';
import errorReducer from './error.reducer';
import statusReducer from './status.reducer';
import upgradeRequestsReducer from './upgradeRequests.reducer';
import liveHistoryReducer from './liveHistory.reducer';
import updateStatusReducer from './updateStatus.reducer';
import settingsHistoryReducer from './settingsHistory.reducer';

const rootReducer = combineReducers({
  spas: spasReducer,
  spa: spaReducer,
  user: userReducer,
  errorLog: errorLogReducer,
  live: liveReducer,
  settings: settingsReducer,
  const: constReducer,
  error: errorReducer,
  status: statusReducer,
  updateStatus: updateStatusReducer,
  logsRequest: logsRequestReducer,
  users: usersReducer,
  logs: logsReducer,
  upgradeRequests: upgradeRequestsReducer,
  usersById: usersByIdReducer,
  dealership: dealershipReducer,
  dealerships: dealershipsReducer,
  firmware: firmwareReducer,
  firmwareUpgrade: firmwareUpgradeReducer,
  firmwareUpload: firmwareUploadReducer,
  liveHistory: liveHistoryReducer,
  settingsHistory: settingsHistoryReducer,
});

export default rootReducer;