import styles from "../../../assets/scss/Modal.module.scss";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, TextField, Button, FormControl, CircularProgress,Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { AppDispatch, RootState } from '../../../redux/store'; 
import { fetchAvailableFirmware, updateFirmware } from "../../../redux/actions/firmware.actions";

interface ModalProps {
    isOpen: boolean, 
    setOpen: any,
    params: string,
    firmware: any,
    setFirmwareToModify: any,
}

const UpdateFirmwareInfoModal: React.FC<ModalProps> = ({isOpen, setOpen,params,firmware, setFirmwareToModify}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { accessToken } = useSelector((state: RootState) => state.user);
    const [inProgress, setInProgress] = useState(false);

    const [description, setDescription] = useState('');
    const [isLatest, setIsLatest] = useState(false);

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
      
    useEffect(() => {
      if (firmware){
        setIsLatest(firmware.is_latest);
        setDescription(firmware.description);
      }
    }, [firmware]);
    
    const handleSubmit = async (event: React.FormEvent) => {
      setInProgress(true);
      const res = await dispatch(
        updateFirmware({firmware_id:firmware.id, is_latest:isLatest, description:description})
      );
      switch (res.type){
        case "firmware/update/fulfilled":
            setSuccess(true);
            setMessage('Firmware information successfully updated.')
            await dispatch(fetchAvailableFirmware(params));
            break;

        case "firmware/update/rejected":
            setError(true);
            console.log(res); // Print error to console
            setMessage('An error occurred while updating. Please try again later.')
            break;
    }
      setInProgress(false);
    };

    const handleClose = () => {
      setIsLatest(false);
      setInProgress(false);
      setDescription('');
      setOpen(false);
      setError(false);
      setSuccess(false);
      setMessage('');
      setFirmwareToModify(null);
    };

if ((!success && !error) || inProgress ) {
  return (
    <div>
      <Modal
        open={isOpen}
      >
        <div className={styles.modal}>
          <div className={styles.header}>
              Update Firmware
          </div>

            <div className={styles.body}>
              <span className="update-form">
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="description"
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    multiline
                    rows={4}
                    required
                    disabled={inProgress}
                  />
                </FormControl>

                <FormGroup>
                  <FormControlLabel 
                  label="Latest"
                  control={
                    <Checkbox
                      checked={isLatest}
                      onChange={(e) => setIsLatest(e.target.checked)}
                      disabled={inProgress}
                    />
                  } />
                </FormGroup>
              </span>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={inProgress || !accessToken || description===""}
                sx={{ mt: 2 }}
                onClick={handleSubmit}
              >
                {inProgress ? (
                  <CircularProgress size={"24px"} style={{ color: "var(--pure-white)" }} />
                  ) : (
                  "Update Firmware Info"
                  )} 
              </Button>

            </div>

            <div className="modal-btn-group">
                <button className="as-text" onClick={handleClose}> Close </button>
            </div>
        </div>
      </Modal>
    </div>
  )
} else {
  return (
    <div>
      <Modal
        open={isOpen}
      >
        <div className={styles.modal}>
          <div className={styles.header}>
              {error? `An Error Occured`:`Success`}
          </div>

            <div className={styles.body}>
              {message}
            </div>

            <div className="modal-btn-group">
                <p></p>
                <button className="as-text" onClick={handleClose}> OK </button>
            </div>
        </div>
      </Modal>
    </div>
  )};
};

export default UpdateFirmwareInfoModal;
