import axios from "axios";
import { BASE_URL } from "./apiconfig";

export const getUpgradeRequests = async (token: string | null, params:string) => {
    try {
      const { data } = await axios.get(`${BASE_URL}/upgrade-requests?${params}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      console.log("Failed to pull upgrade requests. ", error);
      throw error;
    }
}