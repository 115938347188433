import styles from "../../../assets/scss/Modal.module.scss";
import React, {useEffect, useState} from "react";
import {Modal} from '@mui/material';
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
    CircularProgress,
    Box,
  } from "@mui/material";
import RootState from "../../../redux/states/root.state";
import { upgradeFirmware } from "../../../redux/actions/firmware.actions";
import { useNavigate } from "react-router-dom";

interface ModalProps {
    isOpen: boolean, 
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    spas: any[],
    firmwareType: string,
    version: string
}

const FirmwareUpdateModal: React.FC<ModalProps> = ({isOpen, setOpen, spas,firmwareType,version}) => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const {data:firmware, loading:isLoading, error} = useSelector((state: RootState) => state.firmwareUpgrade);
    const [results, setResults] = useState<any[]>([]);

    const handleClose = () => {
        setOpen(false);
        setResults([])
    };

    useEffect(() => {
      if (isOpen) {
        const fetchData = async (spa:any) => {
          await dispatch(
            upgradeFirmware({spa_id:spa?.id,platform:firmwareType,version:version})
          );
        }

        if(spas!==undefined && spas.length>0){
          let newArray : any[] = [];
          spas.forEach(async (spa:any) => {
            fetchData(spa);
            newArray.push({spa:spa, firmware:firmware, error:error})
            setResults(newArray)
          });
        }
      }
    }, [dispatch, spas, isOpen, firmwareType, version]);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          { isLoading &&
            <>
            <div className={styles.header}>
                <p>{`Requesting Upgrade...`}</p>
            </div>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
              </Box>
          </>
          }

            {!isLoading &&   
              <>
                  <div className={styles.header}>
                    <p>{`Upgrade Requests Sent`}</p>
                  </div>
                  <div className={styles.body}>
                      <p>{`For more information or to view update progress, click on a spa below to be taken to their details page.`}</p>
                        <div className="spa-select">
                          {results.map((res:any)=>(
                            <div key={res?.spa?.id} className="update-results">
                              <span className="clickable-icon" onClick={()=>navigate(`/spa/${res?.spa?.id}`)}>
                                {res?.spa?.nick_name ? 
                                <span className="name-guid">
                                  {`${res?.spa?.nick_name} (`}<div className="partial-guid">{res?.spa?.id}</div>{`)`}
                                </span>
                                :
                                <div className="partial-guid">{res?.spa?.id}</div>
                                } 
                              </span>
                              <span className={res?.error ? `error-result` : `success-result`}>{res?.error ? res?.error : res?.firmware?.status??res?.firmware?.message}</span>
                            </div>
                          ))}
                        </div>
                  </div>

                  <div className="modal-btn-group">
                      <p></p>
                      <button className="as-text" onClick={handleClose}> OK </button>
                  </div>
    
              </>
              }               

        </div>
      </Modal>
    </div>
  );
};

export default FirmwareUpdateModal;
