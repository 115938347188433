import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ConstState from '../states/const.state';

interface ConstData {
    [key: string]: any;
}

const initialState: ConstState = {
    data: {},
    loading: false,
    error: null,
};

const constSlice = createSlice({
    name: 'const',
    initialState,
    reducers: {
        setConstData: (state, action: PayloadAction<ConstData>) => {
            return {
                ...state,
                data: action.payload
            };
        },
    },
});

export const { setConstData } = constSlice.actions;
export default constSlice.reducer;
