import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import StatusState from '../states/status.state';

interface StatusData {
    [key: string]: any;
}

const initialState: StatusState = {
    data: {},
    loading: false,
    error: null,
};

const statusSlice = createSlice({
    name: 'status',
    initialState,
    reducers: {
        setStatusData: (state, action: PayloadAction<StatusData>) => {
            return {
                ...state,
                data: action.payload
            };
        },
    },
});

export const { setStatusData } = statusSlice.actions;
export default statusSlice.reducer;
