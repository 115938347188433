import React from "react";
import Header from "../../components/common/Header/Header";
import PageTitle from "../../components/common/PageTitle/PageTitle";

const AccessDenied = () => {
    return (
        <>
            <PageTitle title={"401 Access Denied | My Arctic Spas"}/>
            <Header/>
            <div className="primary-window" style={{marginTop:0}}>
                <br></br>
                <div 
                    className="sub-menu-title"
                    style={{
                        display:"flex", 
                        alignItems:"center", 
                        justifyContent:"center",
                        flexDirection: "column",
                        height:"85vh", 
                        width:'stretch',
                        fontSize:"2rem",
                        color:'var(--font-black)'
                    }}
                >
                            Access Denied.


                        <a href="/">Home Page</a>
                </div>
            </div>
        </>

    );
};

export default AccessDenied;
