import styles from "../../../assets/scss/Modal.module.scss";
import React, {useState} from "react";
import {Modal, CircularProgress} from '@mui/material';
import { AppDispatch } from "../../../redux/store";
import { useDispatch } from "react-redux";
import { deleteFirmware, fetchAvailableFirmware } from "../../../redux/actions/firmware.actions";

interface ModalProps {
    open: boolean, 
    setOpen: any,
    firmware: any;
    setFirmwareToModify: any;
    params?: string;
}

const DeleteFirmwareModal: React.FC<ModalProps> = (
  {
    open, 
    setOpen, 
    firmware, 
    setFirmwareToModify,
    params=""
  }) => {
  const dispatch: AppDispatch = useDispatch();
  const [inProgress, setInProgress] = useState(false);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');

  const handleClose = () => {
    setFirmwareToModify(null);
    setError(false)
    setSuccess(false); 
    setOpen(false);
  };

  const handleDelete = async () => {
    setInProgress(true);

    if(firmware){
      const res = await dispatch(
          deleteFirmware(firmware.id)
        );

      switch (res.type){
          case "firmware/delete/fulfilled":
              setSuccess(true);
              await dispatch(fetchAvailableFirmware(params));
              break;

          case "firmware/delete/rejected":
              setError(true);
              setMessage(res.payload);
              break;
      }
    }
    setInProgress(false);
  }

  return (
    <div>
    <Modal
      open={open}
      onClose={handleClose}
    >

      <div className={styles.modal}>
          <div className={styles.header}>
              {error?
                <p>An Error Occurred</p>
              :
              success ?
                <p>Success</p>

              : inProgress ?
                <p>Deleting...</p>
              :
                <p> Delete firmware <b>{`${firmware?.type} (${firmware?.version})`}</b>? </p>
              }
              
          </div>
          <div className={styles.body}>
              {error?
                <span>
                  <div>An error occured while deleting, please try again later.</div>
                  <br></br>
                  <div>{message}</div>
                </span>
              :
              success ?
                <div>Firmware successfully deleted.</div>
              :
              inProgress ?
                <CircularProgress size={"24px"} />
              :
                <p> This action cannot be undone. </p>
              }
          </div>
          {
              error || success ?
              <div className="modal-btn-group">
                  <div></div>
                  <button className="as-text" onClick={handleClose}> OK </button>                        
          </div>
          : !inProgress ?
          <div className="modal-btn-group">
                <button className={styles.delete} onClick={handleDelete}> Delete </button>
                <button className="as-text" onClick={handleClose}> Cancel </button>
          </div>
          :
          <></>
          }
      </div>
    </Modal>
  </div>
);
};
    
export default DeleteFirmwareModal;
