import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDealership, getDealerships } from '../../services/api/dealerships.api';
import RootState from "../states/root.state";

export const fetchDealerships = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
  'dealership/list',
  async (params, {rejectWithValue, getState}) => {

      const {user} = getState();
      const token = user.accessToken;

    const response = await getDealerships(token,params);
    return response;
  }
);

export const fetchDealership= createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
  'dealership',
  async (id, {rejectWithValue, getState}) => {

      const {user} = getState();
      const token = user.accessToken;

      const response = await getDealership(token, id);
      return response.data;
  }
);