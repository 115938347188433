import React, { useState } from "react";
import Header from "../../../components/common/Header/Header";
import styles from "../../../assets/scss/UserSettings.module.scss";
import {
    Select,
    MenuItem,
    SelectChangeEvent,
    Button,
    FormControl,
    InputLabel,
    CircularProgress
} from '@mui/material';
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";

const UnitPreference = () => {
    const dispatch: AppDispatch = useDispatch();
    const isLoading = useSelector((state: RootState) => state.user.loading);
    const [tempPref, setTempPref] = useState('F');

    const handleChange = (e:SelectChangeEvent<string>) => {
        setTempPref(e.target.value);
    }

    const handleSubmit = () => {
        console.log(`Set preferred units to ${tempPref==='F'?`Fahrenheit (°F)`:`Celsius (°C)`}`)
    }

    return (
        <>
            <Header withLogo={false} withNavBtn={false} withUserAvatar={false} header="Units"/>
            <div className="page-wrapper top">
                <div className={styles.inputsContainer}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Temperature</InputLabel>
                        <Select
                            value={tempPref}
                            label="Temperature"
                            onChange={handleChange}
                            sx={{width:'stretch'}}
                            className={styles.preferenceSelect}
                            >
                            <MenuItem value={'F'}>{`Fahrenheit (°F)`}</MenuItem>
                            <MenuItem value={'C'}>{`Celsius (°C)`}</MenuItem>
                        </Select>
                    </FormControl>
 
                    <Button 
                        sx={{background:'var(--as-red)', "&:hover": {background:'var(--hover-red)'}}} 
                        variant="contained" 
                        disableElevation 
                        disabled={isLoading}
                        onClick={handleSubmit}>
                        {isLoading ? (
                            <CircularProgress size={"24px"} style={{ color: "var(--font-white)" }} />
                            ) : (
                            "Save Preferences"
                            )} 
                    </Button>
                </div>
            </div>
        </>
    );
};

export default UnitPreference;
