import React from "react";

// This is a placeholder for future, API will be required from demo provider

const ArcticSpasAR: React.FC = () => {
  return (
    <div style={{ margin: "-9rem 0", position: "relative" }}>
      <iframe
        title="Arctic Spas AR"
        src="https://demo.visao.ca/viewer/MulrvpHbSsuQnJKsRWib6A?lang=en&cache=643b40ccb3e20a3531b276e0f5858565&api=0.0.1-beta.17"
        width="100%"
        height="500rem"
        frameBorder="0"
      />
    </div>
  );
};

export default ArcticSpasAR;
