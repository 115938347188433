import React, { useEffect, useState } from "react";
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import Header from "../../../components/common/Header/Header";
import styles from "../../../assets/scss/UserSettings.module.scss";
import {TextField, Button} from '@mui/material';
import { fetchUserData } from "../../../redux/actions/user.actions";
import { editProfile } from "../../../redux/actions/user.actions";
import ConfirmationModal from "../../../components/common/Modals/ConfirmationModal";
import CircularProgress from "@mui/material/CircularProgress";

const EditProfile = () => {
    const dispatch: AppDispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user.data);
    const [isOpen, setOpen] = useState(false);
    const [modalContent, setContent] = useState("");
    const isLoading = useSelector((state: RootState) => state.user.loading);

    /* Handle user input */
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [verifyEmail, setVerifyEmail] = useState("");

    /* Track errors and display as helper text */
    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [verifyError, setVerifyError] = useState("");

    /* Get current user data */
    useEffect(() => {
        dispatch(fetchUserData());
    }, [dispatch]);

    /* Default value as user's current information */
    useEffect(() => {
        setName(user?.name);
        setEmail(user?.email); 
    }, [user]);

    /* Input Validation on Submit. */
    const handleSubmit = async () => {
        const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        const blank_error = 'Field cannot be left blank.'

        /* No field can be left blank. Email must be a valid form. Verify email must match email. */
        let is_error = (name==="")
                        || (email==="") 
                        || (!pattern.test(email)) 
                        || (verifyEmail==="" && email !== user?.email) /* Can be left blank if user didn't change email */
                        || (verifyEmail!==email && verifyEmail!=="" && email !== user?.email); /* Not blank and email has changed */

        /* Handle Input Errors */
        if (is_error){
            if (name===""){
                setNameError(blank_error);
            }

            if (email !== user?.email) { /* User changed email field*/
                if (email===""){ /* Can't be left blank */
                    setEmailError(blank_error);
    
                } else if (!pattern.test(email)) { /* Must be a valid email */
                    setEmailError('Invalid email address.');
    
                } else if (verifyEmail!==email){ /* Emails must match */
                    setVerifyError('E-mails do not match.');
                }
     
                if (verifyEmail===""){ /* Can't be left blank if email changed */
                    setVerifyError(blank_error);
                } 
            }

        } else {
            resetErrors();
            const result = await dispatch(
                editProfile({
                  ...user,
                  name: name,
                })
              );
              
            switch (result.type){
                case "user/update/fulfilled":
                    setOpen(true);
                    setContent('Profile Successfully Updated');
                    break;
    
                case "user/update/rejected":
                    setOpen(true);
                    setContent('An error occured. Please try again later.');
                    break;
            }
        }           
    }

    const resetErrors = () => {
        setNameError("");
        setEmailError("");
        setVerifyError("");
    }

    return (
        <>
            <Header withLogo={false} withNavBtn={false} withUserAvatar={false} header="Edit Profile"/>

            <div className="page-wrapper top">
                <ConfirmationModal isOpen={isOpen} setOpen={setOpen} content={modalContent}/>
                <div className={styles.inputsContainer}>
                    <div className={styles.inputSection}>
                        <TextField 
                            id="username" 
                            className={styles.textfield} 
                            label="Username" 
                            variant="filled" 
                            value={user?.username || ""} 
                            disabled
                            sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "var(--disabled-text)",
                              },
                            }}
                        />
                    </div>

                    <div className={styles.inputSection}>
                        <TextField 
                            id="name" 
                            className={styles.textfield} 
                            label="Name" 
                            value={name || ""} 
                            onChange={(e)=>setName(e.target.value)}
                            error={nameError!==""}
                            helperText={nameError}
                            required
                        />
                    </div>

                    <div className={styles.inputSection}>
                        <TextField 
                            id="email" 
                            className={styles.textfield} 
                            label="E-mail" 
                            value={email || ""} 
                            onChange={(e)=>setEmail(e.target.value)}
                            error={emailError!==""}
                            helperText={emailError}
                            required
                        />
                    </div>

                    <div className={styles.inputSection}>
                        <TextField 
                            id="verify-email" 
                            className={styles.textfield} 
                            label="Verify E-mail" 
                            value={verifyEmail || ""} 
                            onChange={(e)=>setVerifyEmail(e.target.value)}
                            error={verifyError!==""}
                            helperText={verifyError}
                            required
                        />
                    </div>

                    <Button 
                        className="button"
                        variant="contained" 
                        disableElevation 
                        disabled={isLoading}
                        onClick={handleSubmit}>
                        {isLoading ? (
                            <CircularProgress size={"24px"} style={{ color: "var(--font-white)" }} />
                            ) : (
                            "Update Profile"
                            )} 
                    </Button>
                </div>

            </div>
        </>

    );
};

export default EditProfile;
