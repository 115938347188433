export enum SpaSettingsLabels {
    RFIDConnected = "RFIDConnected",
    SBConnected = "SBConnected",
    LPCFWVer = "LPCFWVer",
    SBFWVer = "SBFWVer",
    SPASN = "SPASN",
    TAG1 = "TAG1",
    TAG2 = "TAG2",
    setTSP = "setTSP",
    setFF = "setFF",
    setFD = "setFD",
    setOnHr = "setOnHr",
    setOnCy = "setOnCy",
    setOzHr = "setOzHr",
    setOzCy = "setOzCy",
    setToff = "setToff",
    FS = "FS",
    sendflags = "sendflags",
    cfgP1 = "cfgP1",
    cfgP2 = "cfgP2",
    cfgP3 = "cfgP3",
    cfgP4 = "cfgP4",
    cfgP5 = "cfgP5",
    cfgB1 = "cfgB1",
    cfgB2 = "cfgB2",
    cfgLi = "cfgLi",
    cfgSt = "cfgSt",
    cfgH1 = "cfgH1",
    cfgH2 = "cfgH2",
    cfgRFID = "cfgRFID",
    cfgOn = "cfgOn",
    cfgOzP1 = "cfgOzP1",
    cfgOzP2 = "cfgOzP2",
    cfgEx = "cfgEx",
    cfgPL = "cfgPL",
    cfgBS = "cfgBS",
    cfgSB = "cfgSB",
    cfgFG = "cfgFG",
    cfgSDS = "cfgSDS",
    cfgYESS = "cfgYESS",
    cfgCTMode = "cfgCTMode",
    cfgpHoff = "cfgpHoff",
    cfgORPoff = "cfgORPoff",
    PeakNum = "PeakNum",
    PeakStart1 = "PeakStart1",
    PeakEnd1 = "PeakEnd1",
    PeakStart2 = "PeakStart2",
    PeakEnd2 = "PeakEnd2",
    MidPeakNum = "MidPeakNum",
    MidPeakStart1 = "MidPeakStart1",
    MidPeakEnd1 = "MidPeakEnd1",
    MidPeakStart2 = "MidPeakStart2",
    MidPeakEnd2 = "MidPeakEnd2",
    OffPeakStart = "OffPeakStart",
    OffPeakEnd = "OffPeakEnd",
    PeakHeater = "PeakHeater",
    PeakFilter = "PeakFilter",
    PeakOzone = "PeakOzone",
    MidPeakHeater = "MidPeakHeater",
    MidPeakFilter = "MidPeakFilter",
    MidPeakOzone = "MidPeakOzone",
    Sat = "Sat",
    Sun = "Sun",
    Mon = "Mon",
    Tue = "Tue",
    Wed = "Wed",
    Thu = "Thu",
    Fri = "Fri",
    sbpHind = "sbpHind",
    sbORPind = "sbORPind",
    SBORPhi = "SBORPhi",
    SBORPlo = "SBORPlo",
    /*
    * these 2 values are used to set the actual required value of the chlorine:
    *     Ex: I want the value to be 550: set SBORPhi: 555 and SBORPlo: 545.
    *     Ex: I want the value to be 650: set SBORPhi: 655 and SBORPlo: 645.
    *     Ex: I want the value to be 750: set SBORPhi: 755 and SBORPlo: 745.
    *
    * */
    SBpHhi = "SBpHhi",
    SBpHlo = "SBpHlo"
}