import styles from "../../../assets/scss/Modal.module.scss";
import React, {useState, useEffect} from "react";
import {CircularProgress, Modal} from '@mui/material';
import { AppDispatch } from "../../../redux/store";
import { useDispatch } from "react-redux";
import { deregisterSpaUser, fetchSpa, registerSpaUser } from "../../../redux/actions/spa.actions";
import UserSelect from "../../../components/common/UserSelect/UserSelect";

interface ModalProps {
    isOpen: boolean, 
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    user: any;
    spa:any;
}

const RegisterUserModal: React.FC<ModalProps> = ({isOpen, setOpen, user, spa}) => {
  const dispatch: AppDispatch = useDispatch();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (isOpen){
        setError(false)
        setSuccess(false); 
        setSelectedUser(false);
    }
  }, [isOpen,setError,setSuccess,setSelectedUser]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeregister = async () => {
    setInProgress(true);
    if(user && spa){
      const result = await dispatch(
          deregisterSpaUser({guid: spa.id, user_id:user.id})
        );

      switch (result.type){
          case "spa/deregister/fulfilled":
              setSuccess(true);
              await dispatch(fetchSpa(spa.id));
              break;

          case "spa/deregister/rejected":
              setError(true);
              break;
      }
    }
    setInProgress(false);
  }
  
  const handleRegister = async () => {
    setInProgress(true);
    if(spa && selectedUser){
      const result = await dispatch(
        registerSpaUser({guid: spa.id, user_id:selectedUser.id})
      );

    switch (result.type){
        case "spa/register-to-user/fulfilled":
            setSuccess(true);
            await dispatch(fetchSpa(spa.id));
            break;

        case "spa/register-to-user/rejected":
            setError(true);
            break;
    }
    }
    setInProgress(false);
  }

  if (user) {
    return (
      <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <div className={styles.modal}>
            <div className={styles.header}>
                {
                inProgress ?
                  <p>Deregistering...</p>
                :
                error?
                  <p>Error</p>
                :
                success ?
                  <p>Success</p>
                :
                  <p> Deregister spa from <b>{user?.name}</b>? </p>
                }
                
            </div>
            <div className={styles.body}>
                {
                inProgress ?
                  <CircularProgress size={"24px"} />
                : error?
                  <div>An error occured during deregistration, please try again later.</div>
                :
                success ?
                  <div>Spa successfully registered to user.</div>
                :
                  <p> This action will unlink the spa from the user. </p>
                }
            </div>
            {
              error || success ?
              <div className="modal-btn-group">
                <div></div>
                <button className="as-text" onClick={handleClose}> OK </button>                        
              </div>
            : !inProgress ?
            <div className="modal-btn-group">
                  <button className={styles.delete} onClick={handleDeregister}> Deregister </button>
                  <button className="as-text" onClick={handleClose}> Cancel </button>
            </div>
            :
            <></>
            }
        </div>
      </Modal>
    </div>
    );
  }

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
  
        <div className={styles.modal}>
            <div className={styles.header}>
                {
                inProgress ?
                  <p>Registering...</p>

                : error?
                  <p>Error</p>
                :
                success ?
                  <p>Success</p>

                :
                  <p> Select User </p>
                }
                
            </div>
            <div className={styles.body}>
                {
                inProgress ?
                  <CircularProgress size={"24px"} />
                :
                error?
                  <div>An error occured during registration, please try again later.</div>
                :
                success ?
                  <div>Spa successfully deregistered.</div>
                :
                  <div>
                    <div>This action will link the spa to the user.</div>
                    <UserSelect setSelectedUser={setSelectedUser} currentUser={spa?.user}/>
                  </div>
                }
            </div>
            {
                error || success ?
                <div className="modal-btn-group">
                    <div></div>
                    <button className="as-text" onClick={handleClose}> OK </button>                        
            </div>
            : !inProgress ?
            <div className="modal-btn-group">
                  <button className="as-text" onClick={handleClose}> Cancel </button>
                  <button className="as-button" disabled={!selectedUser} onClick={handleRegister}> Register </button>
            </div>
            :
            <></>
            }
        </div>
      </Modal>
    </div>
  );
};

export default RegisterUserModal;
