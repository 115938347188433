import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUsers, getUserById } from '../../services/api/users.api';
import RootState from "../states/root.state";

export const fetchUsers = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
  'user/list',
  async (params, {rejectWithValue, getState}) => {

      const {user} = getState();
      const token = user.accessToken;

    const response = await getUsers(token,params);
    return response;
  }
);

export const fetchUserById= createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
  'user',
  async (id, {rejectWithValue, getState}) => {

      const {user} = getState();
      const token = user.accessToken;

      const response = await getUserById(token, id);
      return response.data;
  }
);