import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ErrorState from '../states/error.state';

interface ErrorData {
    [key: string]: any;
}

const initialState: ErrorState = {
    data: {},
    loading: false,
    error: null,
};

const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        setErrorData: (state, action: PayloadAction<ErrorData>) => {
            return {
                ...state,
                data: action.payload
            };
        },
    },
});

export const { setErrorData } = errorSlice.actions;
export default errorSlice.reducer;
