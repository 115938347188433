import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {useDispatch} from "react-redux";
import {AppDispatch} from "./redux/store";
import {fetchUserData} from "./redux/actions/user.actions";
import LoginPage from './pages/Auth/LoginPage';
import RegistrationPage from './pages/Auth/RegistrationPage';
import ForgotPasswordPage from './pages/Auth/ForgotPasswordPage';
import ResetPasswordPage from './pages/Auth/ResetPasswordPage';
import ProtectedRoute from './components/Layout/ProtectedRoute';
import HomePage from './pages/Home/HomePage';
import SpasPage from './pages/Spas/SpasPage';
import SpaDetailsPage from './pages/SpaDetails/SpaDetailsPage';
import SpaControlPage from './pages/SpaControl/SpaControlPage';
import ContactUs from './pages/SpaControl/ContactUs/ContactUs';
import Filtration from './pages/SpaControl/Filtration/Filtration';
import Schedule from './pages/SpaControl/Schedule/Schedule';
import SpaBoy from './pages/SpaControl/SpaBoy/SpaBoy';
import Troubleshoot from './pages/SpaControl/Troubleshoot/Troubleshoot';
import Manuals from './pages/SpaControl/ManualsWarranty/Manuals';
import UsersPage from './pages/Users/UsersPage';
import UserDetailsPage from './pages/UserDetails/UserDetailsPage';
import DealershipsPage from './pages/Dealerships/DealershipsPage';
import SettingsPage from './pages/Settings/SettingsPage';
import ChangePassword from './pages/Settings/ChangePassword/ChangePassword';
import EditProfile from './pages/Settings/EditProfile/EditProfile';
import Technician from './pages/Settings/Technician/Technician';
import UnitPreference from './pages/Settings/UnitPreference/UnitPreference';
import MySpas from './pages/MySpas/MySpas';
import SpaErrorsPage from './pages/SpaErrors/SpaErrorsPage';
import DealershipDetailsPage from './pages/DealershipDetails/DealershipDetailsPage';
import LogsPage from './pages/Logs/LogsPage';
import ManageFirmwaresPage from './pages/ManageFirmwares/ManageFirmwaresPage';
import NotFound from './pages/General/NotFound';
import DealerRoute from './components/Layout/DealerRoute';
import SpaReport from './pages/SpaReport/SpaReport';
import UpgradeRequestsPage from './pages/UpgradeRequests/UpgradeRequestsPage';
import PageTitle from './components/common/PageTitle/PageTitle';

const AppRoutes: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const base_title ="My Arctic Spas";

  useEffect(() => {
      dispatch(fetchUserData());
  }, [dispatch]);

  return (
    <Router>
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<><PageTitle title={"Login | "+base_title}/><LoginPage/></>}/>

            {/* Only guest route */}
            <Route path="/login" element={<><PageTitle title={"Login | "+base_title}/> <LoginPage/></>}/>
            <Route path="/register" element={<><PageTitle title={"Register | "+base_title}/><RegistrationPage/></>}/>
            <Route path="/forgot-password" element={<><PageTitle title={"Forgot Password | "+base_title}/><ForgotPasswordPage/></>}/>
            <Route path="/reset-password" element={<><PageTitle title={"Reset Password | "+base_title}/><ResetPasswordPage /></>}/>

            {/*  Customer Routes */}
                <Route
                    path="/my-spas"
                    element={
                        <ProtectedRoute>
                            <PageTitle title={"My Spas | "+base_title}/>
                            <MySpas/>
                        </ProtectedRoute>
                    }
                />

            {/* Spa Controls Routes */}
            <Route
                    path="/spa-control/:id"
                    element={
                        <ProtectedRoute>
                            <PageTitle title={"Spa Control | "+base_title}/>
                            <SpaControlPage/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/spa-control/:id/spaboy"
                    element={
                        <ProtectedRoute>
                            <PageTitle title={"SpaBoy | "+base_title}/>
                            <SpaBoy/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/spa-control/:id/filtration"
                    element={
                        <ProtectedRoute>
                            <PageTitle title={"Filtration | "+base_title}/>
                            <Filtration/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/spa-control/:id/schedule"
                    element={
                        <ProtectedRoute>
                            <PageTitle title={"Schedule | "+base_title}/>
                            <Schedule/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/spa-control/:id/troubleshoot"
                    element={
                        <ProtectedRoute>
                            <PageTitle title={"Troubleshoot | "+base_title}/>
                            <Troubleshoot/>
                        </ProtectedRoute>
                    }
                />
                
                <Route
                    path="/spa-control/manuals-warranty"
                    element={
                        <ProtectedRoute>
                            <PageTitle title={"Manuals&Warranty | "+base_title}/>
                            <Manuals/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/spa-control/contact-us"
                    element={
                        <ProtectedRoute>
                            <PageTitle title={"Contact Us | "+base_title}/>
                            <ContactUs/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/settings"
                    element={
                        <ProtectedRoute>
                            <PageTitle title={"Settings | "+base_title}/>
                            <SettingsPage/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/settings/edit-profile"
                    element={
                        <ProtectedRoute>
                            <PageTitle title={"Edit Profile | "+base_title}/>
                            <EditProfile/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/settings/change-password"
                    element={
                        <ProtectedRoute>
                            <PageTitle title={"Change Password | "+base_title}/>
                            <ChangePassword/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/settings/unit-preference"
                    element={
                        <ProtectedRoute>
                            <PageTitle title={"Preferences | "+base_title}/>
                            <UnitPreference/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/settings/technician"
                    element={
                        <ProtectedRoute>
                            <PageTitle title={"Technician | "+base_title}/>
                            <Technician/>
                        </ProtectedRoute>
                    }
                />

            {/* Admins Only Routes */}
                <Route
                    path="/home"
                    element={
                        <DealerRoute>
                            <PageTitle title={"Home | "+base_title}/>
                            <HomePage/>
                        </DealerRoute>
                    }
                />
                <Route
                    path="/spas"
                    element={
                        <DealerRoute>
                            <PageTitle title={"Spas | "+base_title}/>
                            <SpasPage/>
                        </DealerRoute>
                    }
                />

                <Route
                    path="/spa/:id"
                    element={
                        <DealerRoute>
                            <PageTitle title={"Spa Details | "+base_title}/>
                            <SpaDetailsPage/>
                        </DealerRoute>
                    }
                />

                <Route
                    path="/spa/:id/errors"
                    element={
                        <DealerRoute>
                            <PageTitle title={"Errors | "+base_title}/>
                            <SpaErrorsPage/>
                        </DealerRoute>
                    }
                />

                <Route
                    path="/spa/:id/report"
                    element={
                        <DealerRoute>
                            <PageTitle title={"Report | "+base_title}/>
                            <SpaReport/>
                        </DealerRoute>
                    }
                />

                <Route
                    path="/firmware"
                    element={
                        <DealerRoute>
                            <PageTitle title={"Firmware | "+base_title}/>
                            <ManageFirmwaresPage/>
                        </DealerRoute>
                    }
                />

                <Route
                    path="/logs"
                    element={
                        <DealerRoute>
                            <PageTitle title={"Logs | "+base_title}/>
                            <LogsPage/>
                        </DealerRoute>
                    }
                />

                <Route
                    path="/upgrade-requests"
                    element={
                        <DealerRoute>
                            <PageTitle title={"Upgrade History | "+base_title}/>
                            <UpgradeRequestsPage/>
                        </DealerRoute>
                    }
                />

                <Route
                    path="/users"
                    element={
                        <DealerRoute>
                            <PageTitle title={"Users | "+base_title}/>
                            <UsersPage/>
                        </DealerRoute>
                    }
                />

                <Route
                    path="/user/:id"
                    element={
                        <DealerRoute>
                            <PageTitle title={"User Details | "+base_title}/>
                            <UserDetailsPage/>
                        </DealerRoute>
                    }
                />

                <Route
                    path="/dealerships"
                    element={
                        <DealerRoute>
                            <PageTitle title={"Dealerships | "+base_title}/>
                            <DealershipsPage/>
                        </DealerRoute>
                    }
                />

                <Route
                    path="/dealership/:id"
                    element={
                        <DealerRoute>
                            <PageTitle title={"Dealership Details | "+base_title}/>
                            <DealershipDetailsPage/>
                        </DealerRoute>
                    }
                />

            <Route 
                path='*' 
                element={
                    <>
                    <PageTitle title={"404 Not Found | "+base_title}/>
                    <NotFound/>
                    </>
                } 
            />
        </Routes>
    </Router>
  );
};

export default AppRoutes;