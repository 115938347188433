import React from "react";
import Header from "../../components/common/Header/Header";
import PageTitle from "../../components/common/PageTitle/PageTitle";

const NotFound = () => {
    return (
        <>
            <PageTitle title={"404 Not Found | My Arctic Spas"}/>
            <Header/>
            <div className="primary-window" style={{marginTop:0}}>
                <br></br>
                <div 
                    className="sub-menu-title"
                    style={{
                        display:"flex", 
                        alignItems:"center", 
                        justifyContent:"center", 
                        height:"85vh", 
                        width:'stretch',
                        fontSize:"2rem",
                        color:'var(--font-black)'
                    }}
                >
                        Oops! Page not found.
                </div>
            </div>
        </>

    );
};

export default NotFound;
