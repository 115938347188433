import { createAsyncThunk } from '@reduxjs/toolkit';
import { SpaError } from '../models/errorLog.model';
import { getSpaErrorLogs } from '../../services/api/errorLog.api';

export const fetchErrorLogs = createAsyncThunk<SpaError[], string>(
  'errorLog/fetch',
  async (spaId, { rejectWithValue }) => {
    try {
      const response = await getSpaErrorLogs(spaId);
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to fetch error logs");
    }
  }
);