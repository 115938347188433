import "./Header.css"
import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../redux/store";
import { logout } from "../../../redux/actions/user.actions";
import NavigatableDiv from "../NavigatorDiv/NavigatableDiv";
import { BackChevronIcon } from "../../../assets/svg/svg";
import Logo from "../../Layout/Logo";
import {Menu, MenuItem, IconButton} from '@mui/material';
import RootState from "../../../redux/states/root.state";
import MenuIcon from '@mui/icons-material/Menu';
import MySwitch from "../Switches/Switch";

const Header = ({ withBackButton = true, withNavBtn = true, withLogo = true,withUserAvatar = true, header=""}) => {
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.data);
  const canAccessAdminPanel = user?.roles?.some((role: any) =>["dealer", "admin", "owner", "corporate", "developer"].includes(role.name)) || false;
  const [menu, setMenu] = useState<null | HTMLElement>(null);
  const [nav, setNav] = useState<null | HTMLElement>(null);
  const [theme, setTheme] = useState<any>('light');
  const open = Boolean(menu);
  const navOpen = Boolean(nav);
  const navigate = useNavigate();
  var mode = localStorage.getItem('data-theme');
  
  
  useEffect(() => {
    if (mode) {
      setTheme(mode);
    } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setTheme('dark');
    }
  }, [mode]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenu(event.currentTarget);
  };

  const handleClose = () => {
    setMenu(null);
  };

  const handleCollapse = () => {
    setNav(null);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleChange = () => {
    if (theme==='dark'){
      setTheme('light');
      document.body.setAttribute('data-theme', 'light');
      localStorage.setItem('data-theme', 'light');
    } else {
      setTheme('dark');
      document.body.setAttribute('data-theme', 'dark');
      localStorage.setItem('data-theme', 'dark');
    }
    window.dispatchEvent( new Event('storage') );
  }

  return (
    <>
      <div className="header">
        <div className="right-group">
          <div className="back-button">
            {withBackButton && (
              <NavigatableDiv className="back-chevron-icon" destination={-1}>
                <BackChevronIcon />
              </NavigatableDiv>
            )}
          </div>

          {withNavBtn && canAccessAdminPanel && 
          <div className="button-group hide-in-mobile">
              <div onClick={()=>navigate(`/home`)}>Dash</div>
              <div onClick={()=>navigate(`/dealerships`)}>Dealerships</div>
              <div onClick={()=>navigate(`/users`)}>Users</div>
              <div onClick={()=>navigate(`/spas`)}>Spas</div>
              <div onClick={()=>navigate(`/firmware`)}>Firmware</div>
              <div onClick={()=>navigate(`/logs`)}>Logs</div>
              <div onClick={()=>navigate(`/upgrade-requests`)}>Upgrades</div>
          </div>}

          {withNavBtn && canAccessAdminPanel && 
          <div className="show-in-mobile">
            <IconButton aria-label="menu" sx={{color:`var(--as-gray)`}} size="large" onClick={(e)=>setNav(e.currentTarget)}>
              <MenuIcon/>
            </IconButton>
            <Menu
              id="nav-menu"
              anchorEl={nav}
              open={navOpen}
              onClose={handleCollapse}
              >
              <MenuItem onClick={()=>navigate(`/home`)}>Dash</MenuItem>
              <MenuItem onClick={()=>navigate(`/dealerships`)}>Dealerships</MenuItem>
              <MenuItem onClick={()=>navigate(`/users`)}>Users</MenuItem>
              <MenuItem onClick={()=>navigate(`/spas`)}>Spas</MenuItem>
              <MenuItem onClick={()=>navigate(`/firmware`)}>Firmware</MenuItem>
              <MenuItem onClick={()=>navigate(`/logs`)}>Logs</MenuItem>
              <MenuItem onClick={()=>navigate(`/upgrade-requests`)}>Upgrades</MenuItem>
            </Menu>
          </div>}
        </div>

        {withLogo && 
        <div className="logo">
          <Logo height="4rem"/>
        </div>
        }

        {header!=="" && 
        <div className="logo">
          <p>{header}</p>
        </div>
        }


        {withUserAvatar &&
        <div className="user-avatar">
          <button onClick={handleClick}>{user?.name[0] ?? `U`}</button>
          <Menu
            id="user-menu"
            anchorEl={menu}
            open={open}
            onClose={handleClose}
            >
            <MenuItem onClick={()=>navigate(`/`)}>Home</MenuItem>
            <MenuItem onClick={()=>navigate(`/settings`)}>Settings</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
            <MenuItem>
              Dark Mode
              <MySwitch checked={theme==='dark'} onChange={handleChange}/>
            </MenuItem>
          </Menu>
        </div>}
      </div>
    </>
  );
};

export default Header;
