import { createAsyncThunk } from '@reduxjs/toolkit';
import RootState from '../states/root.state';
import { deleteFirmwareApi, getAvailableFirmware, updateFirmwareApi, upgradeFirmwareApi, uploadFirmwareApi } from '../../services/api/firmware.api';


export interface requestFirmwareResponse {
    status: String,
    spa_id: String,
    updated_at: String,
    created_at: String,
    id: Number,
}

export const fetchAvailableFirmware = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
    'firmware/list',
    async (params, {rejectWithValue, getState}) => {
  
        const {user} = getState();
        const token = user.accessToken;
  
      return await getAvailableFirmware(token, params);
    }
  );
  
export const upgradeFirmware = createAsyncThunk<
    requestFirmwareResponse,
    {spa_id: string;platform: string;version: string},
    { rejectValue: string; state: RootState }
>(
    'firmware/upgrade',
    async (firmwareInfo, { getState, rejectWithValue, dispatch }) => {
        try {
            const { user } = getState();
            const token = user.accessToken;

            return await upgradeFirmwareApi(token, firmwareInfo);
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while updating firmware.');
            }
        }
    }
);

  export const uploadFirmware = createAsyncThunk<any,FormData,{ rejectValue: string; state: RootState }>(
      'firmware/upload',
      async (formData, {rejectWithValue, getState}) => {
  
          const {user} = getState();
          const token = user.accessToken;
  
          return await uploadFirmwareApi(token,formData);
      }
  );

  export const updateFirmware = createAsyncThunk<any,{firmware_id: string, is_latest: boolean, description: string},{ rejectValue: string; state: RootState }>(
    'firmware/update',
    async (firmwareInfo, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;
    
            return await updateFirmwareApi(token,firmwareInfo);
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while updating firmware information.');
            }
        }
    }
);

export const deleteFirmware = createAsyncThunk<any,string,{ rejectValue: string; state: RootState }>(
    'firmware/delete',
    async (firmware_id, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;

            return await deleteFirmwareApi(token,firmware_id);            
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while deleting firmware.');
            }
        }
    }
);