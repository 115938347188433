import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData, loginUser } from "../../../redux/actions/user.actions";
import RootState from "../../../redux/states/root.state";
import { AppDispatch } from "../../../redux/store";
import { Link, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  SxProps,
  Theme,
  Input,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import "./LoginForm.css";

const buttonStyles: SxProps<Theme> = (theme) => ({
  marginTop: "2rem",
  textTransform: "none",
  fontWeight: "600",
  backgroundColor: "var(--as-red)",
  "&:hover": {
    backgroundColor: "var(--as-red)",
  },
});

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const loginError = useSelector((state: RootState) => state.user.error);
  const accessToken = useSelector((state: RootState) => state.user.accessToken);

  useEffect(() => {
    if (accessToken) {
      const fetchData = async () => {
        const result = await dispatch(
          fetchUserData()
        );
        
        return result;
      }
    fetchData().then((r)=>{
        let user = r.payload as any;

        const canAccessAdminPanel = user?.roles?.some((role: any) =>
            ["dealer", "admin", "owner", "corporate", "developer"].includes(role.name)
        ) || false;

        canAccessAdminPanel ? navigate("/home") : navigate("/my-spas");
      });
    }
  }, [accessToken, navigate]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    const result = await dispatch(
      loginUser({ Email: email, Password: password })
    );
    if (loginUser.fulfilled.match(result)) {
      let user = result.payload.user as any;

      const canAccessAdminPanel = user?.roles?.some((role: any) =>
          ["dealer", "admin", "owner", "corporate", "developer"].includes(role.name)
      ) || false;

      canAccessAdminPanel ? navigate("/home") : navigate("/my-spas");
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <div className="login-card">
      <div className="login-card-content">
        <div className="login-header">Login</div>
        <Box
          component="form"
          onSubmit={handleLogin}
          noValidate
          className="tester"
        >
          <TextField
            variant="standard"
            // size="small"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email / Username"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              "& .MuiInputLabel-root": {
                fontSize: "small",
                color: "var(--as-gray)",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "var(--as-gray)",
              },
              "& input:-webkit-autofill": {
                WebkitTextFillColor: "inherit",
                // transition: "background-color 5000s ease-in-out 0s",
              },
            }}
          />

          <FormControl
            variant="standard"
            fullWidth
            required
            size="small"
            sx={{
              "& .MuiInputLabel-root": {
                fontSize: "small",
                color: "var(--as-gray)",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "var(--as-gray)",
              },
              "& input:-webkit-autofill": {
                WebkitTextFillColor: "inherit",
                // transition: "background-color 5000s ease-in-out 0s",
              },
            }}
          >
            <InputLabel htmlFor="standard-adornment-password">
              Password
            </InputLabel>
            <Input
              id="standard-adornment-password"
              type={showPassword ? "text" : "password"}
              name="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseUpPassword}
                  >
                    {showPassword ? (
                      <VisibilityOffOutlined />
                    ) : (
                      <VisibilityOutlined />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  value="remember"
                  color="primary"
                  checked={rememberMe}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setRememberMe(e.target.checked)
                  }
                  sx={{
                    "&.Mui-checked": {
                      color: "var(--as-red)", // Set the checked state color from CSS variable
                    },
                  }}
                />
              }
              label="Remember me"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "small", // Adjust the font size as needed
                },
              }}
            />

            <Link to={"/forgot-password"} className="login-help-link">
              Forgot password?
            </Link>
          </div>
          {loginError && (
            <Typography color="error" variant="body2" paragraph>
              {loginError}
            </Typography>
          )}
          <Button
            className="button"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={buttonStyles}
          >
            Login
          </Button>
          <Box mt={".5rem"} display="flex" justifyContent="center" gap="1rem">
            Don't have an account?
            <Link to={"/register"} className="login-help-link">
              {"Sign Up"}
            </Link>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default LoginForm;
