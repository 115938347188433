import "./LogsPage.css";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Header from "../../components/common/Header/Header";
import { AppDispatch } from "../../redux/store";
import { fetchLogs } from "../../redux/actions/logs.actions";
import LogsContainer from "../../components/common/Tables/TableContainers/LogsContainer";

const LogsPage: React.FC = () => {
  // Data
  const dispatch: AppDispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(fetchLogs(searchParams.toString()))
  }, [dispatch, searchParams]);

  return (
    <>
      <Header/>
      <LogsContainer
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
    </>
  );
};

export default LogsPage;
