import styles from "../../../assets/scss/SpaCard.module.scss";
import React from "react";
import { useNavigate } from "react-router-dom";
import RootState from "../../../redux/states/root.state";
import { useSelector } from "react-redux";

interface SpaCardProps { 
  spa: any; 
  isCardEdit: boolean;
  setOpenDelete: React.Dispatch<React.SetStateAction<boolean>>; 
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>; 
  setSpaToModify: React.Dispatch<any>;
}

const SpaCard: React.FC<SpaCardProps> = ({ spa, isCardEdit, setOpenDelete, setSpaToModify, setOpenEdit }) => {
    const navigate = useNavigate();

    const is_connected = spa.status === 'connected';

    const liveData = useSelector((state: RootState) => state.live.data);

    const handleClick = () => {
      if (!isCardEdit){
        spa.is_moved ? navigate(`/spa-control/${spa.id}`) : console.log('Spa not moved!');
      }
    }

    const handleEdit = () => {
      if (isCardEdit){
        setSpaToModify(spa);
        setOpenEdit(true);
      }
    }

    const handleDelete = (e:React.MouseEvent<SVGSVGElement>) => {
      e.stopPropagation();
      setSpaToModify(spa);
      setOpenDelete(true);
    }

  return (
    <div className={styles.cardContainer} onClick={handleClick}>
        <div className={styles.cardContent}>
            <div className={styles.upperContent}>
                <p className={isCardEdit ? styles.editName : ! is_connected? styles.offlineName:`` } onClick={handleEdit}>
                  {spa.nick_name}
                </p>
                
                {is_connected ? (
                    <span className="status-indicator online"></span>
                  ) : (
                    <span className="status-indicator offline"></span>
                  )}
                <div>
                  {
                    isCardEdit ? (
                      <svg className={styles.deleteButton} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleDelete}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                      </svg>
                      )
                      :
                      (<p className={styles.model}>{spa.model || `Summit Series XL`}</p>)
                  }
                </div>
            </div>
            <hr className={styles.divider}/>
            <div className={styles.lowerContent}>
                <p>                  
                    {
                        is_connected ?
                      liveData.H1 || liveData.H2 ? 'Heating...' : 'Idle'
                      : 'Offline'
                  }
                </p>
            </div>
        </div>
    </div>
  );
};

export default SpaCard;
