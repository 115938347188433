import styles from "../../../assets/scss/Modal.module.scss";
import React, {useEffect, useState} from "react";
import {Modal} from '@mui/material';
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
    CircularProgress,
    Box,
  } from "@mui/material";
import RootState from "../../../redux/states/root.state";
import { upgradeFirmware } from "../../../redux/actions/firmware.actions";
import useMQTT from "../../../hooks/useMQTT";
import { UpdateStatusLabels } from "../../../mqttData/updateStatus/updateStatusLabels.enums";
import { useNavigate } from "react-router-dom";
import { fetchSpa } from "../../../redux/actions/spa.actions";

interface ModalProps {
    isOpen: boolean, 
    setOpen: any,
    id: string,
    firmwareType: string,
    version: string
}

const SingleUpdateModal: React.FC<ModalProps> = ({isOpen, setOpen,id,firmwareType,version}) => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const {data:firmware,loading:isLoading, error} = useSelector((state: RootState) => state.firmwareUpgrade);
    const {data:spa} = useSelector((state: RootState) => state.spa);
    const {data:updateData} = useSelector((state: RootState) => state.updateStatus);
    const [now, setNow] = useState(new Date());

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
      if (isOpen) {
        setNow(new Date());
      }
    }, [isOpen]);

    useEffect(() => {
      if (id) {
        dispatch(fetchSpa(id));
      }
    }, [dispatch, id]);

    useEffect(() => {
      if (isOpen) {
        const sendUpdateData = async (id:string) => {
          await dispatch(
            upgradeFirmware({spa_id:id,platform:firmwareType,version:version})
          );
        }
        sendUpdateData(id);
      }
    }, [dispatch, id, isOpen, firmwareType, version]);
    

  useMQTT(id ?? "");
    
  return (
    <div>
      <Modal
        open={isOpen}
      >
        <div className={styles.modal}>
        { isLoading ?
        <>
          <div className={styles.header}>
              <p>{`Requesting Update...`}</p>
          </div>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        </>
        :
        error ?
        <>
          <div className={styles.header}>
            <p>{`An Error Occurred`}</p>
          </div>

          <div className={styles.body}>
            <p>{`For more information, click on the spa below to be taken to their details page.`}</p>
            <div className="update-results">
              <span className="clickable-icon" onClick={()=>navigate(`/spa/${id}`)}>
                {spa?.nick_name ? 
                  <span className="name-guid">
                    {`${spa?.nick_name} (`}<div className="partial-guid">{spa?.id}</div>{`)`}
                  </span>
                  :
                  <div className="partial-guid">{spa?.id}</div>
                  } 
              </span>
              <span className={`error-result`}>{error}</span>
            </div>
          </div>

          <div className="modal-btn-group">
            <p></p>
            <button className="as-text" onClick={handleClose}> OK </button>
          </div>
        </>
        : 
        (
          Object.keys(updateData).length>0 && 
          updateData[UpdateStatusLabels.spa_guid]===id && 
          updateData[UpdateStatusLabels.type]===firmwareType &&
          new Date(updateData[UpdateStatusLabels.timestamp]) >= now // most recent update status only.
        )
        ?
        <>
          {updateData[UpdateStatusLabels.progress]<100 &&
          <>
            <div className={styles.header}>
                <p>{`Updating Firmware...`}</p>
            </div>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress variant="determinate" value={updateData[UpdateStatusLabels.progress]}/>
            </Box>
          </>}

        {updateData[UpdateStatusLabels.progress]===100 &&
        <>
          <div className={styles.header}>
            <p>{`Update Finished.`}</p>
          </div>

          <div className={styles.body}>
            <p>
              {updateData[UpdateStatusLabels.success]?
                `Successfully updated on ${new Date(updateData[UpdateStatusLabels.timestamp]).toUTCString()}`
              :
                `Failed to updated on ${new Date(updateData[UpdateStatusLabels.timestamp]).toUTCString()}`
              }
            </p>
          </div>

          <div className="modal-btn-group">
            <p></p>
            <button className="as-text" onClick={handleClose}> OK </button>
          </div>
        </>}
      </>
        :
        <>
          <div className={styles.header}>
            <p>{`Upgrade Request Sent`}</p>
          </div>

          <div className={styles.body}>
            <p>{`For more information, click on the spa below to be taken to their details page.`}</p>
            <div className="update-results">
              <span className="clickable-icon" onClick={()=>navigate(`/spa/${id}`)}>
                {spa?.nick_name ? 
                  <span className="name-guid">
                    {`${spa?.nick_name} (`}<div className="partial-guid">{spa?.id}</div>{`)`}
                  </span>
                  :
                  <div className="partial-guid">{spa?.id}</div>
                  } 
              </span>
              <span className={`success-result`}>{firmware?.status??firmware?.message}</span>
            </div>
          </div>

          <div className="modal-btn-group">
            <p></p>
            <button className="as-text" onClick={handleClose}> OK </button>
          </div>
        </>
        }
        </div>
      </Modal>
    </div>
  );
};

export default SingleUpdateModal;
