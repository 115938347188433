import "./Modals.css";
import styles from "../../../assets/scss/Modal.module.scss";
import React, {useState, useEffect} from "react";
import {Modal} from '@mui/material';
import { deregisterSpaUser, editNickname, registerSpaUser } from "../../../redux/actions/spa.actions";
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import CircularProgress from "@mui/material/CircularProgress";
import UserSelect from "../UserSelect/UserSelect";
import { fetchSpas } from "../../../redux/actions/spas.actions";

interface EditSpaModalProps {
    isOpen: boolean, 
    setOpen: any,
    spa: any,
    setSpaToModify:any,
    params?: string,
}

const EditSpaModal: React.FC<EditSpaModalProps> = ({isOpen, setOpen, spa, setSpaToModify, params=""}) => {
    /* nickname input */
    const dispatch: AppDispatch = useDispatch();
    const [nameInput, setNameInput] = useState("");
    const [selectedUser, setSelectedUser] = useState<any>(null);

    const {data:currentUser} = useSelector((state: RootState) => state.user);
    const [canEditUser, setCanEditUser] = useState(false);

    const [nameError, setNameError] = useState(false);
    const [nameSuccess, setNameSuccess] = useState(false);

    const [userError, setUserError] = useState(false);
    const [userSuccess, setUserSuccess] = useState(false);

    const [inProgress, setInProgress] = useState(false);

    useEffect(() => {
        if (currentUser){
            setCanEditUser(currentUser?.roles?.some((role: any) =>
                ["admin"].includes(role.name)
            ) || false);
        }
    }, [currentUser,setCanEditUser]);


    useEffect(() => {
        if (isOpen){
            setNameError(false)
            setNameSuccess(false); 
            setUserError(false)
            setUserSuccess(false); 
            setNameInput(""); /* Reset Input */
        }

    }, [isOpen,setNameError,setNameSuccess,setUserError,setUserSuccess,setNameInput]);

    const handleClose = () => {
        setSpaToModify(null);
        setOpen(false);
    };

    /* Handle text input */
    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        let input = e.target.value;
        if (e.target.id === "nick-name-input"){
            setNameInput(input);
        }  
    };

    const handleEdit = async () => {
        setInProgress(true);
        if(nameInput!==""){
            const result = await dispatch(
                editNickname({spa:{...spa, nick_name:nameInput}})
              );
            switch (result.type){
                case "spa/update/fulfilled":
                    setNameSuccess(true);
                    await dispatch(fetchSpas(params));
                    break;

                case "spa/update/rejected":
                    setNameError(true);
                    break;
            }
        }

        if(selectedUser){
            if (selectedUser!=='none'){
                const result = await dispatch(
                    registerSpaUser({guid:spa?.id, user_id:selectedUser.id})
                  );
                switch (result.type){
                    case "spa/register-to-user/fulfilled":
                        setUserSuccess(true);
                        await dispatch(fetchSpas(params));
                        break;
    
                    case "spa/register-to-user/rejected":
                        setUserError(true);
                        break;
                }
            } else {
                const result = await dispatch(
                    deregisterSpaUser({guid:spa?.id, user_id:selectedUser.id})
                  );
                switch (result.type){
                    case "spa/deregister/fulfilled":
                        setUserSuccess(true);
                        await dispatch(fetchSpas(params));
                        break;
    
                    case "spa/deregister/rejected":
                        setUserError(true);
                        break;
                }
            } 
        }

        setInProgress(false);
    }

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >

        <div className={styles.modal}>
            <div className={styles.header}>
                {[nameError, userError, nameSuccess, userSuccess].includes(true) && !inProgress ?
                    <p></p>
                : inProgress ?
                    <p>Updating...</p>
                :
                    <p> Edit Spa <b>{spa?.nick_name}</b> </p>
                }
                
            </div>
            <div className={styles.body}>
                {[nameError, userError, nameSuccess, userSuccess].includes(true) && !inProgress ?
                    <div>
                        <div>{nameError && `An error occurred when updating nickname.`}</div>
                        <div>{userError && `An error occurred when updating user registration.`}</div>
                        <div>{nameSuccess && `Spa name successfully updated.`}</div>
                        <div>{userSuccess && `Spa user successfully updated.`}</div>
                    </div>
                :
                inProgress ?
                    <CircularProgress size={"24px"} />
                :
                <div>
                    <form className={styles.forms}>
                        <label className={styles.formLabel}>Nick Name:</label>
                        <input type="text" id="nick-name-input" placeholder="Enter new nickname" className={styles.textInput} defaultValue={spa?.nick_name} onChange={handleInput}></input>
                    </form>

                    {canEditUser && <>
                        <br></br>
                        
                        <div className={styles.forms}>
                            <label className={styles.formLabel}>Change User: {spa?.user?spa?.user.name:`No current user.`}</label>
                        </div>
                        <UserSelect setSelectedUser={setSelectedUser} currentUser={spa?.user}/>
                    </>}
                    
                </div>
                }
            </div>
            {
                [nameError, userError, nameSuccess, userSuccess].includes(true) && !inProgress  ?
                <div className="modal-btn-group">
                    <div></div>
                    <button className="as-text" onClick={handleClose}> OK </button>                        
            </div>
            : !inProgress ?
            <div className="modal-btn-group">
                <button className="as-text" onClick={handleClose}> Cancel </button>                        
                <button className="as-button" onClick={handleEdit} disabled={inProgress || (nameInput==="" && !selectedUser)}>Confirm</button>
            </div>
            :
            <></>
            }
        </div>
      </Modal>
    </div>
  );
};

export default EditSpaModal;
