import styles from "../../../assets/scss/Modal.module.scss";
import React from "react";
import {Modal} from '@mui/material';

interface ModalProps {
  isOpen: boolean, 
  setOpen: any,
  setOpenRequest: any,
  spas: any[],
}

const ConfirmLogsModal: React.FC<ModalProps> = ({
  isOpen, 
  setOpen, 
  setOpenRequest,  
  spas
}) => {

  const handleClose = () => {
      setOpen(false);
  };

  const handleConfirm = () => {
    setOpenRequest(true)
    handleClose();
  }

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>
            {spas.length>1 ? 
             <p>{`Request logs for ${spas.length} spas?`}</p>
             :
             spas[0]?.nick_name ? 
              <p className="name-guid">
                {`Request logs for ${spas[0]?.nick_name} (`}<div className="partial-guid">{spas[0]?.id}</div>{`)?`}
              </p>
              :
              <p>Request logs for <div className="partial-guid">{spas[0]?.id}</div>?</p>
            }
          </div>

          <div className={styles.body}>
          <div className="spas-title">{spas.length===1? `Request logs for this spa `:`Request logs for these spas `}</div>
          {spas.length>0 ? 
            <div className="select-container">
                <div className="user-select">
                  {spas.map((spa:any)=>(
                    <div>{spa?.nick_name ? `${spa?.nick_name} (${spa?.id})` :`${spa?.id}`}</div>
                  ))}
                </div>
              </div>
              :
              <div>No spas selected.</div>
            }
            <br></br>
            <button className="button request" disabled={spas.length===0} onClick={handleConfirm}>Request</button>
          </div>

          <div className="modal-btn-group">
            <button className="as-text" onClick={handleClose}> Close </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmLogsModal;
