import { createAsyncThunk } from '@reduxjs/toolkit';
import RootState from '../states/root.state';
import {requestLogsApi} from "../../services/api/logsRequest.api";


export interface requestLogsResponse {
    status: String,
    spa_id: String,
    updated_at: String,
    created_at: String,
    id: Number,
}

export const requestLogs = createAsyncThunk<
    requestLogsResponse,
    { guid: string;},
    { rejectValue: string; state: RootState }
>(
    'logs/request',
    async (spaInfo, { getState, rejectWithValue, dispatch }) => {
        try {
            const { user } = getState();
            const token = user.accessToken;

            return await requestLogsApi(token, spaInfo);
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while requesting logs.');
            }
        }
    }
);