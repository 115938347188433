import axios from 'axios';
import { BASE_URL } from './apiconfig';

export const getDealerships = async (token: string | null, params:string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/dealerships?${params}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to pull dealerships list. ", error);
    throw error;
  }
}

export const getDealership = async (token: string | null, id: string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/dealerships/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  } catch (error) {
    console.log("Failed to pull dealership by id. ", error);
    throw error;
  }
}