import "./DealershipDetailsPage.css"
import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../redux/states/root.state";
import Header from "../../components/common/Header/Header";
import EditUserModal from "../../components/common/Modals/EditUserModal";
import DeleteUserModal from "../../components/common/Modals/DeleteUserModal";
import UsersTable from "../../components/common/Tables/Tables/UsersTable";
import { AppDispatch } from "../../redux/store";
import { fetchSpa } from "../../redux/actions/spa.actions";
import { useParams, useSearchParams } from "react-router-dom";
import { fetchUsers } from "../../redux/actions/users.actions";
import { fetchDealership } from "../../redux/actions/dealership.actions";
import NotFound from "../General/NotFound";
import { CircularProgress } from "@mui/material";

const DealershipDetailsPage: React.FC = () => {
  // Data
  const {id} = useParams();
  const dispatch: AppDispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  // Data
  const dealership = useSelector((state: RootState) => state.dealership.data);
  const error = useSelector((state: RootState) => state.dealership.error);
  const users = useSelector((state: RootState) => state.users.data);
  const isLoading = [useSelector((state: RootState) => state.users.loading), useSelector((state: RootState) => state.dealership.loading), id!==dealership?.id]

  // Table Sort
  const [isChecked, setChecked] = useState<any[]>([]);
  const [isCheckAll, setCheckAll] = useState(false);

  //Modal Handling
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [userToEdit, setUser] = useState<any|null>(null);

  useEffect(() => {
    if (id){
      dispatch(fetchDealership(id));
      dispatch(fetchUsers(searchParams.toString()));
    }
  }, [dispatch,id,searchParams]);

  useEffect(() => {
    if (dealership?.id) {
      searchParams.set('dealership', dealership?.id);
      setSearchParams(searchParams);
    }
}, [dealership,setSearchParams,searchParams]);

  useEffect(() => {
    setChecked(users?.map((u)=>{return {id:u?.id, is_checked:false}}))
  }, [users]);
  
  const isDisabled = () => {
    return isChecked.find(obj=>obj?.is_checked===true)===undefined
  }

  if (error){
    return <NotFound/>
  }
  
  return (
    <>
    <EditUserModal isOpen={editOpen} setOpen={setEditOpen} id={userToEdit?.id}/>
    <DeleteUserModal isOpen={deleteOpen} setOpen={setDeleteOpen} user={userToEdit}/>
    <Header/>
    <div className="container">
    {isLoading.includes(true) ? 
        <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
      :
        <div className="list">
          <div className="filters user">
            <div className="card-container details">
              
              <div className="card-title">
                <strong>{dealership?.name}</strong>
              </div>
              <hr></hr>
              <div><strong>City: </strong>{dealership?.city??`N/A`}</div>
              <div><strong>Region: </strong>{dealership?.region??`N/A`}</div>
              <div><strong>Country: </strong>{dealership?.country_id??`N/A`}</div>

            </div>

            <div className="card-container details">
              <div className="card-title">
                <strong>Contact</strong>
              </div>
              <hr></hr>
              <div><strong>Phone: </strong>{dealership?.phone ?? 'N/A'}</div>
              <div><strong>Email: </strong>{dealership?.email ?? 'N/A'}</div>
            </div>


            <div className="firmware-updates">

              <div className="filter-btn-group">
                <button disabled={isDisabled()}>Remove Selected Staff</button>
              </div>
            </div>

          </div>
          
          <UsersTable 
            users={users} 
            setEditOpen={setEditOpen} 
            setDeleteOpen={setDeleteOpen} 
            setUser={setUser} 
            isChecked={isChecked} 
            setChecked={setChecked} 
            isCheckAll={isCheckAll} 
            setCheckAll={setCheckAll} 
            searchParams={searchParams} 
            setSearchParams={setSearchParams}
          />
        </div>}
      </div>     
    </>
  );
};

export default DealershipDetailsPage;
