import React, {useEffect, useState} from "react";
import "../../assets/svg/svg";

import {
    ContactUsIcon,
    FiltrationIcon,
    ManualIcon,
    MenuOpenIcon,
    ScheduleIcon,
    TroubleShootIcon,
    TubIcon,
    SettingIcon,
} from "../../assets/svg/svg";
import {fetchSpas} from "../../redux/actions/spas.actions";
import ArcticSpasAR from "../../components/common/ArViewer/ArViewer";
import NavigableDiv from "../../components/common/NavigatorDiv/NavigatableDiv";
import {TempSlider} from "../../components/SpaControl/TempSlider";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../redux/store";
import {fetchSpa} from "../../redux/actions/spa.actions";
import RootState from "../../redux/states/root.state";
import useMQTT from "../../hooks/useMQTT";
import Command from "../../classes/command.class";
import {commandKeys} from "../../mqttData/commands/commands.enum";
import LightsButton from "../../components/HotTubControl/Buttons/LightsButton";
import {PumpButton} from "../../components/HotTubControl/Buttons/PumpButton";
import {SpaLiveLabels} from "../../mqttData/live/spaLiveLabels.enums";
import SpaDropdown from "./component/SpaDropdown";
import {SpaSettingsLabels} from "../../mqttData/settings/spaSettingsLabels";
import { AmpereMeter } from "../../components/SpaControl/AmpereMeter";
import Header from "../../components/common/Header/Header";
import useDebounce from "../../hooks/useDebounce";

interface ReceivedData {
    [key: string]: boolean | string | number;
}

const SpaControlPage = () => {
    // Configs:
    const {id} = useParams();
    const dispatch: AppDispatch = useDispatch();

    // SPA:
    const spa = useSelector((state: RootState) => state.spa.data);
    const user = useSelector((state: RootState) => state.user.data);
    const canAccessAdminPanel = user?.roles?.some((role: any) => ["dealer", "admin", "owner", "corporate", "developer"].includes(role.name)) || false;
    const isConnected = useSelector((state: RootState) => state.spa.isConnected);
    const spasList = useSelector((state: RootState) => state.spas.data);

    // Fetch SPA From the DB
    useEffect(() => {
        if (id != null) {
            dispatch(fetchSpa(id));
        }
    }, [dispatch, id]);

    // Fetch SPAs list from the DB:
    useEffect(() => {
        dispatch(fetchSpas(""));
    }, [dispatch]);



    // Spa data and settings:
    const settingsData = useSelector((state: RootState) => state.settings.data);
    const liveData = useSelector((state: RootState) => state.live.data);

    // MQTT Functionality:
    const [commandTopic] = useMQTT(spa.id ?? "");
    const command = new Command(commandTopic);

    const handleSpaSelect = (spaId: string) => {
        window.location.href = `/spa-control/${spaId}`;
    };
    const getCurrentSetPointValue = () => {
        if (settingsData.TSP > 0) {
            setTemp(settingsData.TSP as number)

            return settingsData.TSP;
        }
    };

    const pumps = [
        {
            number: 1,
            label: SpaLiveLabels.Pump1,
            command: commandKeys.P1Next,
            config: SpaSettingsLabels.cfgP1
        },
        {
            number: 2,
            label: SpaLiveLabels.Pump2,
            command: commandKeys.P2Next,
            config: SpaSettingsLabels.cfgP2
        },
        {
            number: 3,
            label: SpaLiveLabels.Pump3,
            command: commandKeys.P3Next,
            config: SpaSettingsLabels.cfgP3
        },
        {
            number: 4,
            label: SpaLiveLabels.Pump4,
            command: commandKeys.P4Next,
            config: SpaSettingsLabels.cfgP4
        },
        {
            number: 5,
            label: SpaLiveLabels.Pump5,
            command: commandKeys.P5Next,
            config: SpaSettingsLabels.cfgP5
        }
    ];

    // Handle temperature change with debouncing:
    const [temp, setTemp] = useState<number>(0);

    const debouncedTemp = useDebounce(temp, 500);
    const handleTempChange = (newTemp: number) => {
        setTemp(newTemp); // Update the slider value
    }

    useEffect(() => {
        command.updateSettings({
            setTSP: debouncedTemp, 
        });
    }, [debouncedTemp]);


    return (
        <>
            <Header/>
            <div className="primary-window">
                <div className="spa-control-window">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                        }}
                    >
                        <div className="spa-info">
                            <SpaDropdown
                                spasList={spasList}
                                selectedSpa={spa}
                                onSpaSelect={handleSpaSelect}
                            />

                            <div>
                                <div className="spa-model">
                                    {spa.spa_type || "Arctic Tub"}

                                    {isConnected ? (
                                        <span className="status-indicator online"></span>
                                    ) : (
                                        <span className="status-indicator offline"></span>
                                    )}
                                </div>
                            </div>
                            <div className="spa-state">
                                <p>
                                    {
                                        isConnected ?
                                            liveData.H1 || liveData.H2 ? 'Heating...' : 'Idle'
                                            : 'Offline'
                                    }
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        className="spa-image"
                        style={isConnected ? {} : {filter: "grayScale(1) opacity(0.2)"}}
                    >
                        <ArcticSpasAR/>
                    </div>

                    <>
                        {canAccessAdminPanel &&
                            <div
                                className="ampere"
                                style={isConnected ? {} : {filter: "grayScale(1)"}}
                            >
                                <AmpereMeter value={ liveData[SpaLiveLabels.Current] ? (liveData[SpaLiveLabels.Current] as number)/100 : 0 }/>
                            </div>
                        }

                        <div
                            className="spa-controls-slider"
                            style={isConnected ? {} : {filter: "grayScale(1) opacity(0.3)"}}
                        >
                            <TempSlider
                                clIndicator={liveData.sbORPind ?? 0}
                                phValue={liveData.sbpH ? liveData.sbpH / 100 : 0}
                                initialValue={temp > 0 ? temp : getCurrentSetPointValue()}
                                currentTemp={liveData[SpaLiveLabels.CurrentTemp] as number}
                                isConnected={isConnected}
                                onTempChange={(newTemp) => handleTempChange(newTemp as number)}
                            />
                        </div>
                    </>
                    <div
                        className="spa-controls"
                        style={isConnected ? {} : {filter: "grayScale(1) opacity(0.3)"}}
                    >
                        {
                            pumps.map((pump) => {
                                return (
                                    settingsData[pump.config] &&
                                    <PumpButton
                                        key={pump.number}
                                        title=""
                                        onClick={() => command.executeSingle(pump.command)}
                                        initialStatus={liveData[pump.label] as number}
                                    />
                                );
                            })
                        }

                        <div className="controls-divider"></div>
                        <LightsButton
                            title=""
                            onClick={() => command.executeSingle(commandKeys.LightsNext)}
                            status={liveData[SpaLiveLabels.Lights] as boolean}
                        />
                    </div>
                </div>

                <div className="menu-list-frame">
                    <div className="menu-list">
                        <NavigableDiv
                            destination={`/spa-control/${spa?.id}/spaboy`}
                            className="menu-item"
                        >
                            <div>
                                <TubIcon/>
                                <p> Spaboy</p>
                            </div>
                            <MenuOpenIcon/>
                        </NavigableDiv>

                        <NavigableDiv
                            destination={`/spa-control/${spa?.id}/filtration`}
                            className="menu-item"
                        >
                            <div>
                                <FiltrationIcon/>
                                <p> Filtration</p>
                            </div>
                            <MenuOpenIcon/>
                        </NavigableDiv>
                        <NavigableDiv
                            destination={`/spa-control/${spa?.id}/schedule`}
                            className="menu-item"
                        >
                            <div
                                style={{
                                    borderBottom: "solid 0.01rem rgba(0, 0, 0, 0.2)",
                                    width: "100%",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div>
                                    <ScheduleIcon/>
                                    <p> Schedule</p>
                                </div>

                                <MenuOpenIcon/>
                            </div>
                        </NavigableDiv>

                        <NavigableDiv
                            destination={`/spa-control/${spa?.id}/troubleshoot`}
                            className="menu-item"
                        >
                            <div>
                                <TroubleShootIcon/>
                                <p> Troubleshoot</p>
                            </div>
                            <MenuOpenIcon/>
                        </NavigableDiv>

                        <NavigableDiv
                            destination="/spa-control/manuals-warranty"
                            className="menu-item"
                        >
                            <div>
                                <ManualIcon/>
                                <p> Manuals & Warranty</p>
                            </div>
                            <MenuOpenIcon/>
                        </NavigableDiv>

            <NavigableDiv
              destination="/spa-control/contact-us"
              className="menu-item"
            >
              <div
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <ContactUsIcon />
                  <p>Contact Us</p>
                </div>
                <MenuOpenIcon />
              </div>
            </NavigableDiv>

                        <div className="spa-info-detailed">
                            <div>
                                <span>Model: Summit XL FA ABC WF 1</span>
                                <span>S/N : {spa?.pack_serial_number} </span>
                            </div>
                            <div>
                                <span>Firmware Version: {spa?.lpc_version} </span>
                                <span>Yocto Version: {spa?.yocto_version}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SpaControlPage;
