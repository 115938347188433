import {useEffect, useRef, useState} from "react";
import {
    connectMQTT,
    disconnectMQTT,
    onConnect,
    onDisconnect,
    onMessage, publishMessage, subscribeToTopic,
    unsubscribeFromTopic
} from "../services/mqtt/mqtt";
import {useDispatch, useSelector} from "react-redux";
import RootState from "../redux/states/root.state";
import {setLiveData} from "../redux/reducers/live.reducer";
import {setSettingsData} from "../redux/reducers/settings.reducer";
import {AppDispatch} from "../redux/store";
import {setConnected, setDisconnected} from "../redux/reducers/spa.reducer";
import { setConstData } from "../redux/reducers/const.reducer";
import { setErrorData } from "../redux/reducers/error.reducer";
import { setStatusData } from "../redux/reducers/status.reducer";
import { setUpdateStatusData } from "../redux/reducers/updateStatus.reducer";

const defaultDealerId = process.env.REACT_APP_DEFAULT_DEALER_ID;

const useMQTT = (spaId: string) => {
    const user = useSelector((state: RootState) => state.user.data);
    const spa = useSelector((state: RootState) => state.spa.data);
    const isConnected = useSelector((state: RootState) => state.spa.isConnected);
    const [browserConnected, setBrowserConnected] = useState(false)
    const dispatch: AppDispatch = useDispatch();


    // Monitor the connection status of a spa using a ref, so it will be updated inside the timeout closure:
    const isConnectedRef = useRef(isConnected);
    useEffect(() => { isConnectedRef.current = isConnected;}, [isConnected]);

    // Topics:
    const [commandTopic, setCommandTopic] = useState("");
    const [liveTopic, setLiveTopic] = useState("");
    const [settingsTopic, setSettingsTopic] = useState("");
    const [constantsTopic, setConstantsTopic] = useState("");
    const [errorsTopic, setErrorsTopic] = useState("");
    const [statusTopic, setStatusTopic] = useState("");


    useEffect(() => {
        console.log("reinitiated")
        const handleConnect = () => {
            setBrowserConnected(true);
        };

        const handleDisconnect = () => {
            setBrowserConnected(false);
        };

        const handleMqttMessages = (topic: string, message: Buffer) => {
            const payload = message.toString();

            if (!topic.includes(spaId)) return;

            const topicParts = topic.split("/");

            const topicName = topicParts[topicParts.length - 1];

            try {
                if (topicName === "live") {
                    console.log({live: JSON.parse(payload)});

                    dispatch(setLiveData(JSON.parse(payload)))
                }

                if (topicName === "sett") {
                    console.log({settings: JSON.parse(payload)});

                    dispatch(setSettingsData(JSON.parse(payload)))
                }

                if (topicName === "const") {
                    console.log({const: JSON.parse(payload)});

                    dispatch(setConstData(JSON.parse(payload)))
                }

                if (topicName === "error") {
                    console.log({error: JSON.parse(payload)});

                    dispatch(setErrorData(JSON.parse(payload)))
                }
                
                if (topicName === "connection-status" || topic.includes("disconnected")) {
                    const data = JSON.parse(payload);

                    if (data.eventType === "disconnected") {
                        dispatch(setDisconnected());
                    }

                    if (data.connection_status === "connected") {
                        dispatch(setConnected());
                    }
                }

                if (topicName === "status") {
                    console.log({status: JSON.parse(payload)});

                    dispatch(setStatusData(JSON.parse(payload)))
                }
   
                if (topicName === "update-status") {
                    console.log({updateStatus: JSON.parse(payload)});

                    dispatch(setUpdateStatusData(JSON.parse(payload)))
                }
            } catch (error) {
                console.error("Failed to parse JSON:", error);
            }
        };
        

        if(!user || !spaId) return;

        connectMQTT(user);
        onConnect(handleConnect);
        onDisconnect(handleDisconnect);
        onMessage(handleMqttMessages);

        return () => {
            cleanUp();
            disconnectMQTT();
        };
    }, [dispatch, spaId, user]);


    useEffect(() => {
        const subscribeToCustomTopic = (topicName: string) => {
            if (!topicName || topicName.length < 1) return;

            subscribeToTopic(topicName as string, { qos: 0 });
        };

        if (spaId && browserConnected) {
            const topicBase = `${spa.manufacturer}/${spa.dealership_id ?? defaultDealerId}/${spa.id}`;
            const connectionStatusTopic = `arctic/${spa.dealership_id ?? defaultDealerId}/${spa.id}/connection-status`;
            const disconnectTopic = `$aws/events/presence/disconnected/${spa.id}`;

            setCommandTopic(`${topicBase}/command`);

            setLiveTopic(`${topicBase}/live`);
            subscribeToCustomTopic(`${topicBase}/live`);

            setSettingsTopic(`${topicBase}/sett`);
            subscribeToCustomTopic(`${topicBase}/sett`);

            setConstantsTopic(`${topicBase}/const`);
            subscribeToCustomTopic(`${topicBase}/const`);

            setErrorsTopic(`${topicBase}/error`);
            subscribeToCustomTopic(`${topicBase}/error`);

            setStatusTopic(`${topicBase}/status`);
            subscribeToCustomTopic(`${topicBase}/status`);

            setStatusTopic(`${topicBase}/update-status`);
            subscribeToCustomTopic(`${topicBase}/update-status`);

            // Subscribe to connection status topics:
            subscribeToCustomTopic(connectionStatusTopic);
            subscribeToCustomTopic(disconnectTopic);

            let delay = 100; // ms

            const checkConnection = () => {
                if (!isConnectedRef.current) {
                    publishMessage(
                        connectionStatusTopic,
                        JSON.stringify({ yocto_command: "report_connection_status" })
                    );

                    // Gradually increase the delay: 100ms, 1100ms, 2100ms, ...
                    delay = Math.min(delay + 1500, 120000); // Cap delay to a maximum of 120 seconds

                    setTimeout(checkConnection, delay);
                }
            };

            // Start the recursive function
            setTimeout(checkConnection, delay);

            return () => {};
        }
    }, [constantsTopic, errorsTopic, statusTopic, browserConnected, liveTopic, settingsTopic, spa, isConnectedRef, spaId]);

    const cleanUp = () => {
        unsubscribeFromTopic('#');
    };

    return [commandTopic];

}

export default useMQTT;