import React, {useState } from "react";
import NavigatableDiv from "../../../components/common/NavigatorDiv/NavigatableDiv";
import { BackChevronIcon } from "../../../assets/svg/svg";

import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import MySwitch from "../../../components/common/Switches/Switch";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";

import "./Schedule.css";
import Header from "../../../components/common/Header/Header";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Schedule = () => {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const [checked, setChecked] = useState(true);

  const handleSwitchChange = () => {
    setChecked((prev) => !prev);
  };

  const [showClock, setShowClock] = useState(false);

  const handleTimeClick = () => {
    setShowClock(true);
  };

  return (
      <>
        <Header withLogo={false} withNavBtn={false} withUserAvatar={false}/>
        <div className="controls-page-container">
          <div className="primary-window">
            <br></br>
            <div className="sub-menu-title"> Spa Schedule</div>

            <div className="list-hold-frame">
              <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
              >
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <div className="weekdays">Monday</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={`set-time-frame ${checked ? "" : "disabled"}`}>
                    <p></p>
                    <div className="set-time">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker defaultValue={dayjs("2022-04-17T15:30")}/>
                      </LocalizationProvider>
                    </div>
                    <p>to &nbsp;</p>

                    <div className="set-time">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker defaultValue={dayjs("2022-04-17T16:30")}/>
                      </LocalizationProvider>
                    </div>
                    <MySwitch onChange={handleSwitchChange}/>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
              >
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                  <div className="weekdays">Tuesday</div>
                </AccordionSummary>
                <AccordionDetails>
                  <MySwitch/>
                </AccordionDetails>
              </Accordion>
              <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
              >
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                  <div className="weekdays">Wednesday</div>
                </AccordionSummary>
                <AccordionDetails>
                  <MySwitch/>
                </AccordionDetails>
              </Accordion>

              <Accordion
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
              >
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                  <div className="weekdays">Thursday</div>
                </AccordionSummary>
                <AccordionDetails>
                  <MySwitch/>
                </AccordionDetails>
              </Accordion>

              <Accordion
                  expanded={expanded === "panel5"}
                  onChange={handleChange("panel5")}
              >
                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                  <div className="weekdays">Friday</div>
                </AccordionSummary>
                <AccordionDetails>
                  <MySwitch/>
                </AccordionDetails>
              </Accordion>

              <Accordion
                  expanded={expanded === "panel6"}
                  onChange={handleChange("panel6")}
              >
                <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                  <div className="weekdays">Saturday</div>
                </AccordionSummary>
                <AccordionDetails>
                  <MySwitch/>
                </AccordionDetails>
              </Accordion>

              <Accordion
                  expanded={expanded === "panel7"}
                  onChange={handleChange("panel7")}
              >
                <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                  <div className="weekdays">Sunday</div>
                </AccordionSummary>
                <AccordionDetails>
                  <MySwitch/>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>

      </>
  );
};

export default Schedule;
