export enum SpaStatusLabels {
    STAT0="STAT0",
    STAT1="STAT1",
    STAT2="STAT2",
    STAT3="STAT3",
    STAT4="STAT4",
    STAT5="STAT5",
    STAT6="STAT6",
    STAT7="STAT7",
    STAT8="STAT8",
    STAT9="STAT9",
    STAT10="STAT10",
    STAT11="STAT11",
    STAT12="STAT12",
    STAT13="STAT13",
    STAT14="STAT14",
    STAT15="STAT15",
    STAT16="STAT16",
    STAT17="STAT17",
    STAT18="STAT18",
    STAT19="STAT19",
    STAT20="STAT20",
    STAT21="STAT21",
    STAT22="STAT22",
    STAT23="STAT23",
    STAT24="STAT24",
    STAT25="STAT25",
    STAT26="STAT26",
    STAT27="STAT27",
    STAT28="STAT28",
    STAT29="STAT29",
    STAT30="STAT30",
    STAT31="STAT31",
    STAT32="STAT32",
    STAT33="STAT33",
    STAT34="STAT34",
    STAT35="STAT35",
    STAT36="STAT36",
    STAT37="STAT37",
    STAT38="STAT38",
    STAT39="STAT39",
    STAT40="STAT40",
    STAT41="STAT41",
    STAT42="STAT42",
    STAT43="STAT43",
    STAT44="STAT44",
    STAT45="STAT45",
    STAT46="STAT46",
    STAT47="STAT47",
    STAT48="STAT48",
    STAT49="STAT49",
    STAT50="STAT50",
    STAT51="STAT51",
    STAT52="STAT52",
    STAT53="STAT53",
    STAT54="STAT54",
    STAT55="STAT55",
    STAT56="STAT56",
    STAT57="STAT57",
    STAT58="STAT58",
    STAT59="STAT59",
    STAT60="STAT60",
    STAT61="STAT61",
    STAT62="STAT62",
    STAT63="STAT63",
}