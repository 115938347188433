import React, {useEffect, useState} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
  Pagination, Button
} from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import {DownloadIcon} from "../../../../assets/svg/svg";
import CircularProgress from "@mui/material/CircularProgress";
import {useDispatch, useSelector} from "react-redux";
import RootState from "../../../../redux/states/root.state";
import {getLogsFile} from "../../../../redux/actions/logs.actions";
import {AppDispatch} from "../../../../redux/store";
import { useNavigate } from "react-router-dom";

type Order = 'asc' | 'desc';
const headers = [
  {id:'id', label:'Log Request'}, 
  {id:'status', label:'Status'}, 
  {id:'spa', label:'Spa'},
  {id:'created_at', label:'Sent'}, 
  {id:'updated_at', label:'Last Updated'},
  {id:'log_file_path', label:'Log File'},
];

const LogsTable: React.FC<{logs:any[], searchParams:any, setSearchParams:any}> = ({logs,searchParams, setSearchParams}) => {
  const navigate = useNavigate();
  // Table Sort
  const [sortedRows, setRows] = useState<any[]>([]);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('');
  const isLoading = useSelector((state: RootState) => state.logs.loading);
  const meta = useSelector((state: RootState) => state.logs.meta);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    setRows(logs);
  }, [logs]);


  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    header_id: string,
  ) => {
    const isAsc = orderBy === header_id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(header_id);

    handleSort();
  };

  const handleSort = () => {
    let newArray = [...logs].sort((a:any, b:any)=>{
      return a[orderBy] === b[orderBy] ? 0 : order==='asc'? a[orderBy] ? -1:1 : b[orderBy] ? 1:-1;
    })
    setRows(newArray);
  }

  const handleChange = (event:any, value:any) => {
    setSearchParams({page: value})  
  }

  const downloadFile = async (log: { id: any; }) => {
    const data = await dispatch(getLogsFile({log_request_id: log.id}));

    const link = document.createElement('a');
    link.href = data.payload.url;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(data.payload.url);
  }

  return (
    <>
          <div className="table">
          {isLoading ? ( 
              <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
            ): 
            logs.length>0 ? (
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {headers.map((header)=>(
                    <TableCell key={header.id}  >
                      {header.id!=="log_file_path" ? 
                      <TableSortLabel
                        active={orderBy === header.id}
                        direction={orderBy === header.id ? order : 'asc'}
                        onClick={(e)=>handleRequestSort(e,header.id)}
                      >
                        {header.label}
                        {orderBy === header.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                      :
                      (header.label)
                    }

                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedRows.map((log)=>(
                  <TableRow key={log.id}>
                    
                    <TableCell  >
                        {log?.id}
                    </TableCell>

                    <TableCell  >
                      {log?.status}
                    </TableCell>

                    <TableCell  >
                      <div className="clickable-icon" onClick={()=>navigate(`/spa/${log?.spa?.id}`)}>
                        {log?.spa?.nick_name??"N/A"}
                      </div>
                    </TableCell>


                    <TableCell  >
                      {new Date(log?.created_at).toUTCString()}
                    </TableCell>

                    <TableCell  >
                      {new Date(log?.updated_at).toUTCString()}
                    </TableCell>

                    <TableCell  >
                      {
                          log.log_file_path &&

                          <Button  onClick={() => {
                            downloadFile(log)
                          }}>
                            <DownloadIcon />
                          </Button>

                      }
                    </TableCell>

                  </TableRow>
                ))}
                
              </TableBody>

            </Table>
            ):(
              <div className="empty-table">No Logs Found.</div>
            )
          }
          </div>

          <div className="table-footer">
            <div className="pagination">
                <Pagination 
                  count={meta?.last_page} 
                  variant="outlined" 
                  onChange={handleChange}
                  page={Number(searchParams.get("page"))}
                  showLastButton 
                  showFirstButton 
                  disabled={meta?.last_page===1}
                  />
                <div className="total-count">Showing {logs?.length??0}/{meta?.total??0} Logs</div>
              </div>
          </div>  
    </>
  );
};

export default LogsTable;
