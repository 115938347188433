import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RootState from "../../../../redux/states/root.state";
import {
  CircularProgress, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow,
  TableSortLabel,
  Box,
  Pagination,
} from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import { EditIcon } from "../../../../assets/svg/svg";

type Order = 'asc' | 'desc';
const headers = [
  {id:'checked', label:''}, 
  {id:'name', label:'Name'}, 
  {id:'username', label:'Username'}, 
  {id:'email', label:'Email'},
  {id:'spas', label:'Spas'},
  {id:'edit', label:'Edit'},
  {id:'delete', label:'Delete'}
];

interface UsersTableProps {
    users: any[],
    setEditOpen: any, 
    setDeleteOpen: any,
    setUser: any,
    isChecked: any,
    setChecked: any,
    isCheckAll: any,
    setCheckAll:any,
    searchParams: any,
    setSearchParams: any,
}

const UsersTable: React.FC<UsersTableProps> = ({users, setEditOpen, setDeleteOpen, setUser, isChecked, setChecked, isCheckAll, setCheckAll, searchParams, setSearchParams}) => {
  const navigate = useNavigate();
  // Data
  const isLoading = useSelector((state: RootState) => state.users.loading);
  const meta = useSelector((state: RootState) => state.users.meta);

  // Table Sort
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [sortedRows, setRows] = useState<any[]>([]);

  useEffect(() => {
    setRows(users);
  }, [users]);

  const handleChange = (event:any, value:any) => {
    searchParams.set("page", value);
    setSearchParams(searchParams);
  }
 
  // Handle Table Sorting
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    header_id: string,
  ) => {
    const isAsc = orderBy === header_id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(header_id);

    handleSort();
  };

  const handleSort = () => {
    let newArray = [...sortedRows].sort((a:any, b:any)=>{
      switch (orderBy){
        case 'name':
        case 'username':
        case 'email':
          return a[orderBy] === b[orderBy] ? 0 : order==='asc'? a[orderBy] > b[orderBy] ? -1:1 : a[orderBy] < b[orderBy] ? -1:1;

        default:
          return 0;
       
      }
    })
    setRows(newArray);
  }

  // Handle Selecting Users
  const checkAll = (e:any) => {
    setCheckAll(e.target.checked);
    setChecked(isChecked.map((m:any)=>{return {...m, is_checked:e.target.checked}}))
  }

  const handleCheck = (user:any)=>{
    setCheckAll(false);
    let newArray = isChecked.map((m:any)=>{
      return m?.id===user?.id ? {...m, is_checked: !m?.is_checked} : m
    })

    setChecked(newArray);
  }


  // Handle Open Modals
  const handleModal = (modal:`edit`|`delete`, user:any) => {
    setUser(user);
    modal===`edit`?setEditOpen(true):setDeleteOpen(true);
  }

  return (
    <>
        <div className="table">
        {isLoading ? ( 
            <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
        ): 
        
        sortedRows.length>0 ? (
        <Table stickyHeader>
            <TableHead>
            <TableRow>

                {headers.map((header)=>(
                <TableCell key={header.id} sx={{color:'var(--title-gray)',background:`var(--as-white)`, fontSize:'1rem'}}>
                    {header.id!=='edit' && header.id!=='delete' && header.id!=='checked'? 
                    <TableSortLabel
                    active={orderBy === header.id}
                    direction={orderBy === header.id ? order : 'asc'}
                    onClick={(e)=>handleRequestSort(e,header.id)}
                    >
                    {header.label}
                    {orderBy === header.id ? (
                        <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                    ) : null}
                    </TableSortLabel>
                    :
                    (header.id!=='checked' ? header.label : <input id="all-checked" className="table-check" type="checkbox" checked={isCheckAll} onChange={(e)=>checkAll(e)}></input>)
                }

                </TableCell>
                ))}
            </TableRow>
            </TableHead>

            <TableBody>
            {sortedRows.map((user,i)=>(
            <TableRow key={i}>
                <TableCell>
                <input id={`${user?.id}-checked`} className="table-check" type="checkbox" checked={isChecked.find((obj:any)=>obj.id===user?.id)?.is_checked} onChange={()=>handleCheck(user)}></input>
                </TableCell>

                <TableCell>
                <div className="clickable-icon" onClick={()=>navigate(`/user/${user?.id}`)}>
                    {user?.name!==undefined && user.name.length>0? user.name : "N/A"}
                </div>
                </TableCell>

                <TableCell>
                {user?.username}
                </TableCell>

                <TableCell>
                {user?.email}
                </TableCell>

                <TableCell>
                {user?.spas}
                </TableCell>

                <TableCell>
                <div className="clickable-icon" id="edit-user" onClick={()=>(handleModal(`edit`,user))}>
                    <EditIcon/>
                    </div>
                </TableCell>

                <TableCell>
                <div className="clickable-icon" id="delete-user" onClick={()=>(handleModal(`delete`,user))}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -5 24 24" fill="none" className="icon table-icon">
                    <path
                    d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" 
                    stroke="#444444" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                </div>
                </TableCell>
                
            </TableRow>
            ))}
            </TableBody>

        </Table>
        ):(
        <div className="empty-table">No Users Found.</div>
        )
        }
        </div>

        <div className="table-footer">
          <div className="pagination">
            <Pagination 
              count={meta?.last_page} 
              variant="outlined" 
              onChange={handleChange}
              page={Number(searchParams.get("page"))}
              showLastButton 
              showFirstButton 
              disabled={meta?.last_page===1}
            />
            <div className="total-count">Showing {users?.length??0}/{meta?.total??0} Users</div>
          </div>
        </div>

    </>
  );
};

export default UsersTable;
