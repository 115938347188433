// src/redux/reducers/errorLog.reducer.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SpaError } from '../models/errorLog.model';
import { fetchErrorLogs } from '../actions/errorLog.actions';

interface ErrorLogState {
  data: SpaError[];
  loading: boolean;
  error: string | null;
}

const initialState: ErrorLogState = {
  data: [],
  loading: false,
  error: null,
};

const errorLogSlice = createSlice({
  name: 'errorLog',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchErrorLogs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchErrorLogs.fulfilled, (state, action: PayloadAction<SpaError[]>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchErrorLogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      });
  },
});

export default errorLogSlice.reducer;