export enum SpaLiveLabels {
    CurrentTemp= "STemp",
    Pump1= "P1",
    Pump2= "P2",
    Pump3= "P3",
    Pump4= "P4",
    Pump5= "P5",
    BL1= "BL1",
    BL2= "BL2",
    Lights= "Li",
    St= "St",
    H1= "H1",
    H2= "H2",
    Filter= "Filter",
    On = "On",
    Oz= "Oz",
    Fan= "Fan",
    HTemp= "HTemp",
    Econ= "Econ",
    Current= "Current",
    AllOn= "AllOn",
    Fogger= "Fogger",
    SDS= "SDS",
    Yess= "Yess",
    sbTemp= "sbTemp",
    sbVin= "sbVin",
    sbVout= "sbVout",
    sbI1= "sbI1",
    sbI2= "sbI2",
    sbpH= "sbpH",
    sbORP= "sbORP",
    sbE1p= "sbE1p",
    sbE1n= "sbE1n",
    sbE2p= "sbE2p",
    sbE2n= "sbE2n",
    sbStat= "sbStat",
    sbpHind= "sbpHind",
    sbORPind= "sbORPind",
    sbWear= "sbWear" // 0 to 100
}