import './SettingsPage.css'
import React, { ReactElement, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../redux/states/root.state";
import { logout } from "../../redux/actions/user.actions";
import { AppDispatch } from "../../redux/store";
import Header from "../../components/common/Header/Header";
import {LogoutIcon, MenuOpenIcon} from "../../assets/svg/svg";
import styles from "../../assets/scss/Settings.module.scss";
import NavigableDiv from "../../components/common/NavigatorDiv/NavigatableDiv";
import MySwitch from "../../components/common/Switches/Switch";

const Settings = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.data);
  const [theme, setTheme] = useState<any>('light');
  var mode = localStorage.getItem('data-theme');

  useEffect(() => {
    if (mode) {
      setTheme(mode);
    } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setTheme('dark');
    }    
  }, [mode]);


  const handleLogout = () => {
    dispatch(logout())
      .unwrap()
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Logout failed:", error);
      });
  };

  const handleChange = () => {
    if (theme==='dark'){
      setTheme('light');
      document.body.setAttribute('data-theme', 'light');
      localStorage.setItem('data-theme', 'light');
    } else {
      setTheme('dark');
      document.body.setAttribute('data-theme', 'dark');
      localStorage.setItem('data-theme', 'dark');
    }
    window.dispatchEvent( new Event('storage') )
  }

    const logoutElement: ReactElement = <><LogoutIcon /> Logout</>
    const loginElement: ReactElement = <><LogoutIcon flipped={true} /> Login</>

  return (
      <>
        <Header withLogo={false} withNavBtn={false} withUserAvatar={false} header="Settings"/>
          <div className="page-wrapper top">
            <div className={styles.settingsContainer}>
                <p className={styles.header}>ACCOUNT</p>
                <div className={styles.buttonGroup}>
                    
                    <NavigableDiv
                      destination={`/settings/edit-profile`}
                      className="menu-item"
                    >
                      <div style={{
                                    borderBottom: "solid 0.01rem var(--color-gray)",
                                    width: "100%",
                                    justifyContent: "space-between",
                                }}>
                          <p>Edit Profile</p>
                          <MenuOpenIcon/> 
                      </div>
                    </NavigableDiv>

                    <NavigableDiv
                      destination={`/settings/change-password`}
                      className="menu-item"
                    >
                      <div style={{
                                    borderBottom: "solid 0.01rem var(--color-gray)",
                                    width: "100%",
                                    justifyContent: "space-between",
                                }}>
                          <p>Change Password</p>
                          <MenuOpenIcon/> 
                      </div>
                    </NavigableDiv>

                    <div className="menu-item" onClick={()=>navigate(`/my-spas`,{ state: { isSettings: true } })}>
                        <div className={styles.clickableDiv}>
                          <p>My Spas</p>
                          <MenuOpenIcon/>  
                        </div>
                      </div>
                </div>

                <p className={styles.header}>GENERAL</p>
                <div className={styles.buttonGroup}>

                <NavigableDiv
                      destination={`/settings/unit-preference`}
                      className="menu-item"
                    >
                      <div style={{
                                    borderBottom: "solid 0.01rem var(--color-gray)",
                                    width: "100%",
                                    justifyContent: "space-between",
                                }}>
                          <p>Units</p>
                          <p>°F<MenuOpenIcon/></p>
                      </div>
                    </NavigableDiv>

                    <NavigableDiv
                      destination={`/settings/technician`}
                      className="menu-item"
                    >
                      <div style={{
                                    borderBottom: "solid 0.01rem var(--color-gray)",
                                    width: "100%",
                                    justifyContent: "space-between",
                                }}>
                          <p>Technician Mode</p>
                          <MenuOpenIcon/>
                      </div>
                    </NavigableDiv>

                    <div className="dark-mode" >

                          <p>Dark Mode</p>
                          <MySwitch checked={theme==='dark'} onChange={handleChange}/>

                    </div>

                    
                </div>

                

                <div style={{width: "100%", marginLeft: "20px", marginTop: "20px"}}>
                    <div className="menu-button">
                        {user ? <Link onClick={handleLogout} to={'#'}> { logoutElement } </Link> :
                            <Link to="/login"> { loginElement } </Link>}
                    </div>
                </div>
            </div>
        </div>

    </>
  );
};

export default Settings;
