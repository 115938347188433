import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UpdateStatusState from '../states/updateStatus.state';

interface UpdateStatusData {
    [key: string]: any;
}

const initialState: UpdateStatusState = {
    data: {},
    loading: false,
    error: null,
};

const updateStatusSlice = createSlice({
    name: 'update-status',
    initialState,
    reducers: {
        setUpdateStatusData: (state, action: PayloadAction<UpdateStatusData>) => {
            return {
                ...state,
                data: action.payload
            };
        },
    },
});

export const { setUpdateStatusData } = updateStatusSlice.actions;
export default updateStatusSlice.reducer;
