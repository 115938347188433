import React, { useState } from "react";
import Header from "../../../components/common/Header/Header";
import styles from "../../../assets/scss/UserSettings.module.scss";
import {TextField, Button, InputAdornment} from '@mui/material';
import {
    VisibilityOffOutlined,
    VisibilityOutlined,
  } from "@mui/icons-material";
import { changePassword } from "../../../redux/actions/user.actions";
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import ConfirmationModal from "../../../components/common/Modals/ConfirmationModal";
import CircularProgress from "@mui/material/CircularProgress";

const ChangePassword = () => {
    const dispatch: AppDispatch = useDispatch();
    const isLoading = useSelector((state: RootState) => state.user.loading);
    const [isOpen, setOpen] = useState(false);
    const [modalContent, setContent] = useState("");

    /* Input values to validate and send to db */
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    /* Set show passwords */
    const [showOld, setShowOld] = useState(false); /* Show current password field */
    const [showNew, setShowNew] = useState(false); /* Show both new password fields */

    /* Set errors and helper texts */
    const [oldPasswordError, setOldPasswordError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmError, setConfirmError] = useState("");

    /* Validation and error handling on submit */
    const handleSubmit = async () => {
        /* Reset errors to check again */
        resetErrors();

        /* Handle input errors. No field can be left blank */
        if (oldPassword === ""
            || password === ""
            || passwordConfirmation === "") {
            const blank_error = "Field cannot be left blank."

            if (oldPassword === ""){
                setOldPasswordError(blank_error);
            }

            if (password === ""){
                setPasswordError(blank_error);
            }

            if (passwordConfirmation === ""){
                setConfirmError(blank_error);
            }

        } else {
            const result = await dispatch(
                changePassword({
                  old_password: oldPassword,
                  password: password,
                  password_confirmation: passwordConfirmation
                })
              );
            
            switch(result.type){
                case "user/update-password/rejected":
                    if (result.payload==="The password field confirmation does not match."){
                        setConfirmError("Passwords do not match.");

                    } else if (result.payload==='Old password is incorrect.'){
                        setOldPasswordError("Password is incorrect.");
                    } else {
                        setOpen(true);
                        setContent('An error occured. Please try again later.');
                    }
                    break;

                case "user/update-password/fulfilled":
                    setOpen(true);
                    setContent('Password Successfully Changed');
                    break;
            }
        }
    }

    const resetErrors = () => {
        setOldPasswordError("");
        setPasswordError("");
        setConfirmError("");
    }

    return (
        <>
            <Header withLogo={false} withNavBtn={false} withUserAvatar={false} header="Change Password"/>

            <div className="page-wrapper top">
                <ConfirmationModal isOpen={isOpen} setOpen={setOpen} content={modalContent}/>
                <div className={styles.inputsContainer}>
                    <div className={styles.inputSection}>
                        <TextField 
                            id="old-password" 
                            className={styles.textfield} 
                            label="Current Password" 
                            required
                            helperText={oldPasswordError}
                            error={oldPasswordError!==""}
                            type={showOld ? `text`:`password`}
                            onChange={(e)=>setOldPassword(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end" className={styles.passwordShow} onClick={()=>setShowOld(!showOld)}>
                                    {showOld ? <VisibilityOffOutlined/> : <VisibilityOutlined/>}
                                  </InputAdornment>
                              }}
                        />
                    </div>

                    <div className={styles.inputSection}>
                        <TextField 
                            id="new-password" 
                            className={styles.textfield} 
                            label="New Password" 
                            required
                            helperText={passwordError}
                            error={passwordError!==""}
                            type={showNew ? `text`:`password`}
                            onChange={(e)=>setPassword(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end" className={styles.passwordShow} onClick={()=>setShowNew(!showNew)}>
                                    {showNew ? <VisibilityOffOutlined/> : <VisibilityOutlined/>}
                                  </InputAdornment>
                              }}
                        />
                    </div>

                    <div className={styles.inputSection}>
                        <TextField 
                            id="confirm-password" 
                            className={styles.textfield} 
                            label="Confirm New Password"  
                            helperText={confirmError}
                            error={confirmError!==""}
                            required
                            onChange={(e)=>setPasswordConfirmation(e.target.value)}
                            type={showNew ? `text`:`password`}
                            InputProps={{
                                endAdornment: <InputAdornment position="end" className={styles.passwordShow} onClick={()=>setShowNew(!showNew)}>
                                    {showNew ? <VisibilityOffOutlined/> : <VisibilityOutlined/>}
                                  </InputAdornment>
                              }}
                        />
                    </div>

                    <Button 
                        className="button" 
                        variant="contained" 
                        disableElevation 
                        disabled={isLoading}
                        onClick={handleSubmit}>
                        {isLoading ? (
                            <CircularProgress size={"24px"} style={{ color: "var(--font-white)" }} />
                            ) : (
                            "Change Password"
                            )} 
                    </Button>
                </div>

            </div>
        </>

    );
};

export default ChangePassword;
