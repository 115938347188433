import React, {useEffect, useState} from "react";
import {
    LeftOffFilterIcon,
    LeftOnFilterIcon,
    OzoneIcon,
    RightOffFilterIcon,
    RightOnFilterIcon
} from "../../../assets/svg/svg";
import "./Filtration.css";
import "../SpaControlPage.css";
import {Slider} from "@mui/material";
import MySwitch from "../../../components/common/Switches/Switch";
import Header from "../../../components/common/Header/Header";
import {useParams} from "react-router-dom";
import {AppDispatch} from "../../../redux/store";
import {useDispatch, useSelector} from "react-redux";
import RootState from "../../../redux/states/root.state";
import {fetchSpa} from "../../../redux/actions/spa.actions";
import useMQTT from "../../../hooks/useMQTT";
import Command from "../../../classes/command.class";

const Filtration = () => {
    // Configs:
    const { id } = useParams();
    const dispatch: AppDispatch = useDispatch();

    // SPA:
    const spa = useSelector((state: RootState) => state.spa.data);
    const isConnected = useSelector((state: RootState) => state.spa.isConnected);

    const settingsData = useSelector((state: RootState) => state.settings.data);
    const liveData = useSelector((state: RootState) => state.live.data);

    // MQTT Functionality:
    const [ commandTopic] = useMQTT(id ?? '')
    const command = new Command(commandTopic)

    /* Filters functionality */
    const [hasFilters, setHasFilters] = useState(0)
    const [isOzoneWorking, setIsOzoneWorking] = useState(false)
    const [stopFilterAbove3, setStopFilterAbove3] = useState(false)
    const [filterFrequencey, setFilterFrequencey] = useState(0)
    const [filterDuration, setfilterDuration] = useState(0)

    // Fetch SPA From the DB
    useEffect(() => {
        if (id != null) {
            dispatch(fetchSpa(id));
        }
    }, [dispatch, id]);


    useEffect(() => {
        if(!settingsData) return;

        if(settingsData.RFIDConnected)
            setHasFilters(settingsData.RFIDConnected)

        if(settingsData.FS)
            setStopFilterAbove3(settingsData.FS)

        if(settingsData.FF)
            setFilterFrequencey(settingsData.FF)

        if(settingsData.FD)
            setfilterDuration(settingsData.FD)

    }, [settingsData]);


    useEffect(() => {
        if(!liveData) return;

        if(liveData.Oz)
            setIsOzoneWorking(liveData.Oz)

    }, [liveData]);

    const handleStopFilterChange = (e: any) => {
        const newControlValue = e.target.checked ? 1 : 0;

        /* Automatic: 0 | Manual: slider value */
        setStopFilterAbove3(!!newControlValue)

        command.updateSettings({
            setFS: newControlValue,
        })
    }

    const handleFilterFrequencyChange = (event: Event, value: number | number[], activeThumb: number) => {
        setFilterFrequencey(value as number);

        command.updateSettings({
            setFF: value as number,
        })
    }

    const handleFilterDurationChange = (event: Event, value: number | number[], activeThumb: number) => {
        setfilterDuration(value as number);

        command.updateSettings({
            setFD: value as number,
        })
    }

    return (
        <>
            <Header withLogo={false} withNavBtn={false} withUserAvatar={false} header="Filtration"/>
                <div className="primary-window">  
                <div className="filteration-container">
                    <div className="filter-controls-container">

                        <div className="filter-controls">
                            
                            <div className="filter-status">
                                <p className="filter-header">Filters</p>
                                
                                <div style={{display: 'flex', alignItems: 'center', gap: '16px'}}>
                                    { hasFilters ? 
                                        <> 
                                            <LeftOnFilterIcon />
                                            <RightOnFilterIcon /> 
                                        </> 
                                        : 
                                        <> 
                                            <LeftOffFilterIcon /> 
                                            <RightOffFilterIcon />
                                        </> 
                                    }                                    
                                </div>
                                <p> </p>
                            </div>

                            <div className="divider"></div>

                            <div className="ozone-status">
                                <p  className="filter-header">Ozone</p>
                                <div>
                                    <OzoneIcon toggleable/>
                                </div>
                                <p className={isOzoneWorking ? 'filter-on' : 'filter-off'}> {isOzoneWorking ? 'On' : 'Off'} </p>
                            </div>
                        </div>
                    </div>

                    <div className="sub-menu-title"> FILTER SCHEDULE</div>
                    <div className="sub-menu-box">
                        <div className="filter-frequency-info">
                            <p> Run filters for<b> <big> {filterDuration} </big> </b>{filterDuration!==1?`hours`:`hour`} </p>
                            <div className="filtration-slider">
                                <Slider
                                    min={0}
                                    max={2}
                                    value={filterDuration}
                                    onChange={handleFilterDurationChange}
                                    step={1}
                                    marks
                                />
                            </div>
                        </div>
                        <div className="filter-frequency-info">
                            <p> Run filters <b> <big> {filterFrequencey} </big> </b> {filterFrequencey!==1?`times`:`time`} a day </p>

                            <div className="filtration-slider">
                                <Slider
                                    min={0}
                                    max={12}
                                    value={filterFrequencey}
                                    onChange={handleFilterFrequencyChange}
                                    step={1}
                                    marks
                                />
                            </div>
                        </div>
                        <p style={{textTransform: 'none'}} className="sub-menu-switch">Filter will run for {filterDuration*filterFrequencey} {filterDuration*filterFrequencey!==1 ? `hours`:`hour`} everyday.</p>
                    </div>


                    <div className="sub-menu-title">
                        <div>
                            other
                        </div>
                        <div>
                        Set Temperature {settingsData.TSP / 10} &deg;F
                        </div>
                    </div>
                    <div className="sub-menu-box">
                        <div className="sub-menu-switch">
                            Stop filter 3° above set temperature
                            <MySwitch checked={stopFilterAbove3} onChange={handleStopFilterChange}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Filtration;
