import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SpaState from '../states/spa.state';
import { fetchSpa } from '../actions/spa.actions';

const initialState: SpaState = {
    loading: false,
    isConnected: false,
    data: [],
    error: null,
};

const spaSlice = createSlice({
    name: 'spa',
    initialState,
    reducers: {
        setConnected: (state) => {
            state.isConnected = true;
        },
        setDisconnected: (state) => {
            state.isConnected = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSpa.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchSpa.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchSpa.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? null;
            });
    },
});

export const { setConnected, setDisconnected } = spaSlice.actions;
export default spaSlice.reducer;