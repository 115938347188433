import React from 'react';
import loginStyles from "../../assets/scss/Login.module.scss";
import logo from "../../assets/logos/logo.png";
import ForgotPasswordForm from "./components/ForgotPasswordForm";

const ForgotPasswordPage: React.FC = () => {
    return (
        <>
            <div className={loginStyles.homeScreen}>
                <img
                    src={logo}
                    alt="Logo"
                    style={{ height: "7rem", margin: "0 0 4rem 0" }}
                />

                <ForgotPasswordForm />
            </div>
        </>
    );
};

export default ForgotPasswordPage;