import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchDealership } from '../actions/dealership.actions';
import DealershipState from '../states/dealership.state';

const initialState: DealershipState = {
  loading: false,
  data: [],
  error: null,
};

const DealershipSlice = createSlice({
  name: 'dealership',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDealership.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDealership.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchDealership.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? null;
      });
  },
});

export default DealershipSlice.reducer;