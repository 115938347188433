import "../Tables.css"
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import DealershipsTable from "../Tables/DealershipsTable";
import RootState from "../../../../redux/states/root.state";

interface ContainerProps {
  searchParams: any;
  setSearchParams: any;
}

const DealershipsContainer: React.FC<ContainerProps> = ({
  searchParams,
  setSearchParams
}) => {
  const dealerships = useSelector((state: RootState) => state.dealerships.data);

  //Filters
  const [country, setCountry] = useState(``);
  const [region, setRegion] = useState(``);
 
  const handleSelect = (event: SelectChangeEvent, selectField: string) => {
    switch (selectField){
      case 'country':
        setCountry(event.target.value as string);
        break;
      case 'region':
        setRegion(event.target.value as string);
        break;
    }
  };

  return (
    <div className="container">
        <div className="list">
          <div className="filters">
            <div className="search">
              <TextField
                id="name-search"
                label="Name"
                type="search"
                fullWidth={true}
              />

              <FormControl fullWidth>
                <InputLabel >Country</InputLabel>
                <Select
                  label="Country"
                  value={country}
                  onChange={e => handleSelect(e,'country')}
                >
                  <MenuItem value={1}>All</MenuItem>
                  <MenuItem value={2}>Canada</MenuItem>
                  <MenuItem value={3}>America</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel >Region</InputLabel>
                <Select
                  label="Region"
                  value={region}
                  onChange={e => handleSelect(e,'country')}
                >
                  <MenuItem value={1}>All</MenuItem>
                  <MenuItem value={2}>Alberta</MenuItem>
                  <MenuItem value={3}>British Columbia</MenuItem>
                </Select>
              </FormControl>

              <div className="filter-btn-group">
                <button>Apply Filters</button>
                <button>Clear</button>
              </div>
            </div></div>

            
          <DealershipsTable dealerships={dealerships} searchParams={searchParams} setSearchParams={setSearchParams}/>
              
        </div>
      </div>      
  );
};

export default DealershipsContainer;
