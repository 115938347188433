import "./UpgradeRequestsPage.css";
import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Header from "../../components/common/Header/Header";
import { AppDispatch } from "../../redux/store";
import { fetchUpgradeRequests } from "../../redux/actions/upgradeRequests.actions";
import UpdateHistoryContainer from "../../components/common/Tables/TableContainers/UpdatesContainer";

const UpdateHistoryPage: React.FC = () => {
  // Data
  const dispatch: AppDispatch = useDispatch();
 const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(fetchUpgradeRequests(searchParams.toString()))
  }, [dispatch, searchParams]);

  return (
    <>
      <Header/>
      <UpdateHistoryContainer
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
    </>
  );
};

export default UpdateHistoryPage;
