export enum commandKeys {
    P1Next = 'P1next',
    P2Next = 'P2next',
    P3Next = 'P3next',
    P4Next = 'P4next',
    P5Next = 'P5next',
    SDSNext = 'SDSnext',
    YESSNext = 'YESSnext',
    Bl1Next = 'Bl1next',
    BL2Next = 'BL2next',
    FLTRBoost = 'FLTRboost',
    OnzNext = 'Onznext',
    OzPeak2 = 'OzPeak2',
    FgNext = 'Fgnext',
    SBBoost = 'SBboost',
    Reset = 'Reset', // can have a value of 'all'
    OzPeak1 = 'OzPeak1', // can have  a value of 'off'
    LightsNext = 'Linext'
}