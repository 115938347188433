import {publishMessage} from "../services/mqtt/mqtt";

class Command{
    private readonly commandTopic: string;

    constructor(commandTopic: string) {
        this.commandTopic = commandTopic;
    }
    executeSingle(commandKey: string, value: any = null): void {
        publishMessage(this.commandTopic, JSON.stringify({
            [commandKey]: value ?? 1
        }));
    }

    updateSettings(params: Object){
        publishMessage(this.commandTopic, JSON.stringify(params));
    }
}

export default Command;
