import axios from "axios";
import { BASE_URL } from "./apiconfig";

export const getLiveHistory = async (token: string | null, spaInfo : {guid: string, date_from: number, date_to: number, keys: any[]}) => {
    try {
        const response = await axios.post(`${BASE_URL}/spas/live-history`, {
            spa_id: spaInfo.guid,
            date_from: spaInfo.date_from,
            date_to: spaInfo.date_to,
            keys: spaInfo.keys,
        },{
            headers: { Authorization: `Bearer ${token}` }
        });
      return response.data;
    } catch (error) {
      console.log("Failed to pull live history. ", error);
      throw error;
    }
}

export const getSettingsHistory = async (token: string | null, spaInfo : {guid: string, date_from: number, date_to: number, keys: any[]}) => {
  try {
      const response = await axios.post(`${BASE_URL}/spas/settings-history`, {
          spa_id: spaInfo.guid,
          date_from: spaInfo.date_from,
          date_to: spaInfo.date_to,
          keys: spaInfo.keys,
      },{
          headers: { Authorization: `Bearer ${token}` }
      });
    return response.data;
  } catch (error) {
    console.log("Failed to pull settings history. ", error);
    throw error;
  }
}

export const getConstantsHistory = async (token: string | null, spaInfo : {guid: string, date_from: number, date_to: number, keys: any[]}) => {
  try {
      const response = await axios.post(`${BASE_URL}/spas/constants-history`, {
          spa_id: spaInfo.guid,
          date_from: spaInfo.date_from,
          date_to: spaInfo.date_to,
          keys: spaInfo.keys,
      },{
          headers: { Authorization: `Bearer ${token}` }
      });
    return response.data;
  } catch (error) {
    console.log("Failed to pull constants history. ", error);
    throw error;
  }
}

export const getErrorsHistory = async (token: string | null, spaInfo : {guid: string, date_from: number, date_to: number, keys: any[]}) => {
  try {
      const response = await axios.post(`${BASE_URL}/spas/errors-history`, {
          spa_id: spaInfo.guid,
          date_from: spaInfo.date_from,
          date_to: spaInfo.date_to,
          keys: spaInfo.keys,
      },{
          headers: { Authorization: `Bearer ${token}` }
      });
    return response.data;
  } catch (error) {
    console.log("Failed to pull errors history. ", error);
    throw error;
  }
}

export const getStatusesHistory = async (token: string | null, spaInfo : {guid: string, date_from: number, date_to: number, keys: any[]}) => {
  try {
      const response = await axios.post(`${BASE_URL}/spas/statuses-history`, {
          spa_id: spaInfo.guid,
          date_from: spaInfo.date_from,
          date_to: spaInfo.date_to,
          keys: spaInfo.keys,
      },{
          headers: { Authorization: `Bearer ${token}` }
      });
    return response.data;
  } catch (error) {
    console.log("Failed to pull statuses history. ", error);
    throw error;
  }
}

