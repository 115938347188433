export enum SpaConstLabels {
    // Min Values
    cfgP1min="cfgP1min",
    cfgP2min="cfgP2min",
    cfgP3min="cfgP3min",
    cfgP4min="cfgP4min",
    cfgP5min="cfgP5min",
    cfgB1min="cfgB1min",
    cfgB2min="cfgB2min",
    cfgLimin="cfgLimin",
    cfgStmin="cfgStmin",
    cfgH1min="cfgH1min",
    cfgH2min="cfgH2min",
    cfgRFIDmin="cfgRFIDmin",
    cfgOnmin="cfgOnmin",
    cfgOzP1min="cfgOzP1min",
    cfgOzP2min="cfgOzP2min",
    cfgExmin="cfgExmin",
    cfgPLmin="cfgPLmin",
    cfgBSmin="cfgBSmin",
    cfgSBmin="cfgSBmin",
    cfgFGmin="cfgFGmin",
    cfgSDSmin="cfgSDSmin",
    cfgYESSmin="cfgYESSmin",
    cfgCTModemin="cfg CTModemin",
    cfgpHoffmin="cfgpHoffmin",
    cfgORPoffmin="cfgORPoffmin",
    // Max Values
    cfgP1max="cfgP1max",
    cfgP2max="cfgP2max",
    cfgP3max="cfgP3max",
    cfgP4max="cfgP4max",
    cfgP5max="cfgP5max",
    cfgB1max="cfgB1max",
    cfgB2max="cfgB2max",
    cfgLimax="cfgLimax",
    cfgStmax="cfgStmax",
    cfgH1max="cfgH1max",
    cfgH2max="cfgH2max",
    cfgRFIDmax="cfgRFIDmax",
    cfgOnmax="cfgOnmax",
    cfgOzP1max="cfgOzP1max",
    cfgOzP2max="cfgOzP2max",
    cfgExmax="cfgExmax",
    cfgPLmax="cfgPLmax",
    cfgBSmax="cfgBSmax",
    cfgSBmax="cfgSBmax",
    cfgFGmax="cfgFGmax",
    cfgSDSmax="cfgSDSmax",
    cfgYESSmax="cfgYESSmax",
    cfgCTModemax="cfg CTModemax",
    cfgpHoffmax="cfgpHoffmax",
    cfgORPoffmax="cfgORPoffmax",
    // $spa_sett_min
    setTSPmin="setTSPmin",
    setFFmin="setFFmin",
    setFDmin="setFDmin",
    setOnHrmin="setOnHrmin",
    setOnCymin="setOnCymin",
    setOzHrmin="setOzHrmin",
    setOzCymin="setOzCymin",
    setSBHrsmin="setSBHrsmin",
    setTOffmin="setTOffmin",
    Fsmin="Fsmin",

    //$spa_sett_max
    setTSPmax="setTSPmax",
    setFFmax="setFFmax",
    setFDmax="setFDmax",
    setOnHrmax="setOnHrmax",
    setOnCymax="setOnCymax",
    setOzHrmax="setOzHrmax",
    setOzCymax="setOzCymax",
    setSBHrsmax="setSBHrsmax",
    setTOffmax="setTOffmax",
    Fsmax="Fsmax",
}