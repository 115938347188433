import React, { useState, useEffect, useRef } from "react";
import { DownChevronIcon } from "../../../assets/svg/svg";
import { useNavigate } from "react-router-dom";
import "../SpaControlPage.css";

interface Spa {
  id: string;
  nick_name: string;
  isSpaConnected: boolean;
}

interface SpaDropdownProps {
  spasList: Array<Spa>;
  selectedSpa: { nick_name: string };
  onSpaSelect: (spaId: string) => void;
}

const SpaDropdown: React.FC<SpaDropdownProps> = ({
  spasList,
  selectedSpa,
  onSpaSelect,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !(dropdownRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownItemClick = (spaId: string) => {
    onSpaSelect(spaId); // Callback to parent for selecting spa
    setIsDropdownVisible(false);
  };

  return (
    <div className="spa-dropdown">
      <div onClick={toggleDropdown} className="spa-nickname">
        {selectedSpa?.nick_name}
        <DownChevronIcon />
      </div>

      {isDropdownVisible && (
        <div className="dropdown-menu" ref={dropdownRef}>
          <ul>
            {spasList.map((spaItem) => (
              <li
                key={spaItem.id}
                onClick={() => handleDropdownItemClick(spaItem.id)}
              >
                <div className="dropdown-items">
                  {spaItem.nick_name}
                  <div className="dropdown-indicator">
                    {spaItem.isSpaConnected ? (
                      <span className="status-indicator online"></span>
                    ) : (
                      <span className="status-indicator offline"></span>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SpaDropdown;
