import axios from 'axios';
import { BASE_URL } from './apiconfig';

export const getUsers = async (token: string | null, params:string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/users?${params}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to pull user list. ", error);
    throw error;
  }
}

export const getUserById = async (token: string | null, id: string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/users/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  } catch (error) {
    console.log("Failed to pull user by id. ", error);
    throw error;
  }
}