import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import LiveState from "../states/live.state";

interface LiveData {
    [key: string]: any;
}

const initialState: LiveState = {
    data: {},
    loading: false,
    error: null,
};

const liveSlice = createSlice({
    name: 'live',
    initialState,
    reducers: {
        setLiveData: (state, action: PayloadAction<LiveData>) => {
            return {
                ...state,
                data: action.payload
            };
        },
    },
});

export const { setLiveData } = liveSlice.actions;
export default liveSlice.reducer;
