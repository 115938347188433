import React, { useEffect, useState } from "react";
import {
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow,
  TableSortLabel,
  Box,
  Pagination,
  CircularProgress
} from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import { DeleteIcon, EditIcon } from "../../../../assets/svg/svg";
import { useSelector } from "react-redux";
import RootState from "../../../../redux/states/root.state";

type Order = 'asc' | 'desc';
const headers = [
  {id:'id', label:'ID', align:"center"},
  {id:'type', label:'Type', align:null},
  {id:'version', label:'Version', align:null}, 
  {id:'description', label:'Description', align:null}, 
  {id:'is_latest', label:'Latest', align:"center"},
  {id:'updated_at', label:'Last Updated', align:null},
  {id:'created_at', label:'Uploaded', align:null},
  {id:'edit', label:'Edit', align:"center"},
  {id:'delete', label:'Delete', align:"center"},
];

const FirmwareTable: React.FC<
{
  searchParams:any, 
  setSearchParams:any, 
  setEditOpen:any,
  setDeleteOpen:any,
  setFirmwareToModify: any,
}> = ({
  searchParams, 
  setSearchParams,
  setEditOpen,
  setDeleteOpen,
  setFirmwareToModify
}) => {
  const {data:firmwares,meta, loading} = useSelector((state: RootState) => state.firmware);
  // Table Sort
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('');
  const [sortedRows, setSortedRows] = useState<any[]>([]);
  
  useEffect(() => {
    setSortedRows(firmwares);
  }, [firmwares]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    header_id: string,
  ) => {
    const isAsc = orderBy === header_id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(header_id);

    handleSort();
  };

  const handleChange = (event:any, value:any) => {
    setSearchParams({page: value})  
  }

  const handleSort = () => {
    let newArray = [...firmwares].sort((a:any, b:any)=>{
      let isAsc = order ==='asc';
      switch (orderBy){
        case 'is_latest':
          return a[orderBy]===b[orderBy] ? 0 : isAsc? a[orderBy] ? -1 : 1 : b[orderBy] ? -1 : 1

        default:
          return a[orderBy]===b[orderBy] ? 0 : isAsc ? a[orderBy]>b[orderBy] ? -1 : 1 : a[orderBy]<b[orderBy] ? -1 : 1;
      }
    })
    setSortedRows(newArray);
  }

  const handleModal = (type:string, firmware:any) => {
    setFirmwareToModify(firmware);
    if (type===`edit`){
      setEditOpen(true);
    } else {
      setDeleteOpen(true);
    }
  }

  return (
    <>
          <div className="table">
          {loading ? ( 
              <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
            ): 
            (firmwares.length===0?
              <div className="empty-table">No Firmware Found.</div>
            :
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {headers.map((header)=>(
                    <TableCell key={header.id}  align={header.align ? 'center':undefined}>
                      {header.id!=="edit" && header.id!=="delete" && header.id!=="description"? 
                      <TableSortLabel
                        active={orderBy === header.id}
                        direction={orderBy === header.id ? order : 'asc'}
                        onClick={(e)=>handleRequestSort(e,header.id)}
                      >
                        {header.label}
                        {orderBy === header.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                      :
                      (header.label)
                    }

                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedRows.map((f:any)=>
                  <TableRow key={f.id}>

                    <TableCell >
                      {f?.id}
                    </TableCell>

                    <TableCell >
                      {f?.type}
                    </TableCell>
              
                    <TableCell>
                      {f?.version}
                    </TableCell>
                    
                    <TableCell  >
                      {f?.description}
                    </TableCell>

                    <TableCell align="center">
                      {f?.is_latest ? <span className="check">✓</span>:``}
                    </TableCell>

                    <TableCell  >
                      {f?.created_at && new Date(f?.created_at).toUTCString()}
                    </TableCell>


                    <TableCell  >
                      {f?.updated_at && new Date(f?.updated_at).toUTCString()}
                    </TableCell>

                    <TableCell align='center'  >
                      <div className="clickable-icon" onClick={()=>handleModal(`edit`, f)}>
                        <EditIcon/>
                      </div>
                    </TableCell>

                    <TableCell align='center'  >
                      <div className="clickable-icon" onClick={()=>handleModal(`delete`, f)}>
                        <DeleteIcon/>
                      </div>
                    </TableCell>

                  </TableRow>
                  )}
                
              </TableBody>

            </Table>
            )}
          </div>

          <div className="table-footer">
            <div className="pagination">
                <Pagination 
                  count={meta?.last_page} 
                  variant="outlined" 
                  onChange={handleChange}
                  page={Number(searchParams.get("page"))}
                  showLastButton 
                  showFirstButton 
                  disabled={meta?.last_page===1}
                  />
                </div>
          </div>  
    </>
  );
};

export default FirmwareTable;
