import {createAsyncThunk} from '@reduxjs/toolkit';
import { getUpgradeRequests } from '../../services/api/upgradeRequests.api';
import RootState from "../states/root.state";

export const fetchUpgradeRequests = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
  'upgrades/list',
  async (params, {rejectWithValue, getState}) => {

      const {user} = getState();
      const token = user.accessToken;

    return await getUpgradeRequests(token, params);
  }
);