import React from "react";
import './Technician.scss'
import Header from "../../../components/common/Header/Header";
import {
    Switch,
} from '@mui/material';
import MySwitch from "../../../components/common/Switches/Switch";

const Technician = () => {
    return (
        <>
            <Header withLogo={false} withNavBtn={false} withUserAvatar={false}/>

            <div className="primary-window">
                <div className="menu-page-header">Technician Mode</div>
                <br></br>
                <div className="tech-page-container">
                    <div className="switch-container">
                        <p>{`Technician Mode`}</p>
                        <MySwitch disabled={true}/>
                    </div>
                    <p className="coming-soon-text">{`* Technician mode coming soon.`}</p>
                </div>
            </div>
        </>

    );
};

export default Technician;
