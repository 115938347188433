import React, {useEffect, useState} from "react";
import { 
    Table, 
    TableBody, 
    TableCell, 
    TableHead, 
    TableRow,
    TableSortLabel,
    Box,
    CircularProgress,
    Pagination,
  } from "@mui/material";
  import { visuallyHidden } from '@mui/utils';
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import { fetchErrorsHistory, fetchSettingsHistory, fetchStatusesHistory } from "../../../redux/actions/spaHistory.actions";

interface ReportsTableProps {
    spa_guid: string;
    category: string;
    selected: any[];
    startDate: string;
    endDate: string;
}

const headers = [
    {id:'name', label:'Setting'}, 
    {id:'value', label:'Value'},
    {id:'timestamp', label:'Timestamp'},
  ]

type Order = 'asc' | 'desc';

const ReportsTable: React.FC<ReportsTableProps> = ({selected, startDate, endDate, spa_guid, category}) => {
    const dispatch: AppDispatch = useDispatch();
    const {data: history, loading} = useSelector((state: RootState) => state.settingsHistory);
    const [isSortingRows, setIsSortingRows] = useState(true);
    // Table Sort
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState('');
    const [sortedRows, setSorted] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const rowsPerPage = 25;

    //Generate random dates for dummy data
    useEffect(() => {
        setPage(1);
        setIsSortingRows(true);
        let keys = [...selected];
        switch(category){
            case 'settings':
            case 'spa_settings':
            case 'rfid':
            case 'spaboy':
            case 'firmware':
                dispatch(fetchSettingsHistory({guid:spa_guid, date_from:new Date(startDate).getTime(), date_to:new Date(endDate).getTime(), keys:keys}));
                break;

            case 'errors':
                dispatch(fetchErrorsHistory({guid:spa_guid, date_from:new Date(startDate).getTime(), date_to:new Date(endDate).getTime(), keys:keys}));
                break;

            case 'statuses':
                dispatch(fetchStatusesHistory({guid:spa_guid, date_from:new Date(startDate).getTime(), date_to:new Date(endDate).getTime(), keys:keys}));
                break;
        }    
    },[dispatch, spa_guid, selected, startDate, endDate, category]);

    useEffect(() => {
        setIsSortingRows(true);
        let newArray: any[]=[];
        if (selected.length>1){
            selected.forEach((select) => {
                history.forEach((obj:any) => {
                    let newObj = {...obj};
                    let keys = Object.keys(newObj);
                    let del = keys.find((key)=>key!==select && key!=='spa_guid' && key!=='timestamp')
                    if (del){
                        delete newObj[del];
                    }
                    newObj.name = select;
                    
                    newArray.push(newObj);
                });
            });
        } else {
            history.forEach((obj:any) => {
                let newObj = {...obj};
                let keys = Object.keys(newObj);
                let name = keys.find((key)=>key!=='spa_guid' && key!=='timestamp')
                newObj.name = name;
                
                newArray.push(newObj);
            });
        };
        setSorted(newArray)
        setIsSortingRows(false);
    },[history,selected]);

    const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    header_id: string,
    ) => {
    const isAsc = orderBy === header_id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(header_id);

    handleSort();
    };
    
    const handleSort = () => {
        let newArray = [...sortedRows].sort((a:any, b:any)=>{
            let isAsc = order ==='asc';
            switch (orderBy){
                case 'name':
                    return a?.name===b?.name ? 0 : isAsc ? a?.name>b?.name ? -1 : 1 : a?.name<b?.name ? -1 : 1;
        
                case 'value':
                    if(typeof a?.name === 'boolean'){
                        return a[a?.name]===b[b?.name] ? 0 : isAsc? a[a?.name] ? -1 : 1 : b[a?.name] ? -1 : 1
                    }
                    return a[a?.name]===b[b?.name] ? 0 : isAsc ? a[a?.name]>b[b?.name] ? -1 : 1 : a[a?.name]<b[b?.name] ? -1 : 1;
                
                case 'timestamp':
                    let timeA = new Date(a?.timestamp);
                    let timeB = new Date(b?.timestamp);
                    return timeA===timeB ? 0 : isAsc ? timeA>timeB ? -1 : 1 : timeA<timeB ? -1 : 1;
    
                default:
                    return 0;
            }
        })
        setSorted(newArray);   
    }
    
  return (
    <>
    <div className="table">
        {loading || isSortingRows ?
        <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
        :
        sortedRows.length>0 ? (
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                    {headers.map((header:any)=>
                    <TableCell key={header.id} sx={{color:'var(--title-gray)',background:`var(--as-white)`, fontSize:'1rem'}}>
                        <TableSortLabel
                            active={orderBy === header.id}
                            direction={orderBy === header.id ? order : 'asc'}
                            onClick={(e)=>handleRequestSort(e,header.id)}
                        >
                            {header.label}
                            {orderBy === header.id ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                    )}

                </TableRow>
              </TableHead>

              <TableBody>
                {(rowsPerPage > 0
                    ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : sortedRows
                ).map((row:any,i)=>
                    <TableRow key={i}>
                        <TableCell>
                            {row.name}
                        </TableCell>

                        <TableCell>
                            {typeof row[row.name] === 'boolean' ? row[row.name] ? 'ON' : 'OFF' : row[row.name]}
                        </TableCell>
                            
                        <TableCell>
                            {new Date(row.timestamp).toUTCString()}
                        </TableCell>
                    </TableRow>
                )}
              </TableBody>
            </Table>
            ):(
            <div className="empty-table">No Reports Found.</div>
            )
          }
          </div>

          <div className="table-footer">
            <div className="pagination">
              <Pagination 
                count={Math.floor(sortedRows.length/rowsPerPage)-1} 
                variant="outlined" 
                onChange={(e,page)=>setPage(page)}
                page={page}
                showLastButton 
                showFirstButton 
                disabled={sortedRows.length<=25}
                />
              <div className="total-count">Showing {rowsPerPage > 0
                    ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length
                    : sortedRows.length}/{sortedRows.length} Reports</div>
            </div>
        </div>
    </>
  );
};

export default ReportsTable;
