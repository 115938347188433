import React, { useEffect, useState } from "react";

interface ActionButtonProps {
  onClick: () => void;
  status: boolean;
  title: string;
}
const LightIcon: React.FC<ActionButtonProps> = ({ onClick, status, title }) => {
  const [isActive, setIsActive] = useState(status);

  useEffect(() => {
    setIsActive(status);
  }, [status]);

  const handleClick = () => {
    onClick();
  };

  const className = `icon toggleable ${isActive ? "active" : ""}`;

  return (
    <div className="button-wrapper">
      <svg
        className={className}
        width="40"
        height="40"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={handleClick}
      >
        <path
          d="M14.0001 21V14.875M14.0001 14.875C14.5904 14.8755 15.1784 14.8014 15.7501 14.6545M14.0001 14.875C13.4098 14.8755 12.8218 14.8014 12.2501 14.6545M16.6251 23.3788C14.8906 23.7082 13.1096 23.7082 11.3751 23.3788M15.7501 26.159C14.5866 26.2808 13.4136 26.2808 12.2501 26.159M16.6251 21V20.776C16.6251 19.6292 17.3928 18.6492 18.3844 18.074C20.0504 17.1092 21.3518 15.6219 22.0867 13.8426C22.8217 12.0632 22.9494 10.0911 22.4499 8.23182C21.9504 6.37255 20.8516 4.72991 19.3239 3.55843C17.7962 2.38695 15.9247 1.75201 13.9995 1.75201C12.0743 1.75201 10.2029 2.38695 8.67512 3.55843C7.14738 4.72991 6.04864 6.37255 5.54915 8.23182C5.04965 10.0911 5.17729 12.0632 5.91228 13.8426C6.64728 15.6219 7.94859 17.1092 9.6146 18.074C10.6063 18.6492 11.3751 19.6292 11.3751 20.776V21"
          stroke="#231F20"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <div>{title}</div>
    </div>
  );
};

export default LightIcon;
