import React, { useEffect, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { styled } from '@mui/system';
import store, { AppDispatch, RootState } from './redux/store';
import theme from './theme';
import AppRoutes from './Routes';
import { fetchUserData } from './redux/actions/user.actions';
import { setupTokenRefresh, clearTokenRefresh } from './redux/utils/tokenManager';
import { createTheme } from '@mui/material/styles';

const AppWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

const MainContent = styled('main')({
  flexGrow: 1,
});

const AppContent: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { accessToken, expirationTime } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (accessToken && expirationTime) {
      dispatch(fetchUserData());
      setupTokenRefresh(dispatch, expirationTime);
    }

    return () => {
      clearTokenRefresh();
    };
  }, [dispatch, accessToken, expirationTime]);

  return (
    <AppWrapper>
      <MainContent>
        <AppRoutes />
      </MainContent>
    </AppWrapper>
  );
};

const App: React.FC = () => {
  const [mode,setMode] = useState('light');

  useEffect(() => {
    var local = localStorage.getItem('data-theme');
    if (local) {
      setMode(local);
    } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setMode('dark');
    };
  }, [setMode]);

  window.matchMedia('(prefers-color-scheme: dark)').addEventListener("change", function (e) {
    e.matches ? setMode('dark') : setMode('light');
  });

  window.addEventListener("storage", (event) => {
    var local = localStorage.getItem('data-theme');
    if (local) {
      setMode(local);
    } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setMode('dark');
    };
  });

  const darkTheme = createTheme({
    palette: {
      primary: {
        main: 'rgb(239, 169, 151)', // AS Red
      },
      asRed: {
        main: 'rgb(239, 169, 151)',
      },
      // You can keep other color definitions here
    },
  });
  return (
    <Provider store={store}>
      <ThemeProvider theme={mode==='dark'? darkTheme : theme}>
        <CssBaseline />
        <AppContent />
      </ThemeProvider>
    </Provider>
  );
};

export default App;